import { storeWareDataInterface } from "../Interface";

const initState: storeWareDataInterface = {
  warehouseData: {
    company: {
      value: "",
      error: "",
    },
    branch: {
      value: "",
      error: "",
    },
    companyCode: {
      value: "",
      error: "",
    },
    companyNameEng: {
      value: "",
      error: "",
    },
    companyNameThai: {
      value: "",
      error: "",
    },
    branchCode: {
      value: "",
      error: "",
    },
    branchNameEng: {
      value: "",
      error: "",
    },
    branchNameThai: {
      value: "",
      error: "",
    },
    warehouseCode: {
      value: "",
      error: "",
    },
    warehouseName: {
      value: "",
      error: "",
    },
    description: {
      value: "",
      error: "",
    },
    address: {
      value: "",
      error: "",
    },
    subDistrict: {
      value: "",
      error: "",
    },
    district: {
      value: "",
      error: "",
    },
    province: {
      value: "",
      error: "",
    },
    postCode: {
      value: "",
      error: "",
    },
    country: {
      value: "217",
      error: "",
    },
    location: {
      value: "",
      error: "",
    },
    latitude: {
      value: 0,
      error: "",
    },
    longitude: {
      value: 0,
      error: "",
    },
    phoneNumber: {
      value: "",
      error: "",
    },
    faxNumber: {
      value: "",
      error: "",
    },
    contactPerson: {
      value: "",
      error: "",
    },
    contactNumber: {
      value: "",
      error: "",
    },
    contactEmail: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  locationData: {
    company: {
      value: "",
      error: "",
    },
    branch: {
      value: "",
      error: "",
    },
    companyCode: {
      value: "",
      error: "",
    },
    companyNameEng: {
      value: "",
      error: "",
    },
    companyNameThai: {
      value: "",
      error: "",
    },
    branchCode: {
      value: "",
      error: "",
    },
    branchNameEng: {
      value: "",
      error: "",
    },
    branchNameThai: {
      value: "",
      error: "",
    },
    locationCode: {
      value: "",
      error: "",
    },
    locationName: {
      value: "",
      error: "",
    },
    description: {
      value: "",
      error: "",
    },
    address: {
      value: "",
      error: "",
    },
    subDistrict: {
      value: "",
      error: "",
    },
    district: {
      value: "",
      error: "",
    },
    province: {
      value: "",
      error: "",
    },
    postCode: {
      value: "",
      error: "",
    },
    country: {
      value: "",
      error: "",
    },
    location: {
      value: "",
      error: "",
    },
    latitude: {
      value: 0,
      error: "",
    },
    longitude: {
      value: 0,
      error: "",
    },
    phoneNumber: {
      value: "",
      error: "",
    },
    faxNumber: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  warehouseList: [],
  warehouseListTotal: 0,
};

const storeWare = (
  state = initState,
  action: { type: string; data: storeWareDataInterface }
) => {
  switch (action.type) {
    case "warehouse_data":
      return {
        ...state,
        warehouseData: { ...state.warehouseData, ...action.data },
      };
    case "location_data":
      return {
        ...state,
        locationData: { ...state.locationData, ...action.data },
      };
    case "warehouse_list":
      return {
        ...state,
        warehouseList: action.data,
      };
    case "warehouse_list_total":
      return {
        ...state,
        warehouseListTotal: action.data,
      };
    case "clear_store_ware":
      return initState;
    default:
      return state;
  }
};

export default storeWare;
