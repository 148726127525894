import {
  contractDataNewInterface,
  contractViewInterface,
  orderDataInterface,
  orderViewInterface,
  contractListInterface,
  orderListInterface,
  quotationDataInterface,
  quotationViewInterface,
  quotationListInterface,
  invoiceDataInterface,
  invoiceViewInterface,
  invoiceListInterface,
  receiptDataInterface,
  receiptViewInterface,
  receiptListInterface,
  stockDataInterface,
  stockCustomerDataInterface,
  stockCustomerManageInterface,
  stockViewInterface,
  stockCustomerViewInterface,
  stockListInterface,
  stockListCustomerInterface,
  deliveryDataInterface,
  deliveryViewInterface,
  deliveryNoteInterface,
  deliveryListInterface,
  receivedOrderDataInterface,
  receivedOrderViewInterface,
  receivedOrderListInterface,
  claimDataInterface,
  claimViewInterface,
  claimListInterface,
} from "../Interface";

export function contractAction(data: contractDataNewInterface) {
  return {
    type: "contract_data_data",
    data: data,
  };
}

export function contractViewAction(data: contractViewInterface) {
  return {
    type: "contract_view",
    data: data,
  };
}

export function orderAction(data: orderDataInterface) {
  return {
    type: "order_data",
    data: data,
  };
}

export function orderViewAction(data: orderViewInterface) {
  return {
    type: "order_view",
    data: data,
  };
}

export function quotationAction(data: quotationDataInterface) {
  return {
    type: "quotation_data",
    data: data,
  };
}

export function quotationViewAction(data: quotationViewInterface) {
  return {
    type: "quotation_view",
    data: data,
  };
}

export function invoiceAction(data: invoiceDataInterface) {
  return {
    type: "invoice_data",
    data: data,
  };
}

export function invoiceViewAction(data: invoiceViewInterface) {
  return {
    type: "invoice_view",
    data: data,
  };
}

export function receiptAction(data: receiptDataInterface) {
  return {
    type: "receipt_data",
    data: data,
  };
}

export function receiptViewAction(data: receiptViewInterface) {
  return {
    type: "receipt_view",
    data: data,
  };
}

export function stockAction(data: stockDataInterface) {
  return {
    type: "stock_data",
    data: data,
  };
}

export function stockCustomerAction(data: stockCustomerDataInterface) {
  return {
    type: "stock_customer_data",
    data: data,
  };
}

export function stockViewAction(data: stockViewInterface) {
  return {
    type: "stock_view",
    data: data,
  };
}

export function stockCustomerManageAction(
  data: stockCustomerManageInterface[]
) {
  return {
    type: "stock_customer_manage",
    data: data,
  };
}

export function stockCustomerViewAction(data: stockCustomerViewInterface) {
  return {
    type: "stock_customer_view",
    data: data,
  };
}

export function deliveryDataAction(data: deliveryDataInterface) {
  return {
    type: "delivery_data",
    data: data,
  };
}

export function deliveryViewAction(data: deliveryViewInterface) {
  return {
    type: "delivery_view",
    data: data,
  };
}

export function deliveryNoteAction(data: deliveryNoteInterface) {
  return {
    type: "delivery_note",
    data: data,
  };
}

export function receivedOrderDataAction(data: receivedOrderDataInterface) {
  return {
    type: "received_order_data",
    data: data,
  };
}

export function receivedOrderViewAction(data: receivedOrderViewInterface) {
  return {
    type: "received_order_view",
    data: data,
  };
}

export function claimDataAction(data: claimDataInterface) {
  return {
    type: "claim_data",
    data: data,
  };
}

export function claimViewAction(data: claimViewInterface) {
  return {
    type: "claim_view",
    data: data,
  };
}

export function contractListAction(data: contractListInterface[]) {
  return {
    type: "contract_list",
    data: data,
  };
}

export function contractListAllAction(data: contractListInterface[]) {
  return {
    type: "contract_list_all",
    data: data,
  };
}

export function contractListTotalAction(data: number) {
  return {
    type: "contract_list_total",
    data: data,
  };
}

export function orderListAction(data: orderListInterface[]) {
  return {
    type: "order_list",
    data: data,
  };
}

export function orderListAllAction(data: orderListInterface[]) {
  return {
    type: "order_list_all",
    data: data,
  };
}

export function orderListTotalAction(data: number) {
  return {
    type: "order_list_total",
    data: data,
  };
}

export function quotationListAction(data: quotationListInterface[]) {
  return {
    type: "quotation_list",
    data: data,
  };
}

export function quotationListAllAction(data: quotationListInterface[]) {
  return {
    type: "quotation_list_all",
    data: data,
  };
}

export function quotationListTotalAction(data: number) {
  return {
    type: "quotation_list_total",
    data: data,
  };
}

export function invoiceListAction(data: invoiceListInterface[]) {
  return {
    type: "invoice_list",
    data: data,
  };
}

export function invoiceListAllAction(data: invoiceListInterface[]) {
  return {
    type: "invoice_list_all",
    data: data,
  };
}

export function invoiceListTotalAction(data: number) {
  return {
    type: "invoice_list_total",
    data: data,
  };
}

export function receiptListAction(data: receiptListInterface[]) {
  return {
    type: "receipt_list",
    data: data,
  };
}

export function receiptListAllAction(data: receiptListInterface[]) {
  return {
    type: "receipt_list_all",
    data: data,
  };
}

export function receiptListTotalAction(data: number) {
  return {
    type: "receipt_list_total",
    data: data,
  };
}

export function stockListAction(data: stockListInterface[]) {
  return {
    type: "stock_list",
    data: data,
  };
}

export function stockListAllAction(data: stockListInterface[]) {
  return {
    type: "stock_list_all",
    data: data,
  };
}

export function stockListTotalAction(data: number) {
  return {
    type: "stock_list_total",
    data: data,
  };
}

export function stockListCustomerAction(data: stockListCustomerInterface[]) {
  return {
    type: "stock_list_customer",
    data: data,
  };
}

export function stockListAllCustomerAction(data: stockListCustomerInterface[]) {
  return {
    type: "stock_list_all_customer",
    data: data,
  };
}

export function stockListTotalCustomerAction(data: number) {
  return {
    type: "stock_list_total_customer",
    data: data,
  };
}

export function deliveryListAction(data: deliveryListInterface[]) {
  return {
    type: "delivery_list",
    data: data,
  };
}

export function deliveryListAllAction(data: deliveryListInterface[]) {
  return {
    type: "delivery_list_all",
    data: data,
  };
}

export function deliveryListTotalAction(data: number) {
  return {
    type: "delivery_list_total",
    data: data,
  };
}

export function receivedOrderListAction(data: receivedOrderListInterface[]) {
  return {
    type: "received_order_list",
    data: data,
  };
}

export function receivedOrderListAllAction(data: receivedOrderListInterface[]) {
  return {
    type: "received_order_list_all",
    data: data,
  };
}

export function receivedOrderListTotalAction(data: number) {
  return {
    type: "received_order_list_total",
    data: data,
  };
}

export function claimListAction(data: claimListInterface[]) {
  return {
    type: "claim_list",
    data: data,
  };
}

export function claimListAllAction(data: claimListInterface[]) {
  return {
    type: "claim_list_all",
    data: data,
  };
}

export function claimListTotalAction(data: number) {
  return {
    type: "claim_list_total",
    data: data,
  };
}

export function clearMainAction() {
  return {
    type: "clear_main",
    data: "",
  };
}
