export const dataFormProfile = {
  avatar: { value: "", error: "" },
  name: { value: "Name Surname", error: "" },
  first_name: { value: "", error: "" },
  last_name: { value: "", error: "" },
  email: { value: "Email", error: "" },
  role: { value: "Role & Permission", error: "" },
  old_password: { value: "", error: "" },
  new_password: { value: "", error: "" },
  confirm_password: { value: "", error: "" },
};
