import axios from "axios";
import { getCookie } from "@utils/Cookie";

const axiosBacklistBearer = [
  process.env.REACT_APP_API_SERVER_HOST + "/blacklist",
  "https://maps.googleapis.com",
];

const jwt = getCookie(process.env.REACT_APP_JWT_COOKIE_NAME);

axios.interceptors.request.use(
  (config) => {
    const url = config.url ?? "";
    if (
      axiosBacklistBearer.indexOf(url) < 0 &&
      url.split(".").indexOf("googleapis") < 0
    ) {
      const token = jwt ?? "";
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    // console.error("axios.interceptors.request.error", error);
    // return Promise.reject(error);
  }
);

export const Get = async (url: string, body?: any, headers?: any) => {
  try {
    return axios({
      method: "GET",
      url,
      params: {
        ...body,
      },
      headers: {
        ...headers,
      },
    })
      .then((res) => {
        return {
          status: true,
          message: res,
          code: res.data.code ?? 400,
        };
      })
      .catch((err) => {
        return {
          status: false,
          message: err,
          code: 400,
        };
      });
  } catch (error) {
    return {
      status: false,
      message: error,
      code: 400,
    };
  }
};

export const Post = async (url: string, body?: any, headers?: any) => {
  try {
    return axios({
      method: "POST",
      headers,
      url,
      data: body,
    })
      .then((res) => {
        return {
          status: true,
          message: res,
          code: res.data.code ?? 400,
        };
      })
      .catch((err) => {
        return {
          status: false,
          message: err,
          code: 400,
        };
      });
  } catch (error) {
    return {
      status: false,
      message: error,
      code: 400,
    };
  }
};

export const Patch = async (url: string, body?: any, headers?: any) => {
  try {
    return axios({
      method: "PATCH",
      url,
      data: {
        ...body,
      },
      headers: {
        ...headers,
      },
    })
      .then((res) => {
        return {
          status: true,
          message: res,
          code: res.data.code ?? 400,
        };
      })
      .catch((err) => {
        return {
          status: false,
          message: err,
          code: 400,
        };
      });
  } catch (error) {
    return {
      status: false,
      message: error,
      code: 400,
    };
  }
};

export const Put = async (url: string, body?: any, headers?: any) => {
  try {
    return axios({
      method: "PUT",
      url,
      data: {
        ...body,
      },
      headers: {
        ...headers,
      },
    })
      .then((res) => {
        return {
          status: true,
          message: res,
          code: res.data.code ?? 400,
        };
      })
      .catch((err) => {
        return {
          status: false,
          message: err,
          code: 400,
        };
      });
  } catch (error) {
    return {
      status: false,
      message: error,
      code: 400,
    };
  }
};

export const Delete = async (url: string, body?: any, headers?: any) => {
  try {
    return axios({
      method: "DELETE",
      headers,
      url,
      data: body,
    })
      .then((res) => {
        return {
          status: true,
          message: res,
          code: res.data.code ?? 400,
        };
      })
      .catch((err) => {
        return {
          status: false,
          message: err,
          code: 400,
        };
      });
  } catch (error) {
    return {
      status: false,
      message: error,
      code: 400,
    };
  }
};
