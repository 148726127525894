import { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { imgList } from "@constants/img";
import { iconList } from "@constants/icon";
import { dataFormProfile } from "./intitalValue";
import { Put, Post } from "@api/api";
import {
  openMenuAction,
  openProfileAction,
  openLogoutAction,
} from "@redux/Actions/storeAction";
import { userInfoAction } from "@redux/Actions/userAction";
import { formProfileInterface } from "./interface";
import { stateInterface } from "@redux/Interface";

const AsideProfile = () => {
  const dispatch = useDispatch();
  const store = useSelector((state: stateInterface) => state.store);
  const userInfo = useSelector((state: stateInterface) => state.user.userInfo);
  const [delayOpen, setDelayOpen] = useState<string>("");
  const [delayClose, setDelayClose] = useState<string>("");
  const [values, setValues] = useState<formProfileInterface>(dataFormProfile);
  const [load, setLoad] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean[]>([
    false,
    false,
    false,
  ]);
  const [pic, setPic] = useState<string>("");

  const _id = userInfo._id;
  const user_picture = userInfo.user_picture;

  const first_name = values.first_name.value;
  const last_name = values.last_name.value;

  useEffect(() => {
    initData();
  }, [userInfo]);

  useEffect(() => {
    if (values.avatar.value) {
      handleGetImage(values.avatar.value);
    }
  }, [values.avatar.value]);

  useEffect(() => {
    if (store.openProfile) {
      dispatch(openMenuAction(false));
      dispatch(openLogoutAction(false));
      setDelayClose(store.openProfile);
      setTimeout(() => {
        setDelayOpen(store.openProfile);
      }, 100);
    } else {
      setDelayOpen(store.openProfile);
      setTimeout(() => {
        setDelayClose(store.openProfile);
      }, 500);
    }
    clearData();
  }, [store.openProfile]);

  const initData = () => {
    let temp = values;
    let text_permission = "";
    userInfo.role.permissions.forEach((e, key) => {
      if (key > 0) {
        text_permission = text_permission + " / " + e.module_name;
      } else {
        text_permission = text_permission + e.module_name;
      }
    });
    temp = {
      ...temp,
      first_name: {
        value: userInfo.user_firstname,
        error: "",
      },
      last_name: {
        value: userInfo.user_lastname,
        error: "",
      },
      email: {
        value: userInfo.user_login_email,
        error: "",
      },
      // role: {
      //   value:
      //     "Role : " +
      //     userInfo.role.user_role_name +
      //     "<br/>Permissions : " +
      //     text_permission,
      //   error: "",
      // },
      role: {
        value: "Role<br/>" + userInfo.role.user_role_name,
        error: "",
      },
      avatar: {
        value: userInfo.user_picture,
        error: "",
      },
    };
    setValues(temp);
    setLoad(!load);
  };

  const handleGetImage = async (logo: string) => {
    const res = await apiGetImage(logo);
    const blob = URL.createObjectURL(res.data);
    setPic(blob);
  };

  const handleSubmitPassword = async () => {
    const validate = await handleValidatePassword();
    if (validate) {
      const res = await apiPutUserChangePassword({
        old_password: values.old_password.value,
        new_password: values.new_password.value,
      });
      if (res.status && res.code === 200) {
        setError("");
        dispatch(openProfileAction("profile"));
      } else {
        if (
          res.code === 400 &&
          res?.message?.response?.data?.message ===
            "ValidationError: new_password should have at least 6 characters!"
        ) {
          let tempValues = values;
          tempValues = {
            ...tempValues,
            new_password: {
              value: values.new_password.value,
              error: "Enter a New Password at least 6 characters",
            },
            confirm_password: {
              value: values.confirm_password.value,
              error: "Enter a Confirm Password at least 6 characters",
            },
          };
          setValues({
            ...values,
            ...tempValues,
          });
        } else if (
          res.code === 400 &&
          res?.message?.response?.data?.message ===
            "ValidationError: old_password should have at least 6 characters!"
        ) {
          let tempValues = values;
          tempValues = {
            ...tempValues,
            old_password: {
              value: values.old_password.value,
              error: "Enter an Old Password at least 6 characters",
            },
          };
          setValues({
            ...values,
            ...tempValues,
          });
        } else if (
          res.code === 400 &&
          res?.message?.response?.data?.message === "Password not match"
        ) {
          let tempValues = values;
          tempValues = {
            ...tempValues,
            old_password: {
              value: values.old_password.value,
              error: "Enter a valid Old Password",
            },
          };
          setValues({
            ...values,
            ...tempValues,
          });
        } else {
          setError(res?.message?.data?.message ?? "Something went wrong");
        }
      }
    }
  };

  const handleSubmitEdit = async () => {
    const validate = await handleValidateEdit();
    if (validate) {
      const res = await apiPutUserProfile({
        user_firstname: values.first_name.value,
        user_lastname: values.last_name.value,
        user_picture: values.avatar.value,
      });
      if (res.status && res.code === 200) {
        setError("");
        dispatch(
          userInfoAction({
            ...userInfo,
            user_firstname: values.first_name.value,
            user_lastname: values.last_name.value,
            user_picture: values.avatar.value,
          })
        );
        dispatch(openProfileAction("profile"));
      } else {
        setError(res?.message?.data?.message ?? "Something went wrong");
      }
    }
  };

  const handleValidatePassword = () => {
    const old_password = values?.old_password?.value ?? "";
    const new_password = values?.new_password?.value ?? "";
    const confirm_password = values?.confirm_password?.value ?? "";
    let tempValues = values;
    let success = true;
    if (!old_password) {
      tempValues = {
        ...tempValues,
        old_password: {
          value: old_password,
          error: "Enter an Old Password",
        },
      };
      success = false;
    } else {
      tempValues = {
        ...tempValues,
        old_password: {
          value: old_password,
          error: "",
        },
      };
    }
    if (!new_password) {
      tempValues = {
        ...tempValues,
        new_password: {
          value: new_password,
          error: "Enter a New Password",
        },
      };
      success = false;
    } else {
      tempValues = {
        ...tempValues,
        new_password: {
          value: new_password,
          error: "",
        },
      };
    }
    if (!confirm_password) {
      tempValues = {
        ...tempValues,
        confirm_password: {
          value: confirm_password,
          error: "Enter a Confirm Password",
        },
      };
      success = false;
    } else {
      tempValues = {
        ...tempValues,
        confirm_password: {
          value: confirm_password,
          error: "",
        },
      };
    }
    if (new_password && confirm_password) {
      if (new_password !== confirm_password) {
        tempValues = {
          ...tempValues,
          confirm_password: {
            value: confirm_password,
            error: "Passwords don't match",
          },
        };
        success = false;
      } else {
        tempValues = {
          ...tempValues,
          confirm_password: {
            value: confirm_password,
            error: "",
          },
        };
      }
    }
    setValues({
      ...values,
      ...tempValues,
    });
    return success;
  };

  const handleValidateEdit = () => {
    let tempValues = values;
    let success = true;
    if (!first_name) {
      tempValues = {
        ...tempValues,
        first_name: {
          value: first_name,
          error: "Enter an First Name",
        },
      };
      success = false;
    }
    if (!last_name) {
      tempValues = {
        ...tempValues,
        last_name: {
          value: last_name,
          error: "Enter an Last Name",
        },
      };
      success = false;
    }
    setValues({
      ...values,
      ...tempValues,
    });
    return success;
  };

  const handleToUpload = () => {
    document?.getElementById("new-avatar")?.click();
  };

  const handleUploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
      setValues({
        ...values,
        avatar: {
          value: values.avatar.value,
          error: "Enter Picture Format JPG | JPEG | PNG",
        },
      });
    } else {
      const formData = new FormData();
      formData.append("image", file);
      const res = await apiUploadImage(formData);
      if (res.status && (res.code === 200 || res.code === 201)) {
        setValues({
          ...values,
          avatar: {
            value: res?.message?.data?.data?._id ?? "",
            error: "",
          },
        });
        setLoad(!load);
      } else {
        (document.getElementById("new-avatar") as HTMLFormElement).value = "";
      }
    }
  };

  const clearData = () => {
    setError("");
    setValues({
      ...values,
      first_name: {
        value: userInfo.user_firstname,
        error: "",
      },
      last_name: {
        value: userInfo.user_lastname,
        error: "",
      },
      avatar: {
        value: user_picture,
        error: "",
      },
      old_password: { value: "", error: "" },
      new_password: { value: "", error: "" },
      confirm_password: { value: "", error: "" },
    });
    setShowPassword([false, false, false]);
    (document.getElementById("new-avatar") as HTMLFormElement).value = "";
  };

  const apiPutUserChangePassword = (body: {
    old_password: string;
    new_password: string;
  }) => {
    return Put(
      process.env.REACT_APP_API_SERVER_HOST + "/users/" + _id + "/password",
      { ...body }
    );
  };

  const apiPutUserProfile = (body: {
    user_firstname: string;
    user_lastname: string;
    user_picture: string;
  }) => {
    return Put(
      process.env.REACT_APP_API_SERVER_HOST + "/users/profile/" + _id,
      {
        ...body,
      }
    );
  };

  const apiGetImage = (image: string) => {
    return axios.get(
      process.env.REACT_APP_API_SERVER_HOST + "/images/assets/" + image,
      { responseType: "blob" }
    );
  };

  const apiUploadImage = (body: any) => {
    return Post(process.env.REACT_APP_API_SERVER_HOST + "/images", body);
  };

  return (
    <Content className={"aside" + (delayClose ? " open" : "")}>
      <div className={"aside__inner" + (delayOpen ? " open" : "")}>
        <Profile
          className={"profile" + (delayClose === "profile" ? " open" : "")}
        >
          <div className="profile__row">
            <div className="profile__top">
              <div className="profile__head">My Profile</div>
              <img
                src={imgList.close}
                alt=""
                className="profile__close"
                onClick={() =>
                  dispatch(
                    openProfileAction(store.openProfile ? "" : "profile")
                  )
                }
              />
            </div>
            <div className="profile__avatar">
              <img src={pic ? pic : imgList.avatar} alt="" />
            </div>
            <div>
              {/* <div className="profile__text-data">
                {first_name + " " + last_name}
              </div>
              <div className="profile__text-data">
                {values?.email?.value ?? ""}
              </div>
              <div
                className="profile__text-data"
                dangerouslySetInnerHTML={{ __html: values?.role?.value ?? "" }}
              ></div> */}
              <input
                type="text"
                className="profile__input profile__input--edit"
                value={first_name + " " + last_name}
                placeholder="Name"
                readOnly
              />
              <input
                type="text"
                className="profile__input profile__input--edit"
                value={values?.email?.value ?? ""}
                placeholder="Email"
                readOnly
              />
              <div className="profile__text-data profile__text-data--role">
                Role
              </div>
              <input
                type="text"
                className="profile__input profile__input--edit"
                value={userInfo?.role.user_role_name ?? ""}
                placeholder="Role"
                readOnly
              />
              {/* <input
                type="text"
                className="profile__input"
                value={values?.name?.value ?? ""}
                placeholder="Name"
                readOnly
              />
              <input
                type="text"
                className="profile__input"
                value={values?.email?.value ?? ""}
                placeholder="Email"
                readOnly
              />
              <input
                type="text"
                className="profile__input"
                defaultValue={values?.role?.value ?? ""}
                placeholder="Role & Permission"
                readOnly
              /> */}
            </div>
          </div>
          <div className="profile__btn-group">
            <div
              className="profile__btn profile__btn--white"
              id="go-edit-password"
              onClick={() => dispatch(openProfileAction("profile_password"))}
            >
              Change Password
            </div>
            <div
              className="profile__btn profile__btn--blue"
              id="go-edit-profile"
              onClick={() => dispatch(openProfileAction("profile_edit"))}
            >
              Edit Profile
            </div>
          </div>
        </Profile>
        <Profile
          className={
            "profile" + (delayClose === "profile_password" ? " open" : "")
          }
        >
          <div className="profile__row">
            <div className="profile__top">
              <div className="profile__head">
                <img
                  src={imgList.arrow}
                  alt=""
                  onClick={() => dispatch(openProfileAction("profile"))}
                />
                Change Password
              </div>
              <img
                src={imgList.close}
                alt=""
                className="profile__close"
                onClick={() =>
                  dispatch(
                    openProfileAction(store.openProfile ? "" : "profile")
                  )
                }
              />
            </div>
            <div className="profile__avatar">
              <img src={pic ? pic : imgList.avatar} alt="" />
            </div>
            <div>
              <form>
                <div className="profile__input-warp">
                  <div className="profile__box">
                    <input
                      type={showPassword[0] ? "text" : "password"}
                      className={
                        "profile__input profile__input--password profile__input--1" +
                        (values?.old_password?.error ?? ""
                          ? " profile__input--error"
                          : "")
                      }
                      placeholder="Old Password"
                      autoComplete="off"
                      name="old_password"
                      id="old_password"
                      value={values?.old_password?.value ?? ""}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          old_password: {
                            value: e.target.value,
                            error: "",
                          },
                        })
                      }
                    />
                    {showPassword[0] === false ? (
                      <img
                        src={iconList.icon_password_lock}
                        alt=""
                        className="profile__icon profile__icon--1"
                        onClick={() =>
                          setShowPassword([
                            true,
                            showPassword[1],
                            showPassword[2],
                          ])
                        }
                      />
                    ) : (
                      <img
                        src={iconList.icon_password_unlock}
                        alt=""
                        className="profile__icon profile__icon--1"
                        onClick={() =>
                          setShowPassword([
                            false,
                            showPassword[1],
                            showPassword[2],
                          ])
                        }
                      />
                    )}
                  </div>
                  <div
                    className={
                      "profile__error" +
                      (values?.old_password?.error ?? "" ? " active" : "")
                    }
                  >
                    {values?.old_password?.error ?? ""}
                  </div>
                </div>
                <div className="profile__input-warp">
                  <div className="profile__box">
                    <input
                      type={showPassword[1] ? "text" : "password"}
                      className={
                        "profile__input profile__input--password profile__input--2" +
                        (values?.new_password?.error ?? ""
                          ? " profile__input--error"
                          : "")
                      }
                      placeholder="New Password"
                      autoComplete="off"
                      name="new_password"
                      id="new_password"
                      value={values?.new_password?.value ?? ""}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          new_password: {
                            value: e.target.value,
                            error: "",
                          },
                        })
                      }
                    />
                    {showPassword[1] === false ? (
                      <img
                        src={iconList.icon_password_lock}
                        alt=""
                        className="profile__icon profile__icon--1"
                        onClick={() =>
                          setShowPassword([
                            showPassword[0],
                            true,
                            showPassword[2],
                          ])
                        }
                      />
                    ) : (
                      <img
                        src={iconList.icon_password_unlock}
                        alt=""
                        className="profile__icon profile__icon--1"
                        onClick={() =>
                          setShowPassword([
                            showPassword[0],
                            false,
                            showPassword[2],
                          ])
                        }
                      />
                    )}
                  </div>
                  <div
                    className={
                      "profile__error" +
                      (values?.new_password?.error ?? "" ? " active" : "")
                    }
                  >
                    {values?.new_password?.error ?? ""}
                  </div>
                </div>
                <div className="profile__input-warp">
                  <div className="profile__box">
                    <input
                      type={showPassword[2] ? "text" : "password"}
                      className={
                        "profile__input profile__input--password profile__input--3" +
                        (values?.confirm_password?.error ?? ""
                          ? " profile__input--error"
                          : "")
                      }
                      placeholder="Confirm Password"
                      autoComplete="off"
                      name="new_password_confirm"
                      id="new_password_confirm"
                      value={values?.confirm_password?.value ?? ""}
                      onChange={(e) =>
                        setValues({
                          ...values,
                          confirm_password: {
                            value: e.target.value,
                            error: "",
                          },
                        })
                      }
                    />
                    {showPassword[2] === false ? (
                      <img
                        src={iconList.icon_password_lock}
                        alt=""
                        className="profile__icon profile__icon--1"
                        onClick={() =>
                          setShowPassword([
                            showPassword[0],
                            showPassword[1],
                            true,
                          ])
                        }
                      />
                    ) : (
                      <img
                        src={iconList.icon_password_unlock}
                        alt=""
                        className="profile__icon profile__icon--1"
                        onClick={() =>
                          setShowPassword([
                            showPassword[0],
                            showPassword[1],
                            false,
                          ])
                        }
                      />
                    )}
                  </div>
                  <div
                    className={
                      "profile__error" +
                      (values?.confirm_password?.error ?? "" ? " active" : "")
                    }
                  >
                    {values?.confirm_password?.error ?? ""}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="profile__btn-group">
            <div className="profile__error-main">{error}</div>
            <div
              className="profile__btn profile__btn--red"
              id="save-password"
              onClick={() => dispatch(openProfileAction("profile"))}
            >
              Cancel
            </div>
            <div
              className="profile__btn profile__btn--blue"
              id="save-password"
              onClick={() => handleSubmitPassword()}
            >
              Save Changes
            </div>
          </div>
        </Profile>
        <Profile
          className={"profile" + (delayClose === "profile_edit" ? " open" : "")}
        >
          <div className="profile__row">
            <div className="profile__top">
              <div className="profile__head">
                <img
                  src={imgList.arrow}
                  alt=""
                  className="go-profile"
                  onClick={() => dispatch(openProfileAction("profile"))}
                />
                Edit Profile
              </div>
              <img
                src={imgList.close}
                alt=""
                className="profile__close close-profile"
                onClick={() =>
                  dispatch(
                    openProfileAction(store.openProfile ? "" : "profile")
                  )
                }
              />
            </div>
            <div className="profile__avatar profile__avatar--edit">
              <img
                src={pic ? pic : values.avatar.value}
                alt=""
                id="change-avatar-img"
              />
            </div>
            <div className="profile__error profile__error--center">
              {values?.avatar?.error ?? ""}
            </div>
            <div
              className="profile__text"
              id="change-avatar"
              onClick={() => handleToUpload()}
            >
              Change Profile Photo
            </div>
            <input
              type="file"
              name="new-avatar"
              id="new-avatar"
              className="profile__file"
              onChange={(e) => handleUploadImage(e)}
            />
            <div>
              <div className="profile__box">
                <input
                  type="text"
                  className={
                    "profile__input profile__input--edit" +
                    (values?.first_name?.error ?? ""
                      ? " profile__input--error"
                      : "")
                  }
                  value={values?.first_name?.value ?? ""}
                  placeholder="First Name"
                  name="profile_first_name"
                  id="profile_first_name"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      first_name: {
                        value: e.target.value,
                        error: "",
                      },
                    })
                  }
                />
                <img
                  src={imgList.edit}
                  alt=""
                  className="profile__icon profile__icon--edit"
                />
              </div>
              <div
                className={
                  "profile__error" +
                  (values?.first_name?.error ?? "" ? " active" : "")
                }
              >
                {values?.first_name?.error ?? ""}
              </div>
              <div className="profile__box">
                <input
                  type="text"
                  className={
                    "profile__input profile__input--edit" +
                    (values?.last_name?.error ?? ""
                      ? " profile__input--error"
                      : "")
                  }
                  value={values?.last_name?.value ?? ""}
                  placeholder="Last Name"
                  name="profile_last_name"
                  id="profile_last_name"
                  onChange={(e) =>
                    setValues({
                      ...values,
                      last_name: {
                        value: e.target.value,
                        error: "",
                      },
                    })
                  }
                />
                <img
                  src={imgList.edit}
                  alt=""
                  className="profile__icon profile__icon--edit"
                />
              </div>
              <div
                className={
                  "profile__error" +
                  (values?.last_name?.error ?? "" ? " active" : "")
                }
              >
                {values?.last_name?.error ?? ""}
              </div>
              {/* <input
                type="text"
                className="profile__input"
                defaultValue={values?.email?.value ?? ""}
                placeholder="Email"
                readOnly
              /> */}
              {/* <div className="profile__text-data">
                {values?.email?.value ?? ""}
              </div> */}
              <input
                type="text"
                className="profile__input profile__input--edit"
                value={values?.email?.value ?? ""}
                placeholder="Email"
                readOnly
              />
              {/* <div className="profile__error" id="error_profile_email"></div> */}
              {/* <input
                type="text"
                className="profile__input"
                defaultValue={values?.role?.value ?? ""}
                placeholder="Role & Permission"
                readOnly
              /> */}
              <div className="profile__text-data profile__text-data--role">
                Role
              </div>
              <input
                type="text"
                className="profile__input profile__input--edit"
                value={userInfo?.role.user_role_name ?? ""}
                placeholder="Role"
                readOnly
              />
              {/* <div className="profile__error" id="error_profile_role"></div> */}
            </div>
          </div>
          <div className="profile__btn-group profile__btn-group--center">
            <div className="profile__error-main">{error}</div>
            <div
              className="profile__btn profile__btn--blue"
              onClick={() => handleSubmitEdit()}
            >
              Save Changes
            </div>
          </div>
        </Profile>
      </div>
    </Content>
  );
};

export default AsideProfile;

const Content = styled.div`
  position: absolute;
  right: 0;
  width: 30%;
  height: calc(100vh - 4.5vw);
  display: none;
  &.open {
    display: block;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    top: 7.8vw;
    height: calc(100vh - 7.8vw);
  }
  @media only screen and (max-width: 450px) {
    top: 16vw;
    height: calc(100vh - 16vw);
  }
  .aside {
    &__inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      -webkit-transform: translateY(-120%);
      transform: translateY(-120%);
      transition: -webkit-transform 1s cubic-bezier(0.8, 0, 0.1, 1);
      transition: transform 1s cubic-bezier(0.8, 0, 0.1, 1);
      transition: transform 1s cubic-bezier(0.8, 0, 0.1, 1),
        -webkit-transform 1s cubic-bezier(0.8, 0, 0.1, 1);
      will-change: transform;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow-y: scroll;
      overflow-x: hidden;
      &.open {
        transform: translateY(0);
      }
    }
  }
`;

const Profile = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 6%;
  background-color: #fff;
  overflow: auto;
  -webkit-transform: translateX(120%);
  transform: translateX(120%);
  transition: -webkit-transform 1s cubic-bezier(0.8, 0, 0.1, 1);
  transition: transform 0.5s cubic-bezier(0.8, 0, 0.1, 1);
  transition: transform 0.5s cubic-bezier(0.8, 0, 0.1, 1),
    -webkit-transform 0.5s cubic-bezier(0.8, 0, 0.1, 1);
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    display: none;
  }
  &.open {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  @media only screen and (max-width: 1024px) {
    padding: 30px 20px;
  }
  @media only screen and (max-width: 450px) {
    padding: 25px 15px;
  }
  .profile {
    &__row {
      &--last {
        margin: 8% 0 0;
      }
    }
    &__top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__head {
      font-family: "SFProDisplay-Medium";
      font-weight: bold;
      font-size: 1.9vw;
      line-height: 1.5;
      display: flex;
      align-items: center;
      color: #000;
      > img {
        width: 1.3vw;
        margin: 0 1vw 0 0;
        transform: rotate(90deg);
        cursor: pointer;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 4vw;
        > img {
          width: 3vw;
          margin: 0 2vw 0 0;
        }
      }
      @media only screen and (max-width: 450px) {
        font-size: 6vw;
        > img {
          width: 4.5vw;
          margin: 0 3vw 0 0;
        }
      }
    }
    &__close {
      width: 5%;
      cursor: pointer;
      @media only screen and (max-width: 1024px) {
        width: 3.2%;
      }
      @media only screen and (max-width: 450px) {
        width: 5%;
      }
    }
    &__avatar {
      position: relative;
      width: 38%;
      padding-top: 38%;
      margin: 14% auto 16%;
      overflow: hidden;
      border-radius: 50%;
      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
        object-fit: cover;
      }
      &--edit {
        margin: 14% auto 3%;
      }
      @media only screen and (max-width: 1024px) {
        width: 25%;
        padding-top: 25%;
        margin: 6% auto 8%;
        &--edit {
          margin: 6% auto 2%;
        }
      }
      @media only screen and (max-width: 450px) {
        width: 35%;
        padding-top: 35%;
        margin: 14% auto 16%;
        &--edit {
          margin: 14% auto 3%;
        }
      }
    }
    &__icon {
      position: absolute;
      width: 5%;
      top: 43%;
      right: 0;
      transform: translate(0, -50%);
      cursor: pointer;
      &--edit {
        width: 7%;
        cursor: default;
      }
    }
    &__input-warp {
      position: relative;
      width: 100%;
    }
    &__text-data {
      font-family: "SFProDisplay-Regular";
      font-weight: normal;
      font-size: 1.2vw;
      color: #000000;
      line-height: 1.3;
      margin: 8% 0;
      &--role {
        margin: 5% 0 -4%;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 2.9vw;
        margin: 4% 0;
        &--role {
          margin: 5% 0 -2%;
        }
      }
      @media only screen and (max-width: 450px) {
        font-size: 4.4vw;
        margin: 6% 0;
        &--role {
          margin: 5% 0 -4%;
        }
      }
    }
    &__input {
      width: 100%;
      outline: none;
      font-family: "SFProDisplay-Regular";
      font-weight: normal;
      font-size: 1.2vw;
      color: #000000;
      line-height: 2;
      padding: 0;
      margin: 4% 0;
      border: none;
      border-radius: 0;
      &--password {
        color: #4774c2;
        padding: 0 7% 0 0;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: solid 1px #e8e8e8;
      }
      &--edit {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: solid 1px #e8e8e8;
        padding-right: 8%;
      }
      &--error {
        border-bottom: solid 1px red;
      }
      &:focus {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: solid 1px #e8e8e8;
      }
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #a8a8a8;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #a8a8a8;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #a8a8a8;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 2.9vw;
        margin: 3% 0;
      }
      @media only screen and (max-width: 450px) {
        font-size: 4.4vw;
        margin: 4% 0;
      }
    }
    &__btn-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 8% 0 0;
      &--center {
        justify-content: center;
      }
    }
    &__btn {
      width: 47%;
      font-family: "SFProDisplay-Medium";
      font-weight: bold;
      font-size: 1.2vw;
      line-height: 2.5;
      margin: 4% 0;
      text-align: center;
      border-radius: 0.521vw;
      cursor: pointer;
      display: block;
      &--white {
        color: #4774c2;
        border: solid 1px #4774c2;
        background-color: #ffffff;
      }
      &--blue {
        color: #ffff;
        border: solid 1px #4774c2;
        background-color: #4774c2;
      }
      &--red {
        color: #ff3b3b;
        border: solid 1px #ff3b3b;
        background-color: #ffffff;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 3.3vw;
        margin: 2% 0;
        border-radius: 0.977vw;
      }
      @media only screen and (max-width: 450px) {
        width: 48%;
        font-size: 4.6vw;
        margin: 4% 0;
        border-radius: 2.222vw;
      }
    }
    &__box {
      position: relative;
      width: 100%;
    }
    &__error {
      font-family: "SFProDisplay-Regular";
      font-weight: normal;
      font-size: 1vw;
      margin: -4% 0 0;
      color: red;
      text-align: right;
      line-height: 1.5;
      display: none;
      &.active {
        display: block;
      }
      &--center {
        text-align: center;
        margin: -2% 0 0;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 2vw;
        line-height: 3;
      }
      @media only screen and (max-width: 450px) {
        font-size: 3vw;
        line-height: 2;
      }
    }
    &__error-main {
      width: 100%;
      font-family: "SFProDisplay-Regular";
      font-weight: normal;
      font-size: 1vw;
      color: red;
      text-align: center;
      line-height: 1.5;
      @media only screen and (max-width: 1024px) {
        font-size: 2vw;
        line-height: 3;
      }
      @media only screen and (max-width: 450px) {
        font-size: 3vw;
        line-height: 2;
      }
    }
    &__text {
      font-family: "SFProDisplay-Medium";
      font-weight: 500;
      font-size: 1.2vw;
      color: #4e4e4e;
      text-align: center;
      margin: 0 0 13%;
      cursor: pointer;
      text-decoration: underline;
      @media only screen and (max-width: 1024px) {
        font-size: 2.9vw;
        margin: 0 0 4%;
      }
      @media only screen and (max-width: 450px) {
        font-size: 4.4vw;
        margin: 0 0 13%;
      }
    }
    &__file {
      width: 0px;
      height: 0px;
      display: none;
      pointer-events: none;
    }
  }
`;
