import styled from "styled-components";
import { Modal } from "antd";
import { useSelector } from "react-redux";

import { iconList } from "@constants/icon";
import { modalMessageInterface } from "./interface";
import { stateInterface } from "@redux/Interface";

const ModalForgotPasswordFail = ({
  onOk = () => {},
}: modalMessageInterface) => {
  const modal = useSelector((state: stateInterface) => state.store.modal);
  const handleOk = () => {
    onOk();
  };

  return (
    <Content className="modal" id="modal-message-content-forgot-fail">
      <Modal
        centered
        visible={modal.open === "forgotfail"}
        footer={null}
        closable={false}
        getContainer={() =>
          document.getElementById(
            "modal-message-content-forgot-fail"
          ) as HTMLElement
        }
        onCancel={() => handleOk()}
      >
        <img src={iconList.icon_error} alt="" className="modal__icon" />
        <div className="modal__head">Your Request is Failed</div>
        <div
          className="modal__text"
          dangerouslySetInnerHTML={{ __html: modal.title ?? "" }}
        />
        <div className="modal__btn" onClick={() => handleOk()}>
          Reset Password
        </div>
      </Modal>
    </Content>
  );
};

export default ModalForgotPasswordFail;

const Content = styled.div`
  padding: 0 1.458vw;
  & .ant-modal {
    width: 24.5vw !important;
    max-width: 100%;
  }
  & .ant-modal-content {
    border-radius: 15px;
  }
  & .ant-modal-body {
    padding: 2vw 1.458vw;
  }
  @media only screen and (max-width: 1024px) {
    & .ant-modal {
      width: 60vw !important;
    }
    & .ant-modal-body {
      padding: 3vw 2.734vw;
    }
  }
  @media only screen and (max-width: 450px) {
    & .ant-modal {
      width: 85% !important;
    }
    & .ant-modal-body {
      padding: 6.5vw 6.222vw;
    }
  }
  .modal {
    &__icon {
      width: 15%;
      margin: 0 auto 3%;
    }
    &__head {
      font-family: "SFProDisplay-Medium";
      font-weight: 500;
      font-size: 1.2vw;
      text-align: center;
      color: #ff3b3b;
      @media only screen and (max-width: 1024px) {
        font-size: 2.8vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 4.5vw;
      }
    }
    &__text {
      font-family: "SFProDisplay-Medium";
      font-weight: 500;
      font-size: 0.9vw;
      text-align: center;
      color: #9b9b9b;
      margin: 1% 0 0;
      @media only screen and (max-width: 1024px) {
        font-size: 2.3vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 3.1vw;
      }
    }
    &__btn {
      font-family: "SFProDisplay-Medium";
      font-weight: 500;
      font-size: 1.2vw;
      color: #fff;
      width: 14vw;
      padding: 0.6vw 0;
      margin: 3% auto 0;
      border-radius: 10px;
      border: solid 1px #4774c2;
      background-color: #4774c2;
      text-align: center;
      cursor: pointer;
      @media only screen and (max-width: 1024px) {
        font-size: 2.8vw;
        width: 30vw;
        padding: 1vw 0;
      }
      @media only screen and (max-width: 450px) {
        font-size: 4.5vw;
        width: 45vw;
        padding: 1.5vw 0;
      }
    }
  }
`;
