import { Suspense, lazy, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  RouteProps,
} from "react-router-dom";
import ProgressBar from "react-topbar-progress-indicator";

import { Get } from "@api/api";
import { Layout, LayoutAuth } from "@components/Layout";
import { getCookie } from "@utils/Cookie";
import { permissionWithScope } from "@utils/PermissionWithScope";
import { userInfoAction } from "@redux/Actions/userAction";
import {
  permissionsInterface,
  permissionsScopeInterface,
} from "@redux/Interface";
import { stateInterface } from "@redux/Interface";

const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Auth/Login"));
const Forgot = lazy(() => import("./pages/Auth/Forgot"));
const CreateNewPassword = lazy(() => import("./pages/Auth/CreateNewPassword"));
const Error404 = lazy(() => import("./pages/Error/Error404"));

//Main
const ContractList = lazy(() => import("./pages/Main/Contract/List"));
const ContractAdd = lazy(() => import("./pages/Main/Contract/Add"));
const ContractView = lazy(() => import("./pages/Main/Contract/View"));
const ContractEdit = lazy(() => import("./pages/Main/Contract/Edit"));
const ContractHistory = lazy(() => import("./pages/Main/Contract/History"));
const OrderList = lazy(() => import("./pages/Main/Order/List"));
const OrderAdd = lazy(() => import("./pages/Main/Order/Add"));
const OrderView = lazy(() => import("./pages/Main/Order/View"));
const OrderEdit = lazy(() => import("./pages/Main/Order/Edit"));
const OrderHistory = lazy(() => import("./pages/Main/Order/History"));
const QuotationList = lazy(() => import("./pages/Main/Quotation/List"));
const QuotationAdd = lazy(() => import("./pages/Main/Quotation/Add"));
const QuotationView = lazy(() => import("./pages/Main/Quotation/View"));
const QuotationEdit = lazy(() => import("./pages/Main/Quotation/Edit"));
const QuotationHistory = lazy(() => import("./pages/Main/Quotation/History"));
const InvoiceList = lazy(() => import("./pages/Main/Invoice/List"));
const InvoiceAdd = lazy(() => import("./pages/Main/Invoice/Add"));
const InvoiceView = lazy(() => import("./pages/Main/Invoice/View"));
const InvoiceEdit = lazy(() => import("./pages/Main/Invoice/Edit"));
const InvoiceHistory = lazy(() => import("./pages/Main/Invoice/History"));
const ReceiptList = lazy(() => import("./pages/Main/Receipt/List"));
const ReceiptAdd = lazy(() => import("./pages/Main/Receipt/Add"));
const ReceiptView = lazy(() => import("./pages/Main/Receipt/View"));
const ReceiptEdit = lazy(() => import("./pages/Main/Receipt/Edit"));
const ReceiptHistory = lazy(() => import("./pages/Main/Receipt/History"));
const DeliveryOrderList = lazy(() => import("./pages/Main/DeliveryOrder/List"));
const DeliveryOrderAdd = lazy(() => import("./pages/Main/DeliveryOrder/Add"));
const DeliveryOrderView = lazy(() => import("./pages/Main/DeliveryOrder/View"));
const DeliveryOrderEdit = lazy(() => import("./pages/Main/DeliveryOrder/Edit"));
const DeliveryOrderNote = lazy(() => import("./pages/Main/DeliveryOrder/Note"));
const ReceivedOrderList = lazy(() => import("./pages/Main/ReceivedOrder/List"));
const ReceivedOrderAdd = lazy(() => import("./pages/Main/ReceivedOrder/Add"));
const ReceivedOrderView = lazy(() => import("./pages/Main/ReceivedOrder/View"));
const ReceivedOrderEdit = lazy(() => import("./pages/Main/ReceivedOrder/Edit"));
const StockInventoryList = lazy(
  () => import("./pages/Main/StockInventory/List")
);
const StockInventoryAdd = lazy(() => import("./pages/Main/StockInventory/Add"));
const StockInventoryView = lazy(
  () => import("./pages/Main/StockInventory/View")
);
const StockInventoryEdit = lazy(
  () => import("./pages/Main/StockInventory/Edit")
);
const StockInventoryManage = lazy(
  () => import("./pages/Main/StockInventory/Manage")
);
const StockInventoryHistory = lazy(
  () => import("./pages/Main/StockInventory/History")
);
const StockInventoryAddWithdraw = lazy(
  () => import("./pages/Main/StockInventory/AddWithdraw")
);
const StockInventoryAddReceived = lazy(
  () => import("./pages/Main/StockInventory/AddReceived")
);
const StockInventoryListCustomer = lazy(
  () => import("./pages/Main/StockInventory/ListCustomer")
);
const StockInventoryAddCustomer = lazy(
  () => import("./pages/Main/StockInventory/AddCustomer")
);
const StockInventoryViewCustomer = lazy(
  () => import("./pages/Main/StockInventory/ViewCustomer")
);
const StockInventoryManageCustomer = lazy(
  () => import("./pages/Main/StockInventory/ManageCustomer")
);
const StockInventoryHistoryCustomer = lazy(
  () => import("./pages/Main/StockInventory/HistoryCustomer")
);
const ClaimList = lazy(() => import("./pages/Main/Claim/List"));
const ClaimAdd = lazy(() => import("./pages/Main/Claim/Add"));
const ClaimView = lazy(() => import("./pages/Main/Claim/View"));
const ClaimEdit = lazy(() => import("./pages/Main/Claim/Edit"));

//Master Data
const WarehouseList = lazy(() => import("./pages/MasterData/Warehouse/List"));
const WarehouseAdd = lazy(() => import("./pages/MasterData/Warehouse/Add"));
const WarehouseView = lazy(() => import("./pages/MasterData/Warehouse/View"));
const WarehouseEdit = lazy(() => import("./pages/MasterData/Warehouse/Edit"));
const CompanyList = lazy(
  () => import("./pages/MasterData/Customer/Company/List")
);
const CompanyAdd = lazy(
  () => import("./pages/MasterData/Customer/Company/Add")
);
const CompanyView = lazy(
  () => import("./pages/MasterData/Customer/Company/View")
);
const CompanyEdit = lazy(
  () => import("./pages/MasterData/Customer/Company/Edit")
);
const CustomerBranchList = lazy(
  () => import("./pages/MasterData/Customer/Branch/List")
);
const CustomerBranchAdd = lazy(
  () => import("./pages/MasterData/Customer/Branch/Add")
);
const CustomerBranchView = lazy(
  () => import("./pages/MasterData/Customer/Branch/View")
);
const CustomerBranchEdit = lazy(
  () => import("./pages/MasterData/Customer/Branch/Edit")
);
const ShippingAddressList = lazy(
  () => import("./pages/MasterData/Customer/Shipping/List")
);
const ShippingAddressAdd = lazy(
  () => import("./pages/MasterData/Customer/Shipping/Add")
);
const ShippingAddressView = lazy(
  () => import("./pages/MasterData/Customer/Shipping/View")
);
const ShippingAddressEdit = lazy(
  () => import("./pages/MasterData/Customer/Shipping/Edit")
);
const ProductList = lazy(
  () => import("./pages/MasterData/Product/Product/List")
);
const ProductAdd = lazy(() => import("./pages/MasterData/Product/Product/Add"));
const ProductView = lazy(
  () => import("./pages/MasterData/Product/Product/View")
);
const ProductEdit = lazy(
  () => import("./pages/MasterData/Product/Product/Edit")
);
const ProductTypeList = lazy(
  () => import("./pages/MasterData/Product/ProductType/List")
);
const ProductTypeAdd = lazy(
  () => import("./pages/MasterData/Product/ProductType/Add")
);
const ProductTypeView = lazy(
  () => import("./pages/MasterData/Product/ProductType/View")
);
const ProductTypeEdit = lazy(
  () => import("./pages/MasterData/Product/ProductType/Edit")
);
const ProductRateList = lazy(
  () => import("./pages/MasterData/Product/ProductRate/List")
);
const ProductRateAdd = lazy(
  () => import("./pages/MasterData/Product/ProductRate/Add")
);
const ProductRateView = lazy(
  () => import("./pages/MasterData/Product/ProductRate/View")
);
const ProductRateEdit = lazy(
  () => import("./pages/MasterData/Product/ProductRate/Edit")
);

//Settings
const Mycompany = lazy(() => import("./pages/Setting/MyCompany/View"));
const MycompanyEdit = lazy(() => import("./pages/Setting/MyCompany/Edit"));
const BranchList = lazy(() => import("./pages/Setting/Branch/List"));
const BranchAdd = lazy(() => import("./pages/Setting/Branch/Add"));
const BranchView = lazy(() => import("./pages/Setting/Branch/View"));
const BranchEdit = lazy(() => import("./pages/Setting/Branch/Edit"));
const DivisionList = lazy(() => import("./pages/Setting/Division/List"));
const DivisionAdd = lazy(() => import("./pages/Setting/Division/Add"));
const DivisionView = lazy(() => import("./pages/Setting/Division/View"));
const DivisionEdit = lazy(() => import("./pages/Setting/Division/Edit"));
const DepartmentList = lazy(() => import("./pages/Setting/Department/List"));
const DepartmentAdd = lazy(() => import("./pages/Setting/Department/Add"));
const DepartmentView = lazy(() => import("./pages/Setting/Department/View"));
const DepartmentEdit = lazy(() => import("./pages/Setting/Department/Edit"));
const PositionList = lazy(() => import("./pages/Setting/Position/List"));
const PositionAdd = lazy(() => import("./pages/Setting/Position/Add"));
const PositionView = lazy(() => import("./pages/Setting/Position/View"));
const PositionEdit = lazy(() => import("./pages/Setting/Position/Edit"));
const RolePermissionList = lazy(
  () => import("./pages/Setting/RolePermission/List")
);
const RolePermissionAdd = lazy(
  () => import("./pages/Setting/RolePermission/Add")
);
const RolePermissionView = lazy(
  () => import("./pages/Setting/RolePermission/View")
);
const RolePermissionEdit = lazy(
  () => import("./pages/Setting/RolePermission/Edit")
);
const UserManagementList = lazy(
  () => import("./pages/Setting/UserManagement/List")
);
const UserManagementAdd = lazy(
  () => import("./pages/Setting/UserManagement/Add")
);
const UserManagementView = lazy(
  () => import("./pages/Setting/UserManagement/View")
);
const UserManagementEdit = lazy(
  () => import("./pages/Setting/UserManagement/Edit")
);
const ActionLogsList = lazy(() => import("./pages/Setting/ActionLogs/List"));

ProgressBar.config({
  barColors: {
    "0": "#4774c2",
    "1.0": "#4774c2",
  },
});

const Routes = () => {
  const dispatch = useDispatch();
  const [load, setLoad] = useState<boolean>(false);
  const jwt = getCookie(process.env.REACT_APP_JWT_COOKIE_NAME);
  const userInfo = useSelector((state: stateInterface) => state.user.userInfo);

  useEffect(() => {
    if (jwt) {
      initData();
    }
  }, []);

  const initData = async () => {
    const res = await apiGetUserMe();
    if (res.status && res.code) {
      const permissions = res?.message?.data?.data?.role?.permissions ?? [];
      const body = {
        _id: res?.message?.data?.data?._id ?? "",
        company_id: res?.message?.data?.data?.company_id ?? "",
        company_name:
          res?.message?.data?.data?.profile?.company_id?.company_name_en ?? "",
        company_code_name:
          (res?.message?.data?.data?.profile?.company_id?.company_code ?? "") +
          " : " +
          (res?.message?.data?.data?.profile?.company_id?.company_name_en ??
            "") +
          " : " +
          (res?.message?.data?.data?.profile?.company_id?.company_name_th ??
            ""),
        user_picture: res?.message?.data?.data?.profile.user_picture?._id ?? "",
        user_login_name: res?.message?.data?.data?.user_login_name ?? "",
        user_login_email: res?.message?.data?.data?.user_login_email ?? "",
        user_role_id: res?.message?.data?.data?.user_role_id ?? "",
        user_login_date: res?.message?.data?.data?.user_login_date ?? "",
        user_firstname: res?.message?.data?.data?.user_firstname ?? "",
        user_lastname: res?.message?.data?.data?.user_lastname ?? "",
        profile: {
          _id: res?.message?.data?.data?.profile?._id ?? "",
          user_title: res?.message?.data?.data?.profile?.user_title ?? "",
          user_name_th: res?.message?.data?.data?.profile?.user_name_th ?? "",
          user_lastname_th:
            res?.message?.data?.data?.profile?.user_lastname_th ?? "",
          user_work_date:
            res?.message?.data?.data?.profile?.user_work_date ?? "",
          user_login_id: res?.message?.data?.data?.profile?.user_login_id ?? "",
          position_id: res?.message?.data?.data?.profile?.position_id ?? "",
          department_id: res?.message?.data?.data?.profile?.department_id ?? "",
          division_id: res?.message?.data?.data?.profile?.division_id ?? "",
          branch_id: res?.message?.data?.data?.profile?.branch_id ?? "",
          company_id: res?.message?.data?.data?.profile?.company_id?._id ?? "",
        },
        role: {
          _id: res?.message?.data?.data?.role?._id ?? "",
          user_role_name: res?.message?.data?.data?.role?.user_role_name ?? "",
          permissions: permissions.map((e: permissionsInterface) => {
            return {
              scopes: e?.scopes ?? {
                create: false,
                read: false,
                update: false,
                delete: false,
                export: false,
              },
              _id: e?._id ?? "",
              module_name: e?.module_name ?? "",
              user_role_id: e?.user_role_id ?? "",
            };
          }),
        },
        isImpliepack: res?.message?.data?.data?.isImpliepack ?? false,
      };
      dispatch(userInfoAction({ ...body }));
      setLoad(true);
    }
  };

  const apiGetUserMe = async () => {
    return await Get(process.env.REACT_APP_API_SERVER_HOST + "/users/me");
  };

  const PrivateRoute = ({
    permissions,
    children,
    ...rest
  }: permissionsScopeInterface & RouteProps) => {
    const permissionPass =
      permissions.module_name === ""
        ? true
        : permissionWithScope({
            permissions,
            userPermission: userInfo.role.permissions,
          });
    return (
      <Route
        {...rest}
        render={() => {
          return jwt ? (
            load ? (
              permissionPass ? (
                children
              ) : (
                <Redirect to={"/"} />
              )
            ) : (
              <></>
            )
          ) : (
            <Redirect to={"/login"} />
          );
        }}
      />
    );
  };

  const PrivateRouteNotAuth = ({ children, ...rest }: RouteProps) => {
    return (
      <Route
        {...rest}
        render={() => {
          return !jwt ? children : <Redirect to="/" />;
        }}
      />
    );
  };

  const PublicRoute = ({ children, ...rest }: RouteProps) => {
    return <Route {...rest} render={() => children} />;
  };

  return (
    <Router>
      <Suspense fallback={<ProgressBar />}>
        <Switch>
          {/* layout default */}
          <Route
            exact
            path={[
              "/",
              "/main/contract",
              "/main/contract/detail/:id",
              "/main/contract/edit/:id",
              "/main/contract/add",
              "/main/contract/history/:id",
              "/main/order",
              "/main/order/detail/:id",
              "/main/order/edit/:id",
              "/main/order/add",
              "/main/order/history/:id",
              "/main/quotation",
              "/main/quotation/detail/:id",
              "/main/quotation/edit/:id",
              "/main/quotation/add",
              "/main/quotation/history/:id",
              "/main/invoice",
              "/main/invoice/detail/:id",
              "/main/invoice/edit/:id",
              "/main/invoice/add",
              "/main/invoice/history/:id",
              "/main/receipt",
              "/main/receipt/detail/:id",
              "/main/receipt/edit/:id",
              "/main/receipt/add",
              "/main/receipt/history/:id",
              "/main/deliveryorder",
              "/main/deliveryorder/detail/:id",
              "/main/deliveryorder/edit/:id",
              "/main/deliveryorder/add",
              "/main/deliveryorder/detail/:id/deliverynote/:id2",
              "/main/receivedorder",
              "/main/receivedorder/detail/:id/:id2",
              "/main/receivedorder/detail/:id",
              "/main/receivedorder/edit/:id",
              "/main/receivedorder/add",
              "/main/stockinventory",
              "/main/stockinventory/detail/:id",
              "/main/stockinventory/edit/:id",
              "/main/stockinventory/manage/:id",
              "/main/stockinventory/add",
              "/main/stockinventory/customer/add",
              "/main/stockinventory/withdraw/add",
              "/main/stockinventory/received/add",
              "/main/stockinventory/history/:id",
              "/main/stockinventory/customer",
              "/main/stockinventory/customer/detail/:id",
              "/main/stockinventory/customer/manage/:id",
              "/main/stockinventory/customer/history/:id",
              "/main/claim",
              "/main/claim/detail/:id",
              "/main/claim/edit/:id",
              "/main/claim/add",
              "/master/warehouse",
              "/master/warehouse/detail/:id",
              "/master/warehouse/edit/:id",
              "/master/warehouse/add",
              "/master/customer/company",
              "/master/customer/company/detail/:id",
              "/master/customer/company/edit/:id",
              "/master/customer/company/add",
              "/master/customer/branch",
              "/master/customer/branch/detail/:id",
              "/master/customer/branch/edit/:id",
              "/master/customer/branch/add",
              "/master/customer/shippingaddress",
              "/master/customer/shippingaddress/detail/:id",
              "/master/customer/shippingaddress/edit/:id",
              "/master/customer/shippingaddress/add",
              "/master/product/producttype",
              "/master/product/producttype/detail/:id",
              "/master/product/producttype/edit/:id",
              "/master/product/producttype/add",
              "/master/product/product",
              "/master/product/product/detail/:id",
              "/master/product/product/edit/:id",
              "/master/product/product/add",
              "/master/product/productrate",
              "/master/product/productrate/detail/:id",
              "/master/product/productrate/edit/:id",
              "/master/product/productrate/add",
              "/settings/mycompany",
              "/settings/mycompany/edit",
              "/settings/branch",
              "/settings/branch/detail/:id",
              "/settings/branch/edit/:id",
              "/settings/branch/add",
              "/settings/division",
              "/settings/division/detail/:id",
              "/settings/division/edit/:id",
              "/settings/division/add",
              "/settings/department",
              "/settings/department/detail/:id",
              "/settings/department/edit/:id",
              "/settings/department/add",
              "/settings/position",
              "/settings/position/detail/:id",
              "/settings/position/edit/:id",
              "/settings/position/add",
              "/settings/role",
              "/settings/role/detail/:id",
              "/settings/role/edit/:id",
              "/settings/role/add",
              "/settings/usermanagement",
              "/settings/usermanagement/detail/:id",
              "/settings/usermanagement/edit/:id",
              "/settings/usermanagement/add",
              "/settings/actionlogs",
            ]}
          >
            <Layout>
              <Switch>
                <PrivateRoute
                  exact
                  path="/"
                  permissions={{ module_name: "", scope: "read" }}
                >
                  <Home />
                </PrivateRoute>

                {/* Main */}
                <PrivateRoute
                  exact
                  path="/main/contract"
                  permissions={{ module_name: "contract", scope: "read" }}
                >
                  <ContractList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/contract/add"
                  permissions={{ module_name: "contract", scope: "create" }}
                >
                  <ContractAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/contract/detail/:id"
                  permissions={{ module_name: "contract", scope: "read" }}
                >
                  <ContractView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/contract/edit/:id"
                  permissions={{ module_name: "contract", scope: "update" }}
                >
                  <ContractEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/contract/history/:id"
                  permissions={{ module_name: "contract", scope: "read" }}
                >
                  <ContractHistory />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/order"
                  permissions={{ module_name: "order", scope: "read" }}
                >
                  <OrderList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/order/add"
                  permissions={{ module_name: "order", scope: "create" }}
                >
                  <OrderAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/order/detail/:id"
                  permissions={{ module_name: "order", scope: "read" }}
                >
                  <OrderView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/order/edit/:id"
                  permissions={{ module_name: "order", scope: "update" }}
                >
                  <OrderEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/order/history/:id"
                  permissions={{ module_name: "order", scope: "read" }}
                >
                  <OrderHistory />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/quotation"
                  permissions={{ module_name: "quotation", scope: "read" }}
                >
                  <QuotationList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/quotation/add"
                  permissions={{ module_name: "quotation", scope: "create" }}
                >
                  <QuotationAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/quotation/detail/:id"
                  permissions={{ module_name: "quotation", scope: "read" }}
                >
                  <QuotationView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/quotation/edit/:id"
                  permissions={{ module_name: "quotation", scope: "update" }}
                >
                  <QuotationEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/quotation/history/:id"
                  permissions={{ module_name: "quotation", scope: "read" }}
                >
                  <QuotationHistory />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/invoice"
                  permissions={{ module_name: "invoice", scope: "read" }}
                >
                  <InvoiceList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/invoice/add"
                  permissions={{ module_name: "invoice", scope: "create" }}
                >
                  <InvoiceAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/invoice/detail/:id"
                  permissions={{ module_name: "invoice", scope: "read" }}
                >
                  <InvoiceView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/invoice/edit/:id"
                  permissions={{ module_name: "invoice", scope: "update" }}
                >
                  <InvoiceEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/invoice/history/:id"
                  permissions={{ module_name: "invoice", scope: "read" }}
                >
                  <InvoiceHistory />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/receipt"
                  permissions={{ module_name: "receipt", scope: "read" }}
                >
                  <ReceiptList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/receipt/add"
                  permissions={{ module_name: "receipt", scope: "create" }}
                >
                  <ReceiptAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/receipt/detail/:id"
                  permissions={{ module_name: "receipt", scope: "read" }}
                >
                  <ReceiptView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/receipt/edit/:id"
                  permissions={{ module_name: "receipt", scope: "update" }}
                >
                  <ReceiptEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/receipt/history/:id"
                  permissions={{ module_name: "receipt", scope: "read" }}
                >
                  <ReceiptHistory />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/deliveryorder"
                  permissions={{ module_name: "delivery-order", scope: "read" }}
                >
                  <DeliveryOrderList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/deliveryorder/add"
                  permissions={{
                    module_name: "delivery-order",
                    scope: "create",
                  }}
                >
                  <DeliveryOrderAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/deliveryorder/detail/:id"
                  permissions={{ module_name: "delivery-order", scope: "read" }}
                >
                  <DeliveryOrderView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/deliveryorder/edit/:id"
                  permissions={{
                    module_name: "delivery-order",
                    scope: "update",
                  }}
                >
                  <DeliveryOrderEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/deliveryorder/detail/:id/deliverynote/:id2"
                  permissions={{
                    module_name: "delivery-order",
                    scope: "read",
                  }}
                >
                  <DeliveryOrderNote />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/receivedorder"
                  permissions={{ module_name: "receive-order", scope: "read" }}
                >
                  <ReceivedOrderList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/receivedorder/add"
                  permissions={{
                    module_name: "receive-order",
                    scope: "create",
                  }}
                >
                  <ReceivedOrderAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/receivedorder/detail/:id/:id2"
                  permissions={{ module_name: "receive-order", scope: "read" }}
                >
                  <ReceivedOrderView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/receivedorder/detail/:id"
                  permissions={{ module_name: "receive-order", scope: "read" }}
                >
                  <ReceivedOrderView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/receivedorder/edit/:id"
                  permissions={{
                    module_name: "receive-order",
                    scope: "update",
                  }}
                >
                  <ReceivedOrderEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/stockinventory"
                  permissions={{
                    module_name: "stock-inventory",
                    scope: "read",
                  }}
                >
                  <StockInventoryList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/stockinventory/add"
                  permissions={{
                    module_name: "stock-inventory",
                    scope: "create",
                  }}
                >
                  <StockInventoryAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/stockinventory/detail/:id"
                  permissions={{
                    module_name: "stock-inventory",
                    scope: "read",
                  }}
                >
                  <StockInventoryView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/stockinventory/edit/:id"
                  permissions={{
                    module_name: "stock-inventory",
                    scope: "update",
                  }}
                >
                  <StockInventoryEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/stockinventory/manage/:id"
                  permissions={{
                    module_name: "stock-inventory",
                    scope: "update",
                  }}
                >
                  <StockInventoryManage />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/stockinventory/history/:id"
                  permissions={{
                    module_name: "stock-inventory",
                    scope: "read",
                  }}
                >
                  <StockInventoryHistory />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/stockinventory/withdraw/add"
                  permissions={{
                    module_name: "stock-inventory",
                    scope: "create",
                  }}
                >
                  <StockInventoryAddWithdraw />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/stockinventory/received/add"
                  permissions={{
                    module_name: "stock-inventory",
                    scope: "create",
                  }}
                >
                  <StockInventoryAddReceived />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/stockinventory/customer"
                  permissions={{
                    module_name: "stock-inventory",
                    scope: "read",
                  }}
                >
                  <StockInventoryListCustomer />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/stockinventory/customer/add"
                  permissions={{
                    module_name: "stock-inventory",
                    scope: "create",
                  }}
                >
                  <StockInventoryAddCustomer />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/stockinventory/customer/detail/:id"
                  permissions={{
                    module_name: "stock-inventory",
                    scope: "read",
                  }}
                >
                  <StockInventoryViewCustomer />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/stockinventory/customer/manage/:id"
                  permissions={{
                    module_name: "stock-inventory",
                    scope: "update",
                  }}
                >
                  <StockInventoryManageCustomer />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/stockinventory/customer/history/:id"
                  permissions={{
                    module_name: "stock-inventory",
                    scope: "update",
                  }}
                >
                  <StockInventoryHistoryCustomer />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/claim"
                  permissions={{ module_name: "claim", scope: "read" }}
                >
                  <ClaimList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/claim/add"
                  permissions={{ module_name: "claim", scope: "create" }}
                >
                  <ClaimAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/claim/detail/:id"
                  permissions={{ module_name: "claim", scope: "read" }}
                >
                  <ClaimView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/main/claim/edit/:id"
                  permissions={{ module_name: "claim", scope: "update" }}
                >
                  <ClaimEdit />
                </PrivateRoute>

                {/* Master Data */}
                <PrivateRoute
                  exact
                  path="/master/warehouse"
                  permissions={{ module_name: "warehouse", scope: "read" }}
                >
                  <WarehouseList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/warehouse/add"
                  permissions={{ module_name: "warehouse", scope: "create" }}
                >
                  <WarehouseAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/warehouse/detail/:id"
                  permissions={{ module_name: "warehouse", scope: "read" }}
                >
                  <WarehouseView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/warehouse/edit/:id"
                  permissions={{ module_name: "warehouse", scope: "update" }}
                >
                  <WarehouseEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/customer/company"
                  permissions={{
                    module_name: "customer-company",
                    scope: "read",
                  }}
                >
                  <CompanyList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/customer/company/add"
                  permissions={{
                    module_name: "customer-company",
                    scope: "create",
                  }}
                >
                  <CompanyAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/customer/company/detail/:id"
                  permissions={{
                    module_name: "customer-company",
                    scope: "read",
                  }}
                >
                  <CompanyView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/customer/company/edit/:id"
                  permissions={{
                    module_name: "customer-company",
                    scope: "update",
                  }}
                >
                  <CompanyEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/customer/branch"
                  permissions={{
                    module_name: "customer-branch",
                    scope: "read",
                  }}
                >
                  <CustomerBranchList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/customer/branch/add"
                  permissions={{
                    module_name: "customer-branch",
                    scope: "create",
                  }}
                >
                  <CustomerBranchAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/customer/branch/detail/:id"
                  permissions={{
                    module_name: "customer-branch",
                    scope: "read",
                  }}
                >
                  <CustomerBranchView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/customer/branch/edit/:id"
                  permissions={{
                    module_name: "customer-branch",
                    scope: "update",
                  }}
                >
                  <CustomerBranchEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/customer/shippingaddress"
                  permissions={{
                    module_name: "customer-shipping-address",
                    scope: "read",
                  }}
                >
                  <ShippingAddressList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/customer/shippingaddress/add"
                  permissions={{
                    module_name: "customer-shipping-address",
                    scope: "create",
                  }}
                >
                  <ShippingAddressAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/customer/shippingaddress/detail/:id"
                  permissions={{
                    module_name: "customer-shipping-address",
                    scope: "read",
                  }}
                >
                  <ShippingAddressView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/customer/shippingaddress/edit/:id"
                  permissions={{
                    module_name: "customer-shipping-address",
                    scope: "update",
                  }}
                >
                  <ShippingAddressEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/product/producttype"
                  permissions={{ module_name: "product-type", scope: "read" }}
                >
                  <ProductTypeList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/product/producttype/add"
                  permissions={{ module_name: "product-type", scope: "create" }}
                >
                  <ProductTypeAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/product/producttype/detail/:id"
                  permissions={{ module_name: "product-type", scope: "read" }}
                >
                  <ProductTypeView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/product/producttype/edit/:id"
                  permissions={{ module_name: "product-type", scope: "update" }}
                >
                  <ProductTypeEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/product/product"
                  permissions={{ module_name: "product", scope: "read" }}
                >
                  <ProductList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/product/product/add"
                  permissions={{ module_name: "product", scope: "create" }}
                >
                  <ProductAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/product/product/detail/:id"
                  permissions={{ module_name: "product", scope: "read" }}
                >
                  <ProductView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/product/product/edit/:id"
                  permissions={{ module_name: "product", scope: "update" }}
                >
                  <ProductEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/product/productrate"
                  permissions={{ module_name: "product-rate", scope: "read" }}
                >
                  <ProductRateList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/product/productrate/add"
                  permissions={{ module_name: "product-rate", scope: "create" }}
                >
                  <ProductRateAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/product/productrate/detail/:id"
                  permissions={{ module_name: "product-rate", scope: "read" }}
                >
                  <ProductRateView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/master/product/productrate/edit/:id"
                  permissions={{ module_name: "product-rate", scope: "update" }}
                >
                  <ProductRateEdit />
                </PrivateRoute>

                {/* settings */}
                <PrivateRoute
                  exact
                  path="/settings/mycompany"
                  permissions={{
                    module_name: "company-profile",
                    scope: "read",
                  }}
                >
                  <Mycompany />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/mycompany/edit"
                  permissions={{
                    module_name: "company-profile",
                    scope: "update",
                  }}
                >
                  <MycompanyEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/branch"
                  permissions={{ module_name: "branch", scope: "read" }}
                >
                  <BranchList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/branch/add"
                  permissions={{ module_name: "branch", scope: "create" }}
                >
                  <BranchAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/branch/detail/:id"
                  permissions={{ module_name: "branch", scope: "read" }}
                >
                  <BranchView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/branch/edit/:id"
                  permissions={{ module_name: "branch", scope: "update" }}
                >
                  <BranchEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/division"
                  permissions={{ module_name: "division", scope: "read" }}
                >
                  <DivisionList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/division/add"
                  permissions={{ module_name: "division", scope: "create" }}
                >
                  <DivisionAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/division/detail/:id"
                  permissions={{ module_name: "division", scope: "read" }}
                >
                  <DivisionView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/division/edit/:id"
                  permissions={{ module_name: "division", scope: "update" }}
                >
                  <DivisionEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/department"
                  permissions={{ module_name: "department", scope: "read" }}
                >
                  <DepartmentList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/department/add"
                  permissions={{ module_name: "department", scope: "create" }}
                >
                  <DepartmentAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/department/detail/:id"
                  permissions={{ module_name: "department", scope: "read" }}
                >
                  <DepartmentView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/department/edit/:id"
                  permissions={{ module_name: "department", scope: "update" }}
                >
                  <DepartmentEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/position"
                  permissions={{ module_name: "position", scope: "read" }}
                >
                  <PositionList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/position/add"
                  permissions={{ module_name: "position", scope: "create" }}
                >
                  <PositionAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/position/detail/:id"
                  permissions={{ module_name: "position", scope: "read" }}
                >
                  <PositionView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/position/edit/:id"
                  permissions={{ module_name: "position", scope: "update" }}
                >
                  <PositionEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/role"
                  permissions={{
                    module_name: "role-permission",
                    scope: "read",
                  }}
                >
                  <RolePermissionList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/role/add"
                  permissions={{
                    module_name: "role-permission",
                    scope: "create",
                  }}
                >
                  <RolePermissionAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/role/detail/:id"
                  permissions={{
                    module_name: "role-permission",
                    scope: "read",
                  }}
                >
                  <RolePermissionView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/role/edit/:id"
                  permissions={{
                    module_name: "role-permission",
                    scope: "update",
                  }}
                >
                  <RolePermissionEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/usermanagement"
                  permissions={{
                    module_name: "user-management",
                    scope: "read",
                  }}
                >
                  <UserManagementList />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/usermanagement/add"
                  permissions={{
                    module_name: "user-management",
                    scope: "create",
                  }}
                >
                  <UserManagementAdd />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/usermanagement/detail/:id"
                  permissions={{
                    module_name: "user-management",
                    scope: "read",
                  }}
                >
                  <UserManagementView />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/usermanagement/edit/:id"
                  permissions={{
                    module_name: "user-management",
                    scope: "update",
                  }}
                >
                  <UserManagementEdit />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/settings/actionlogs"
                  permissions={{ module_name: "action-logs", scope: "read" }}
                >
                  <ActionLogsList />
                </PrivateRoute>
              </Switch>
            </Layout>
          </Route>
          {/* layout authen */}
          <Route exact path={["/login", "/forgot", "/reset-password"]}>
            <LayoutAuth>
              <Switch>
                <PrivateRouteNotAuth exact path="/login">
                  <Login />
                </PrivateRouteNotAuth>
                <PrivateRouteNotAuth exact path="/forgot">
                  <Forgot />
                </PrivateRouteNotAuth>
                <PrivateRouteNotAuth exact path="/reset-password">
                  <CreateNewPassword />
                </PrivateRouteNotAuth>
              </Switch>
            </LayoutAuth>
          </Route>
          {/* not found route */}
          <PublicRoute>
            <Error404 />
          </PublicRoute>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
