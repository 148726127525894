import { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { ModalConfirmLogout } from "@components/Modal";
import { Delete } from "@api/api";
import { delCookie } from "@utils/Cookie";
import {
  openMenuAction,
  openProfileAction,
  loadingAction,
  modalAction,
  openLogoutAction
} from "@redux/Actions/storeAction";
import { stateInterface } from "@redux/Interface";

const AsideLogout = () => {
  const dispatch = useDispatch();
  const store = useSelector((state: stateInterface) => state.store);
  const [delayOpen, setDelayOpen] = useState<boolean>(false);
  const [delayClose, setDelayClose] = useState<boolean>(false);

  useEffect(() => {
    if (store.openLogout) {
      dispatch(openMenuAction(false));
      dispatch(openProfileAction(""));
      setDelayClose(store.openLogout);
      setTimeout(() => {
        setDelayOpen(store.openLogout);
      }, 100);
    } else {
      setDelayOpen(store.openLogout);
      setTimeout(() => {
        setDelayClose(store.openLogout);
      }, 500);
    }
  }, [store.openLogout]);

  const handleConfirmLogout = () => {
    dispatch(
      modalAction({
        open: "confirm_logout",
        title: "Do you want to log out?",
      })
    );
  };

  const handleLogout = async () => {
    dispatch(
      modalAction({
        open: "",
        title: "",
      })
    );
    dispatch(openLogoutAction(false))
    dispatch(loadingAction(true));
    const res = await apiDeleteAccesstoken();
    dispatch(loadingAction(false));
    if (res) {
      delCookie(process.env.REACT_APP_JWT_COOKIE_NAME);
      window.location.reload();
    }
  };

  const handleNoLogout = () =>{
    dispatch(modalAction({ open: "" }))
    dispatch(openLogoutAction(false))
  }

  const apiDeleteAccesstoken = async () => {
    return await Delete(
      process.env.REACT_APP_API_SERVER_HOST + "/authentications/accesstoken"
    );
  };

  return (
    <Content className={"aside" + (delayClose ? " open" : "")}>
      <div className={"aside__inner" + (delayOpen ? " open" : "")}>
        <Logout className="logout">
          <div
            className="logout__text logout__text--first"
            onClick={() => dispatch(openProfileAction("profile"))}
          >
            View Account
          </div>
          <div className="logout__text" onClick={() => handleConfirmLogout()}>
            Log Out
          </div>
        </Logout>
      </div>
      <ModalConfirmLogout
        onYes={() => handleLogout()}
        onNo={() => handleNoLogout()}
      />
    </Content>
  );
};

export default AsideLogout;

const Content = styled.div`
  position: absolute;
  right: 2%;
  width: 15%;
  height: calc(100vh - 4.5vw);
  display: none;
  &.open {
    display: block;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    top: 7.8vw;
    right: 0;
    height: calc(100vh - 7.8vw);
  }
  @media only screen and (max-width: 450px) {
    top: 16vw;
    height: calc(100vh - 16vw);
  }
  .aside {
    &__inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      -webkit-transform: translateY(-120%);
      transform: translateY(-120%);
      transition: -webkit-transform 1s cubic-bezier(0.8, 0, 0.1, 1);
      transition: transform 1s cubic-bezier(0.8, 0, 0.1, 1);
      transition: transform 1s cubic-bezier(0.8, 0, 0.1, 1),
        -webkit-transform 1s cubic-bezier(0.8, 0, 0.1, 1);
      will-change: transform;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow-y: scroll;
      &.open {
        transform: translateY(0);
      }
    }
  }
`;

const Logout = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 6%;
  background-color: #fff;
  @media only screen and (max-width: 1024px) {
    padding: 30px 20px;
  }
  @media only screen and (max-width: 450px) {
    padding: 25px 15px;
  }
  .logout {
    &__text {
      font-family: "SFProDisplay-Regular";
      font-weight: normal;
      font-size: 1vw;
      color: #434343;
      line-height: 2;
      cursor: pointer;
      &--first {
        padding: 0 0 0.5vw;
        margin: 0 0 0.5vw;
        border-bottom: solid 0.052vw #9b9b9b;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 2.5vw;
        padding: 0 0 1vw;
        margin: 0 0 1vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 4vw;
        padding: 0 0 1.5vw;
        margin: 0 0 1.5vw;
      }
    }
  }
`;
