import { userDataInterface } from "../Interface";

const initState = {
  userInfo: {
    _id: "",
    user_login_name: "",
    user_login_email: "",
    user_role_id: "",
    user_login_date: "",
    profile: {
      _id: "",
      user_name_th: "",
      user_lastname_th: "",
      user_work_date: "",
      user_login_id: "",
      position_id: "",
      department_id: "",
      division_id: "",
      branch_id: "",
      company_id: "",
    },
    role: {
      _id: "",
      user_role_name: "",
      permissions: [],
    },
    isImpliepack: false,
  },
};

const store = (
  state = initState,
  action: { type: string; data: userDataInterface }
) => {
  switch (action.type) {
    case "user_info":
      return { ...state, userInfo: action.data };
    default:
      return state;
  }
};

export default store;
