import { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { imgList } from "@constants/img";
import { AsideProfile, AsideLogout } from "@components/Aside";
import { AsideMenu } from "@components/Aside";
import { getCookie } from "@utils/Cookie";
import {
  openMenuAction,
  openProfileAction,
  openLogoutAction,
} from "@redux/Actions/storeAction";
import { stateInterface } from "@redux/Interface";

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [delayOpen, setDelayOpen] = useState<boolean>(false);
  const [delayClose, setDelayClose] = useState<boolean>(false);
  const store = useSelector((state: stateInterface) => state.store);
  const userInfo = useSelector((state: stateInterface) => state.user.userInfo);
  const [pic, setPic] = useState<string>("");

  const jwt = getCookie(process.env.REACT_APP_JWT_COOKIE_NAME);

  const user_firstname = userInfo?.user_firstname ?? "";
  const user_lastname = userInfo?.user_lastname ?? "";
  const user_picture = userInfo?.user_picture ?? "";

  useEffect(() => {
    if (user_picture) {
      handleGetImage(user_picture);
    }
  }, [user_picture]);

  useEffect(() => {
    if (jwt) {
      if (store.openMenu) {
        dispatch(openProfileAction(""));
        dispatch(openLogoutAction(false));
        setDelayClose(store.openMenu);
        setTimeout(() => {
          setDelayOpen(store.openMenu);
        }, 100);
      } else {
        setDelayOpen(store.openMenu);
        setTimeout(() => {
          setDelayClose(store.openMenu);
        }, 500);
      }
    }
  }, [store.openMenu]);

  useEffect(() => {
    if (jwt) dispatch(openMenuAction(false));
  }, [location]);

  const handleGetImage = async (logo: string) => {
    const res = await apiGetImage(logo);
    const blob = URL.createObjectURL(res.data);
    setPic(blob);
  };

  const apiGetImage = (image: string) => {
    return axios.get(
      process.env.REACT_APP_API_SERVER_HOST + "/images/assets/" + image,
      { responseType: "blob" }
    );
  };

  return (
    <Content className="header">
      <div className="header__inner">
        <div className="header__hamburger-inner">
          <div
            className={"header__hamburger" + (store.openMenu ? " open" : "")}
            onClick={() => dispatch(openMenuAction(!store.openMenu))}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <Link to="/" className="header__logo">
          <img src={imgList.logo_impliepack} alt="" />
        </Link>
        <div
          className="header__profile"
          onClick={() =>
            dispatch(
              store.openProfile
                ? openProfileAction("")
                : openLogoutAction(!store.openLogout)
            )
          }
        >
          <div className="header__profile-inner">
            <div className="header__avatar">
              <img src={pic ? pic : imgList.avatar} alt="" />
            </div>
            <div className="header__name">
              <div className="header__name-head">Profile</div>
              <div className="header__name-title">
                {user_firstname + " " + user_lastname}
              </div>
            </div>
            <img src={imgList.arrow} alt="" className="header__arrow" />
          </div>
        </div>
      </div>
      <div className={"header__menu" + (delayClose ? " open" : "")}>
        <div className={"header__menu-inner" + (delayOpen ? " open" : "")}>
          <AsideMenu />
        </div>
      </div>
      <AsideProfile />
      <AsideLogout />
    </Content>
  );
};

export default Header;

interface styleInterface {}

const Content = styled.div<styleInterface>`
  position: fixed;
  width: 100%;
  height: 4.5vw;
  z-index: 999;
  @media only screen and (max-width: 1024px) {
    height: 7.8vw;
  }
  @media only screen and (max-width: 450px) {
    height: 16vw;
  }
  .header {
    &__inner {
      position: relative;
      z-index: 100;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2.5%;
      box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
      background-color: #fff;
      @media only screen and (max-width: 1024px) {
        padding: 0 20px;
      }
      @media only screen and (max-width: 450px) {
        padding: 0 15px;
      }
    }
    &__hamburger-inner {
      display: none;
      width: 20%;
      @media only screen and (max-width: 1024px) {
        display: block;
      }
    }
    &__hamburger {
      width: 26px;
      height: 17px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      > span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #4774c2;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
        &:nth-child(1) {
          top: 0px;
          -webkit-transform-origin: left center;
          -moz-transform-origin: left center;
          -o-transform-origin: left center;
          transform-origin: left center;
        }
        &:nth-child(2) {
          top: 6px;
          -webkit-transform-origin: left center;
          -moz-transform-origin: left center;
          -o-transform-origin: left center;
          transform-origin: left center;
        }
        &:nth-child(3) {
          top: 13px;
          -webkit-transform-origin: left center;
          -moz-transform-origin: left center;
          -o-transform-origin: left center;
          transform-origin: left center;
        }
      }
      &.open {
        > span {
          &:nth-child(1) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            top: -3px;
            left: 0px;
          }
          &:nth-child(2) {
            width: 0%;
            opacity: 0;
          }
          &:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 15.5px;
            left: 0px;
          }
        }
      }
    }
    &__logo {
      display: block;
      width: auto;
      height: 45%;
      > img {
        width: auto;
        margin: 0 auto;
        height: 100%;
      }
      @media only screen and (max-width: 1024px) {
        width: 60%;
        height: 50%;
      }
    }
    &__profile {
      width: auto;
      max-width: 85%;
      height: 100%;
      @media only screen and (max-width: 1024px) {
        width: 20%;
        max-width: unset;
      }
    }
    &__profile-inner {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
    }
    &__avatar {
      position: relative;
      padding: 1.4vw;
      border-radius: 50%;
      overflow: hidden;
      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
        object-fit: cover;
      }
      @media only screen and (max-width: 1024px) {
        padding: 2.5vw;
      }
      @media only screen and (max-width: 450px) {
        padding: 4vw;
      }
    }
    &__name {
      font-family: "SFProDisplay-Medium";
      margin: 0 2vw 0 0.8vw;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    &__name-head {
      font-size: 0.9vw;
      font-weight: 500;
      color: #9b9b9b;
      margin: 0 0 -5%;
      white-space: nowrap;
    }
    &__name-title {
      font-size: 1.1vw;
      font-weight: 500;
      color: #000;
      white-space: nowrap;
    }
    &__arrow {
      width: auto;
      height: 14%;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    &__menu {
      position: absolute;
      top: 7.8vw;
      left: 0;
      width: 100%;
      height: calc(100vh - 7.8vw);
      display: none;
      @media only screen and (max-width: 1024px) {
        &.open {
          display: block;
        }
      }
      @media only screen and (max-width: 450px) {
        top: 16vw;
        height: calc(100vh - 16vw);
      }
    }
    &__menu-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      -webkit-transform: translateY(-120%);
      transform: translateY(-120%);
      transition: -webkit-transform 0.5s cubic-bezier(0.8, 0, 0.1, 1);
      transition: transform 0.5s cubic-bezier(0.8, 0, 0.1, 1);
      transition: transform 0.5s cubic-bezier(0.8, 0, 0.1, 1),
        -webkit-transform 0.5s cubic-bezier(0.8, 0, 0.1, 1);
      will-change: transform;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow-y: scroll;
      &.open {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }
    }
  }
`;
