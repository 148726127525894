import { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { imgList } from "@constants/img";
import {
  menuMainData,
  menuMasterData,
  menuSettingsData,
} from "@constants/menuData";
import { permissionWithScope } from "@utils/PermissionWithScope";
import { Get } from "@api/api";
import { delCookie } from "@utils/Cookie";
import {
  menuToggleAction,
  openProfileAction,
} from "@redux/Actions/storeAction";
import { permissionsModuleNameScopeInterface } from "@redux/Interface";
import { menuDataMainInterface } from "@components/Aside/interface";
import { stateInterface } from "@redux/Interface";

const AsideMenu = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const store = useSelector((state: stateInterface) => state.store);
  const userInfo = useSelector((state: stateInterface) => state.user.userInfo);
  const isImpliepack = userInfo.isImpliepack;
  const [key1, setKey1] = useState<string>("");
  const [key2, setKey2] = useState<string>("");
  const [key3, setKey3] = useState<string>("");

  useEffect(() => {
    dispatch(openProfileAction(""));
    setKey1(pathname.split("/")[1]);
    setKey2(pathname.split("/")[2]);
    setKey3(pathname.split("/")[3]);
  }, [location]);

  useEffect(() => {
    initData();
  }, [pathname]);

  const initData = async () => {
    const res = await apiGetUserMe();
    if (!res.status && res.code !== 200) {
      delCookie(process.env.REACT_APP_JWT_COOKIE_NAME);
      window.location.reload();
    }
  };

  const handleChangeKey2 = (e: string, type: string) => {
    if (key2 !== "" && key2 === e) {
      setKey1("");
      setKey2("");
    } else {
      setKey1(type);
      setKey2(e);
    }
  };

  const checkShowMenu = (
    permissions: permissionsModuleNameScopeInterface[]
  ) => {
    let pass = false;
    if (permissions.length > 0) {
      permissions.forEach((e) => {
        if (e.module_name === "") {
          pass = true;
        } else {
          const filter = userInfo.role.permissions.filter(
            (i) => i.module_name === e.module_name
          );
          if (filter.length > 0) {
            if (e.scope === "create") {
              if (filter[0].scopes.create === true) {
                pass = true;
              }
            } else if (e.scope === "update") {
              if (filter[0].scopes.update === true) {
                pass = true;
              }
            } else if (e.scope === "delete") {
              if (filter[0].scopes.delete === true) {
                pass = true;
              }
            } else {
              if (filter[0].scopes.read === true) {
                pass = true;
              }
            }
          }
        }
      });
    } else {
      pass = true;
    }
    return pass;
  };

  const renderMenu = (data: menuDataMainInterface[], type: string) => {
    return data.map((val, key) => {
      if (checkShowMenu(val.permissions)) {
        return (
          <Li
            key={"primary_" + key}
            className={
              "li" +
              (type === key1 && val.key === key2 ? " active" : "") +
              (data.length === key + 1 ? " last1" : "")
            }
            icon={val.icon}
            icon_active={val.icon_active}
            arrow={imgList.arrow_gray}
            arrow_active={imgList.arrow_blue}
            onClick={() => handleChangeKey2(val.key, type)}
          >
            {val.submenu.length > 0 ? (
              <a
                href="#"
                className={
                  "li__a" + (store.menuToggleHide ? " toggle-hide" : "")
                }
                onClick={(e) => e.preventDefault()}
              >
                <div className="li__icon"></div>
                <div className="li__name">{val.name}</div>
                <div className="li__arrow"></div>
              </a>
            ) : val.key === "userprofile" ? (
              <a
                href="javascript:void(0)"
                className={
                  "li__a" + (store.menuToggleHide ? " toggle-hide" : "")
                }
                onClick={() => dispatch(openProfileAction("profile"))}
              >
                <div className="li__icon"></div>
                <div className="li__name">{val.name}</div>
              </a>
            ) : (
              <Link
                to={
                  val.key === "stockinventory" && !isImpliepack
                    ? `/${type}/${val.key}/customer`
                    : `/${type}/${val.key}`
                }
                className={
                  "li__a" + (store.menuToggleHide ? " toggle-hide" : "")
                }
              >
                <div className="li__icon"></div>
                <div className="li__name">{val.name}</div>
              </Link>
            )}
            <SubDropdown
              className={"sub" + (val.key === key2 ? " active" : "")}
            >
              {val.submenu.map((val_2, key_2) => {
                if (
                  (val_2.permissions.module_name !== "" &&
                    permissionWithScope({
                      permissions: val_2.permissions,
                      userPermission: userInfo.role.permissions,
                    })) ||
                  val_2.permissions.module_name === ""
                )
                  return (
                    <li
                      key={"sub_primary_" + key + "_" + key_2}
                      className={
                        "sub__li" +
                        (type === key1 && val.key === key2 && val_2.key === key3
                          ? " active"
                          : "") +
                        (val.submenu.length === key_2 + 1
                          ? " sub__li--last"
                          : "")
                      }
                    >
                      <Link
                        to={`/${type}/${val.key}/${val_2.key}`}
                        className={
                          "sub__a" +
                          (store.menuToggleHide ? " toggle-hide" : "")
                        }
                      >
                        <div className="sub__dot"></div>
                        <div className="sub__name">{val_2.name}</div>
                      </Link>
                    </li>
                  );
              })}
            </SubDropdown>
          </Li>
        );
      }
    });
  };

  const apiGetUserMe = async () => {
    return await Get(process.env.REACT_APP_API_SERVER_HOST + "/users/me");
  };

  return (
    <Content className="menu">
      <div
        className={"menu__slide" + (store.menuToggleHide ? " toggle-hide" : "")}
        onClick={() => dispatch(menuToggleAction(!store.menuToggleHide))}
      >
        <img src={imgList.arrow_white} alt="" />
      </div>
      <Section className="sec">
        <div
          className={
            "sec__header" + (store.menuToggleHide ? " toggle-hide" : "")
          }
        >
          Main<span> Navigation</span>
        </div>
        <Ul>{renderMenu(menuMainData, "main")}</Ul>
      </Section>
      <Section className="sec">
        <div
          className={
            "sec__header" + (store.menuToggleHide ? " toggle-hide" : "")
          }
        >
          Master<span> Data</span>
        </div>
        <Ul>{renderMenu(menuMasterData, "master")}</Ul>
      </Section>
      <Section className="sec">
        <div
          className={
            "sec__header" + (store.menuToggleHide ? " toggle-hide" : "")
          }
        >
          Settings
        </div>
        <Ul>{renderMenu(menuSettingsData, "settings")}</Ul>
      </Section>
    </Content>
  );
};

export default AsideMenu;

interface styleInterface {
  icon: string;
  icon_active: string;
  arrow: string;
  arrow_active: string;
}

const SubDropdown = styled.ul`
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 0px;
  transition: max-height 0.5s ease-out 0s;
  overflow: hidden;
  &.active {
    max-height: 1000px;
    transition: max-height 0.5s ease-in 0s;
  }
  .sub {
    &__li {
      width: 100%;
      border-bottom: solid 0.026vw #ebebeb;
      &--last {
        border-bottom: none;
      }
      &.active {
        & .sub__a {
          color: #4774c2;
        }
        & .sub__dot {
          background-color: #4774c2;
        }
      }
      @media only screen and (max-width: 1024px) {
        border-bottom: solid 0.049vw #ebebeb;
      }
      @media only screen and (max-width: 450px) {
        border-bottom: solid 0.111vw #ebebeb;
      }
    }
    &__a {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      font-family: "SFProDisplay-Medium";
      font-weight: 500;
      font-size: 1.1vw;
      line-height: 3.5;
      color: #9b9b9b;
      transition: 0.3s all ease;
      padding: 0 8% 0 25%;
      > div {
        white-space: nowrap;
      }
      &--active {
        color: #ffffff;
      }
      &.toggle-hide {
        padding: 0;
        & .sub__dot {
          margin: 0 auto;
        }
        & .sub__name {
          width: 0;
          opacity: 0;
        }
      }
      &:hover {
        color: #4774c2;
        background-color: rgba(71, 116, 194, 0.2);
        & .sub__dot {
          background-color: #4774c2;
        }
      }
      @media only screen and (max-width: 1024px) {
        font-size: 3vw;
        padding: 0 calc(20px + 6.8%);
        line-height: 3;
        &.toggle-hide {
          padding: 0 calc(20px + 6.8%);
          & .sub__dot {
            margin: 0 2% 0 0;
          }
          & .sub__name {
            width: auto;
            opacity: 1;
          }
        }
      }
      @media only screen and (max-width: 450px) {
        font-size: 4.5vw;
        padding: 0 calc(15px + 12%);
        &.toggle-hide {
          padding: 0 calc(15px + 12%);
          & .sub__dot {
            margin: 0 3% 0 0;
          }
          & .sub__name {
            width: auto;
            opacity: 1;
          }
        }
      }
    }
    &__dot {
      width: 0.5vw;
      padding-top: 0.5vw;
      border-radius: 50%;
      margin: 0 6% 0 0;
      background-color: #9b9b9b;
      transition: 0.3s all ease;
      @media only screen and (max-width: 1024px) {
        width: 1.5vw;
        padding-top: 1.5vw;
        margin: 0 2% 0 0;
      }
      @media only screen and (max-width: 450px) {
        width: 2vw;
        padding-top: 2vw;
        margin: 0 3% 0 0;
      }
    }
  }
`;
const Content = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 4.5vw);
  padding: 2.5vw 0;
  background-color: #fff;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    display: none;
  }
  @media only screen and (max-width: 1024px) {
    height: 100%;
    padding: 8% 0;
  }
  @media only screen and (max-width: 450px) {
    height: 100%;
    padding: 10% 0;
  }
  .menu {
    &__slide {
      position: absolute;
      width: 1.7vw;
      padding-top: calc(1.7vw * (60 / 32));
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      background-color: #4774c2;
      cursor: pointer;
      border-radius: 0.4vw 0 0 0.4vw;
      z-index: 100;
      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
        width: 74%;
        transition: 0.3s all ease;
      }
      &.toggle-hide {
        > img {
          transform: translate(-50%, -50%) rotate(-90deg);
        }
      }
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
  }
`;
const Section = styled.div`
  position: relative;
  width: 100%;
  .sec {
    &__header {
      font-family: "SFProDisplay-Medium";
      font-weight: 500;
      font-size: 1.1vw;
      line-height: 2.1;
      background-color: #f7f7f7;
      padding: 0 0 0 12%;
      &.toggle-hide {
        padding: 0;
        text-align: center;
        > span {
          display: none;
        }
      }
      @media only screen and (max-width: 1024px) {
        font-size: 3vw;
        padding: 0 20px;
        &.toggle-hide {
          padding: 0 20px;
          text-align: left;
          > span {
            display: initial;
          }
        }
      }
      @media only screen and (max-width: 450px) {
        font-size: 4.5vw;
        padding: 0 15px;
        &.toggle-hide {
          padding: 0 15px;
        }
      }
    }
  }
`;
const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
const Li = styled.li<styleInterface>`
  width: 100%;
  border-bottom: solid 0.026vw #ebebeb;
  transition: 0.3s all ease;
  &.last {
    border-bottom: none;
  }
  &.active {
    & .li__a {
      color: #4774c2;
      background-color: rgba(71, 116, 194, 0.2);
    }
    & .li__icon {
      background: url(${(props: styleInterface) => props.icon_active}) no-repeat
        center;
      background-size: cover;
    }
    & .li__arrow {
      background: url(${(props: styleInterface) => props.arrow_active})
        no-repeat center;
      background-size: cover;
      transform: translate(0, -50%) rotate(-180deg);
    }
  }
  @media only screen and (max-width: 1024px) {
    border-bottom: solid 0.049vw #ebebeb;
  }
  @media only screen and (max-width: 450px) {
    border-bottom: solid 0.111vw #ebebeb;
  }
  .li {
    &__a {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      font-family: "SFProDisplay-Medium";
      font-weight: 500;
      font-size: 1.1vw;
      line-height: 3.5;
      color: #9b9b9b;
      transition: 0.3s all ease;
      padding: 0 8% 0 12%;
      > div {
        white-space: nowrap;
      }
      &.toggle-hide {
        padding: 0;
        & .li__icon {
          margin: 0 auto;
        }
        & .li__name {
          width: 0;
          opacity: 0;
        }
        & .li__arrow {
          width: 0;
          opacity: 0;
        }
      }
      &:hover {
        color: #4774c2;
        background-color: rgba(71, 116, 194, 0.2);
        & .li__icon {
          background: url(${(props: styleInterface) => props.icon_active})
            no-repeat center;
          background-size: cover;
        }
        & .li__arrow {
          background: url(${(props: styleInterface) => props.arrow_active})
            no-repeat center;
          background-size: cover;
        }
      }
      @media only screen and (max-width: 1024px) {
        font-size: 3vw;
        padding: 0 20px;
        line-height: 3;
        &.toggle-hide {
          padding: 0 20px;
          & .li__icon {
            margin: 0 2% 0 0;
          }
          & .li__name {
            width: auto;
            opacity: 1;
          }
          & .li__arrow {
            width: auto;
            opacity: 1;
          }
        }
      }
      @media only screen and (max-width: 450px) {
        font-size: 4.5vw;
        padding: 0 15px;
        > img {
          width: 8.8%;
          margin: 0 6% 0 0;
        }
        &.toggle-hide {
          padding: 0 20px;
          & .li__icon {
            margin: 0 2% 0 0;
          }
        }
      }
    }
    &__icon {
      position: relative;
      width: 1.6vw;
      padding-top: 1.6vw;
      margin: 0 6% 0 0;
      z-index: 10;
      background: url(${(props: styleInterface) => props.icon}) no-repeat center;
      background-size: cover;
      transition: 0.3s all ease;
      @media only screen and (max-width: 1024px) {
        width: 4.8%;
        padding-top: 4.8%;
        margin: 0 2% 0 0;
      }
      @media only screen and (max-width: 450px) {
        width: 8.5%;
        padding-top: 8.5%;
        margin: 0 4% 0 0;
      }
    }
    &__arrow {
      position: absolute;
      top: 50%;
      right: 8%;
      transform: translate(0, -50%);
      width: 5.5%;
      padding-top: calc(5.5% * (35 / 60));
      background: url(${(props: styleInterface) => props.arrow}) no-repeat
        center;
      background-size: cover;
      transition: 0.3s all ease;
      @media only screen and (max-width: 1024px) {
        width: 2.5%;
        right: 20px;
        padding-top: calc(2.5% * (35 / 60));
      }
      @media only screen and (max-width: 450px) {
        width: 4%;
        right: 15px;
        padding-top: calc(4% * (35 / 60));
      }
    }
  }
`;
