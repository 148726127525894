import { storeStorageDataInterface } from "../Interface";

const initState = {
  lang118n: "th",
};

const storeStorage = (
  state = initState,
  action: { type: string; data: storeStorageDataInterface }
) => {
  switch (action.type) {
    case "118n":
      return { ...state, lang118n: action.data };
    default:
      return state;
  }
};

export default storeStorage;
