import styled from "styled-components";
import { Modal } from "antd";
import { useSelector } from "react-redux";

import { modalConfirmInterface } from "./interface";
import { stateInterface } from "@redux/Interface";

const ModalConfirm = ({
  onYes = () => {},
  onNo = () => {},
}: modalConfirmInterface) => {
  const modal = useSelector((state: stateInterface) => state.store.modal);
  const handleYes = () => {
    onYes();
  };

  const handleNo = () => {
    onNo();
  };

  return (
    <Content className="modal" id="modal-confirm-content">
      <Modal
        centered
        visible={modal.open === "confirm"}
        footer={null}
        closable={false}
        getContainer={() =>
          document.getElementById("modal-confirm-content") as HTMLElement
        }
        onCancel={() => handleNo()}
      >
        <div
          className="modal__text"
          dangerouslySetInnerHTML={{ __html: modal.title ?? "" }}
        />
        <div className="modal__btn-group">
          <div className="modal__btn modal__btn--no" onClick={() => handleNo()}>
            {modal.type === "savechange"
              ? `Cancel`
              : modal.type === "cancel"
              ? `No`
              : `Cancel`}
          </div>
          <div
            className="modal__btn modal__btn--yes"
            onClick={() => handleYes()}
          >
            {modal.type === "savechange"
              ? `Save`
              : modal.type === "create"
              ? `Create`
              : modal.type === "cancel"
              ? `Yes`
              : `Confirm`}
          </div>
        </div>
      </Modal>
    </Content>
  );
};

export default ModalConfirm;

const Content = styled.div`
  padding: 0 1.458vw;
  & .ant-modal {
    width: 24.5vw !important;
    max-width: 100%;
  }
  & .ant-modal-content {
    border-radius: 15px;
  }
  & .ant-modal-body {
    padding: 2vw 1.458vw;
  }
  @media only screen and (max-width: 1024px) {
    & .ant-modal {
      width: 48vw !important;
    }
    & .ant-modal-body {
      padding: 3vw 2.734vw;
    }
  }
  @media only screen and (max-width: 450px) {
    & .ant-modal {
      width: 82vw !important;
    }
    & .ant-modal-body {
      padding: 6.5vw 6.222vw;
    }
  }
  .modal {
    &__text {
      font-family: "SFProDisplay-Medium";
      font-weight: 500;
      font-size: 1.2vw;
      text-align: center;
      color: #434343;
      @media only screen and (max-width: 1024px) {
        font-size: 2.8vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 4.5vw;
      }
    }
    &__btn-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__btn {
      font-family: "SFProDisplay-Medium";
      font-weight: 500;
      font-size: 1.2vw;
      width: 10vw;
      padding: 0.6vw 0;
      margin: 5% 0 0;
      border-radius: 10px;
      text-align: center;
      cursor: pointer;
      &--yes {
        color: #fff;
        border: solid 1px #4774c2;
        background-color: #4774c2;
      }
      &--no {
        color: #ff1f1f;
        border: solid 1px #ff2424;
        background-color: #fff;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 2.8vw;
        width: 20vw;
        padding: 1vw 0;
      }
      @media only screen and (max-width: 450px) {
        font-size: 4.5vw;
        width: 32vw;
        padding: 1.5vw 0;
      }
    }
  }
`;
