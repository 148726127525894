import styled from "styled-components";

const InputCodeNone = () => {
  return (
    <Content>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </Content>
  );
};

export default InputCodeNone;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 3.03vw;
  background: #e8e8e8;
  border-radius: 0.521vw;
  display: flex;
  align-items: center;
  padding: 1vw;
  > div {
    width: 20px;
    padding-top: 20px;
    border-radius: 50%;
    background: #434343;
    margin: 0px 10px 0 0;
  }
  @media only screen and (max-width: 1920px) {
    > div {
      width: 12px;
      padding-top: 12px;
      margin: 0px 5px 0 0;
    }
  }
  @media only screen and (max-width: 1024px) {
    border-radius: 1vw;
    height: 6.4vw;
    padding: 0 1.5vw;
  }
  @media only screen and (max-width: 450px) {
    height: 10.4vw;
    padding: 0 2.5vw;
    > div {
      width: 10px;
      padding-top: 10px;
    }
  }
`;
