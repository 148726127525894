import styled from "styled-components";
import { Modal } from "antd";
import { useSelector } from "react-redux";

import { DocumentOrder } from "@components/Document";
import { modalConfirmInterface } from "./interface";
import { stateInterface } from "@redux/Interface";

const ModalConfirmOrder = ({
  onYes = () => {},
  onNo = () => {},
}: modalConfirmInterface) => {
  const modal = useSelector((state: stateInterface) => state.store.modal);
  const handleYes = () => {
    onYes();
  };

  const handleNo = () => {
    onNo();
  };

  return (
    <Content className="modal" id="modal-confirm-order-content">
      <Modal
        centered
        visible={modal.open === "confirmorder"}
        footer={null}
        closable={false}
        getContainer={() =>
          document.getElementById("modal-confirm-order-content") as HTMLElement
        }
        onCancel={() => handleNo()}
      >
        <div className="modal__order">
          <div className="modal__text">Order Preview</div>
          <DocumentOrder />
          <div className="modal__btn-group">
            <div
              className="modal__btn modal__btn--no"
              onClick={() => handleNo()}
            >
              Cancel
            </div>
            <div
              className="modal__btn modal__btn--yes"
              onClick={() => handleYes()}
            >
              Confirm
            </div>
          </div>
        </div>
      </Modal>
    </Content>
  );
};

export default ModalConfirmOrder;

const Content = styled.div`
  padding: 0 1.458vw;
  & .ant-modal {
    width: 70% !important;
    max-width: 100%;
  }
  & .ant-modal-content {
    border-radius: 15px;
    background-color: transparent;
  }
  & .ant-modal-body {
    padding: 0;
  }
  @media only screen and (max-width: 1024px) {
    & .ant-modal {
      width: 95% !important;
    }
    & .ant-modal-body {
      padding: 0;
    }
  }
  @media only screen and (max-width: 450px) {
    & .ant-modal-body {
      padding: 0;
    }
  }
  .modal {
    &__order {
      width: 100%;
      margin: 0 auto;
    }
    &__text {
      font-family: "SFProDisplay-Medium";
      font-weight: 500;
      font-size: 2.778vw;
      color: #fff;
      margin: 3% 0;
      @media only screen and (max-width: 1024px) {
        font-size: 3.906vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 8.889vw;
      }
    }
    &__btn-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__btn {
      font-family: "SFProDisplay-Medium";
      font-weight: 500;
      font-size: 1.2vw;
      width: 14vw;
      padding: 0.6vw 0;
      margin: 5% 1% 0;
      border-radius: 10px;
      text-align: center;
      cursor: pointer;
      &--yes {
        color: #fff;
        border: solid 1px #4774c2;
        background-color: #4774c2;
      }
      &--no {
        color: #ff1f1f;
        border: solid 1px #ff2424;
        background-color: #fff;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 2.8vw;
        width: 20vw;
        padding: 1vw 0;
      }
      @media only screen and (max-width: 450px) {
        font-size: 4.5vw;
        width: 32vw;
        padding: 1.5vw 0;
      }
    }
  }
`;
