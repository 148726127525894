import { companyDataInterface } from "../Interface";

const initState: companyDataInterface = {
  companyProfileData: {
    logo: {
      value: "",
      error: "",
    },
    companyCode: {
      value: "",
      error: "",
    },
    companyNameEng: {
      value: "",
      error: "",
    },
    companyNameThai: {
      value: "",
      error: "",
    },
    taxID: {
      value: "",
      error: "",
    },
    phoneNumber: {
      value: "",
      error: "",
    },
    faxNumber: {
      value: "",
      error: "",
    },
    email: {
      value: "",
      error: "",
    },
    companyWebsite: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  branchData: {
    companyCode: {
      value: "",
      error: "",
    },
    companyNameEng: {
      value: "",
      error: "",
    },
    companyNameThai: {
      value: "",
      error: "",
    },
    company: {
      value: "",
      error: "",
    },
    branchCode: {
      value: "",
      error: "",
    },
    branchNameEng: {
      value: "",
      error: "",
    },
    branchNameThai: {
      value: "",
      error: "",
    },
    phoneNumber: {
      value: "",
      error: "",
    },
    faxNumber: {
      value: "",
      error: "",
    },
    contactPerson: {
      value: "",
      error: "",
    },
    contactNumber: {
      value: "",
      error: "",
    },
    contactEmail: {
      value: "",
      error: "",
    },
    address: {
      value: "",
      error: "",
    },
    subDistrict: {
      value: "",
      error: "",
    },
    district: {
      value: "",
      error: "",
    },
    province: {
      value: "",
      error: "",
    },
    postCode: {
      value: "",
      error: "",
    },
    country: {
      value: "217",
      error: "",
    },
    location: {
      value: "",
      error: "",
    },
    latitude: {
      value: 0,
      error: "",
    },
    longitude: {
      value: 0,
      error: "",
    },
    mainBranch: {
      value: false,
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  divisionData: {
    company: {
      value: "",
      error: "",
    },
    branch: {
      value: "",
      error: "",
    },
    companyCode: {
      value: "",
      error: "",
    },
    companyNameEng: {
      value: "",
      error: "",
    },
    companyNameThai: {
      value: "",
      error: "",
    },
    branchCode: {
      value: "",
      error: "",
    },
    branchNameEng: {
      value: "",
      error: "",
    },
    branchNameThai: {
      value: "",
      error: "",
    },
    divisionCode: {
      value: "",
      error: "",
    },
    divisionNameEng: {
      value: "",
      error: "",
    },
    divisionNameThai: {
      value: "",
      error: "",
    },
    description: {
      value: "",
      error: "",
    },
    phoneNumber: {
      value: "",
      error: "",
    },
    faxNumber: {
      value: "",
      error: "",
    },
    email: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  departmentData: {
    company: {
      value: "",
      error: "",
    },
    branch: {
      value: "",
      error: "",
    },
    division: {
      value: "",
      error: "",
    },
    companyCode: {
      value: "",
      error: "",
    },
    companyNameEng: {
      value: "",
      error: "",
    },
    companyNameThai: {
      value: "",
      error: "",
    },
    branchCode: {
      value: "",
      error: "",
    },
    branchNameEng: {
      value: "",
      error: "",
    },
    branchNameThai: {
      value: "",
      error: "",
    },
    divisionCode: {
      value: "",
      error: "",
    },
    divisionNameEng: {
      value: "",
      error: "",
    },
    divisionNameThai: {
      value: "",
      error: "",
    },
    departmentCode: {
      value: "",
      error: "",
    },
    departmentNameEng: {
      value: "",
      error: "",
    },
    departmentNameThai: {
      value: "",
      error: "",
    },
    description: {
      value: "",
      error: "",
    },
    phoneNumber: {
      value: "",
      error: "",
    },
    faxNumber: {
      value: "",
      error: "",
    },
    email: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  positionData: {
    company: {
      value: "",
      error: "",
    },
    branch: {
      value: "",
      error: "",
    },
    division: {
      value: "",
      error: "",
    },
    department: {
      value: "",
      error: "",
    },
    companyCode: {
      value: "",
      error: "",
    },
    companyNameEng: {
      value: "",
      error: "",
    },
    companyNameThai: {
      value: "",
      error: "",
    },
    branchCode: {
      value: "",
      error: "",
    },
    branchNameEng: {
      value: "",
      error: "",
    },
    branchNameThai: {
      value: "",
      error: "",
    },
    divisionCode: {
      value: "",
      error: "",
    },
    divisionNameEng: {
      value: "",
      error: "",
    },
    divisionNameThai: {
      value: "",
      error: "",
    },
    departmentCode: {
      value: "",
      error: "",
    },
    departmentNameEng: {
      value: "",
      error: "",
    },
    departmentNameThai: {
      value: "",
      error: "",
    },
    positionCode: {
      value: "",
      error: "",
    },
    positionNameEng: {
      value: "",
      error: "",
    },
    positionNameThai: {
      value: "",
      error: "",
    },
    description: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  contractData: {
    company: {
      value: "",
      error: "",
    },
    companyCode: {
      value: "",
      error: "",
    },
    companyNameEng: {
      value: "",
      error: "",
    },
    companyNameThai: {
      value: "",
      error: "",
    },
    contractNumber: {
      value: "",
      error: "",
    },
    description: {
      value: "",
      error: "",
    },
    companySignDate: {
      value: null,
      error: "",
    },
    partnerSignDate: {
      value: null,
      error: "",
    },
    effectiveDate: {
      value: null,
      error: "",
    },
    expiryDate: {
      value: null,
      error: "",
    },
    contactPerson: {
      value: "",
      error: "",
    },
    contactPhoneNumber: {
      value: "",
      error: "",
    },
    notificationEmail: {
      value: "",
      error: "",
    },
    notificationLineGroup: {
      value: "",
      error: "",
    },
    notificationSetup: {
      value: "",
      error: "",
    },
    notificationDate: {
      value: null,
      error: "",
    },
    notificationMessage: {
      value: "",
      error: "",
    },
    pdfFile: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  branchList: [],
  branchListTotal: 0,
  divisionList: [],
  divisionListTotal: 0,
  departmentList: [],
  departmentListTotal: 0,
  positionList: [],
  positionListTotal: 0,
};

const company = (
  state = initState,
  action: { type: string; data: companyDataInterface }
) => {
  switch (action.type) {
    case "company_profile_data":
      return {
        ...state,
        companyProfileData: { ...state.companyProfileData, ...action.data },
      };
    case "branch_data":
      return {
        ...state,
        branchData: { ...state.branchData, ...action.data },
      };
    case "division_data":
      return {
        ...state,
        divisionData: { ...state.divisionData, ...action.data },
      };
    case "department_data":
      return {
        ...state,
        departmentData: { ...state.departmentData, ...action.data },
      };
    case "position_data":
      return {
        ...state,
        positionData: { ...state.positionData, ...action.data },
      };
    case "contract_data":
      return {
        ...state,
        contractData: { ...state.contractData, ...action.data },
      };
    case "branch_list":
      return {
        ...state,
        branchList: action.data,
      };
    case "branch_list_total":
      return {
        ...state,
        branchListTotal: action.data,
      };
    case "division_list":
      return {
        ...state,
        divisionList: action.data,
      };
    case "division_list_total":
      return {
        ...state,
        divisionListTotal: action.data,
      };
    case "department_list":
      return {
        ...state,
        departmentList: action.data,
      };
    case "department_list_total":
      return {
        ...state,
        departmentListTotal: action.data,
      };
    case "position_list":
      return {
        ...state,
        positionList: action.data,
      };
    case "position_list_total":
      return {
        ...state,
        positionListTotal: action.data,
      };
    case "clear_company":
      return initState;
    default:
      return state;
  }
};

export default company;
