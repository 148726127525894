import { filterDataInterface } from "../Interface";

const initState = {
  filterCompanyProfile: {
    search: "",
    page: 1,
    limit: "20",
  },
  filterBranch: {
    search: "",
    company: "",
    status: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterDivision: {
    search: "",
    company: "",
    branch: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterDepartment: {
    search: "",
    company: "",
    branch: "",
    division: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterPosition: {
    search: "",
    company: "",
    branch: "",
    division: "",
    department: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterContract: {
    search: "",
    company: "",
    branch: "",
    dateTypeChoose: "signdate",
    selectDate: null,
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterUserRole: {
    search: "",
    page: 1,
    limit: "20",
  },
  filterUserRolePermission: {
    search: "",
    role: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterProductType: {
    search: "",
    updateDate: null,
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterProduct: {
    search: "",
    productType: "",
    updateDate: null,
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterProductRate: {
    search: "",
    productType: "",
    product: "",
    updateDate: null,
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterWarehouse: {
    search: "",
    company: "",
    branch: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterLocation: {
    search: "",
    company: "",
    branch: "",
    page: 1,
    limit: "20",
  },
  filterUser: {
    search: "",
    company: "",
    role: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterCustomerCompany: {
    search: "",
    status: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterCustomerBranch: {
    search: "",
    company: "",
    status: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterCustomerShipping: {
    search: "",
    company: "",
    branch: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterActionLogs: {
    search: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
    action: "",
    menu: "",
    date: null,
  },
  filterOrder: {
    search: "",
    orderDateFrom: null,
    orderDateTo: null,
    company: "",
    branch: "",
    status: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterQuotation: {
    search: "",
    dateTypeChoose: "issuedate",
    selectDate: null,
    company: "",
    branch: "",
    status: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterInvoice: {
    search: "",
    dateTypeChoose: "invoicedate",
    selectDate: null,
    company: "",
    status: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterReceipt: {
    search: "",
    dateTypeChoose: "issuedate",
    selectDate: null,
    company: "",
    status: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterDelivery: {
    search: "",
    dateTypeChoose: "issuedate",
    selectDate: null,
    status: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterStock: {
    search: "",
    dateTypeChoose: "transactiondate",
    selectDate: null,
    status: "",
    warehouse: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterClaim: {
    search: "",
    dateTypeChoose: "issuedate",
    selectDate: null,
    company: "",
    status: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
  filterReceived: {
    search: "",
    dateTypeChoose: "issuedate",
    selectDate: null,
    status: "",
    page: 1,
    limit: "20",
    sorting: "updatedAt",
    order: -1,
    totalPage: 0,
    startShowing: 0,
    endShowing: 0,
  },
};

const filter = (
  state = initState,
  action: { type: string; data: filterDataInterface }
) => {
  switch (action.type) {
    case "filter_company_profile":
      return {
        ...state,
        filterCompanyProfile: { ...state.filterCompanyProfile, ...action.data },
      };
    case "filter_branch":
      return {
        ...state,
        filterBranch: { ...state.filterBranch, ...action.data },
      };
    case "filter_division":
      return {
        ...state,
        filterDivision: { ...state.filterDivision, ...action.data },
      };
    case "filter_department":
      return {
        ...state,
        filterDepartment: { ...state.filterDepartment, ...action.data },
      };
    case "filter_position":
      return {
        ...state,
        filterPosition: { ...state.filterPosition, ...action.data },
      };
    case "filter_contract":
      return {
        ...state,
        filterContract: { ...state.filterContract, ...action.data },
      };
    case "filter_user_role":
      return {
        ...state,
        filterUserRole: { ...state.filterUserRole, ...action.data },
      };
    case "filter_user_role_permission":
      return {
        ...state,
        filterUserRolePermission: {
          ...state.filterUserRolePermission,
          ...action.data,
        },
      };
    case "filter_product_type":
      return {
        ...state,
        filterProductType: {
          ...state.filterProductType,
          ...action.data,
        },
      };
    case "filter_product":
      return {
        ...state,
        filterProduct: {
          ...state.filterProduct,
          ...action.data,
        },
      };
    case "filter_product_rate":
      return {
        ...state,
        filterProductRate: {
          ...state.filterProductRate,
          ...action.data,
        },
      };
    case "filter_warehouse":
      return {
        ...state,
        filterWarehouse: {
          ...state.filterWarehouse,
          ...action.data,
        },
      };
    case "filter_location":
      return {
        ...state,
        filterLocation: {
          ...state.filterLocation,
          ...action.data,
        },
      };
    case "filter_user":
      return {
        ...state,
        filterUser: {
          ...state.filterUser,
          ...action.data,
        },
      };
    case "filter_customer_company":
      return {
        ...state,
        filterCustomerCompany: {
          ...state.filterCustomerCompany,
          ...action.data,
        },
      };
    case "filter_customer_branch":
      return {
        ...state,
        filterCustomerBranch: {
          ...state.filterCustomerBranch,
          ...action.data,
        },
      };
    case "filter_customer_shipping":
      return {
        ...state,
        filterCustomerShipping: {
          ...state.filterCustomerShipping,
          ...action.data,
        },
      };
    case "filter_action_logs":
      return {
        ...state,
        filterActionLogs: {
          ...state.filterActionLogs,
          ...action.data,
        },
      };
    case "filter_order":
      return {
        ...state,
        filterOrder: {
          ...state.filterOrder,
          ...action.data,
        },
      };
    case "filter_quotation":
      return {
        ...state,
        filterQuotation: {
          ...state.filterQuotation,
          ...action.data,
        },
      };
    case "filter_invoice":
      return {
        ...state,
        filterInvoice: {
          ...state.filterInvoice,
          ...action.data,
        },
      };
    case "filter_receipt":
      return {
        ...state,
        filterReceipt: {
          ...state.filterReceipt,
          ...action.data,
        },
      };
    case "filter_delivery_order":
      return {
        ...state,
        filterDelivery: {
          ...state.filterDelivery,
          ...action.data,
        },
      };
    case "filter_stock_inventory":
      return {
        ...state,
        filterStock: {
          ...state.filterStock,
          ...action.data,
        },
      };
    case "filter_claim":
      return {
        ...state,
        filterClaim: {
          ...state.filterClaim,
          ...action.data,
        },
      };
    case "filter_received_order":
      return {
        ...state,
        filterReceived: {
          ...state.filterReceived,
          ...action.data,
        },
      };
    default:
      return state;
  }
};

export default filter;
