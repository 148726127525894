import { roleDataInterface } from "../Interface";

const initState: roleDataInterface = {
  userManageData: {
    profileView: {
      value: "",
      error: "",
    },
    profile: {
      value: "",
      error: "",
    },
    userID: {
      value: "",
      error: "",
    },
    company: {
      value: "",
      error: "",
    },
    branch: {
      value: "",
      error: "",
    },
    division: {
      value: "",
      error: "",
    },
    department: {
      value: "",
      error: "",
    },
    position: {
      value: "",
      error: "",
    },
    companyName: {
      value: "",
      error: "",
    },
    branchName: {
      value: "",
      error: "",
    },
    divisionName: {
      value: "",
      error: "",
    },
    departmentName: {
      value: "",
      error: "",
    },
    positionName: {
      value: "",
      error: "",
    },
    role: {
      value: "",
      error: "",
    },
    title: {
      value: "",
      error: "",
    },
    first_name: {
      value: "",
      error: "",
    },
    last_name: {
      value: "",
      error: "",
    },
    birthdate: {
      value: null,
      error: "",
    },
    phoneNumber: {
      value: "",
      error: "",
    },
    email: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  userRoleData: {
    roleID: {
      value: "",
      error: "",
    },
    roleNameEng: {
      value: "",
      error: "",
    },
    roleNameThai: {
      value: "",
      error: "",
    },
    description: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  userRolePermissionData: {
    roleID: {
      value: "",
      error: "",
    },
    roleNameEng: {
      value: "",
      error: "",
    },
    roleNameThai: {
      value: "",
      error: "",
    },
    permissionID: {
      value: "",
      error: "",
    },
    permissionNameEng: {
      value: "",
      error: "",
    },
    permissionNameThai: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  userManageList: [],
  userManageListTotal: 0,
  userRoleList: [],
  userRoleListTotal: 0,
};

const role = (
  state = initState,
  action: { type: string; data: roleDataInterface }
) => {
  switch (action.type) {
    case "user_manage_data":
      return {
        ...state,
        userManageData: { ...state.userManageData, ...action.data },
      };
    case "user_role_data":
      return {
        ...state,
        userRoleData: { ...state.userRoleData, ...action.data },
      };
    case "user_role_permission_data":
      return {
        ...state,
        userRolePermissionData: {
          ...state.userRolePermissionData,
          ...action.data,
        },
      };
    case "user_manage_list":
      return {
        ...state,
        userManageList: action.data,
      };
    case "user_manage_list_total":
      return {
        ...state,
        userManageListTotal: action.data,
      };
    case "user_role_list":
      return {
        ...state,
        userRoleList: action.data,
      };
    case "user_role_list_total":
      return {
        ...state,
        userRoleListTotal: action.data,
      };
    case "clear_role":
      return initState;
    default:
      return state;
  }
};

export default role;
