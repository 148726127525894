import { storeDataInterface } from "../Interface";

const initState = {
  loading: false,
  openMenu: false,
  openProfile: "",
  menuToggleHide: false,
  openLogout: false,
  modal: {
    open: "",
    title: "",
  },
  actionLogsList: [],
  actionLogsListTotal: 0,
  actionLogsListAll: [],
};

const store = (
  state = initState,
  action: { type: string; data: storeDataInterface }
) => {
  switch (action.type) {
    case "loading":
      return { ...state, loading: action.data };
    case "openmenu":
      return { ...state, openMenu: action.data };
    case "openprofile":
      return { ...state, openProfile: action.data };
    case "menutoggle":
      return { ...state, menuToggleHide: action.data };
    case "openlogout":
      return { ...state, openLogout: action.data };
    case "modal":
      return { ...state, modal: { ...state.modal, ...action.data } };
    case "action_logs_list":
      return {
        ...state,
        actionLogsList: action.data,
      };
    case "action_logs_list_total":
      return {
        ...state,
        actionLogsListTotal: action.data,
      };
    case "action_logs_list_all":
      return {
        ...state,
        actionLogsListAll: action.data,
      };
    default:
      return state;
  }
};

export default store;
