import { combineReducers } from "redux";

import store from "./store";
import storeStorage from "./storeStorage";
import user from "./user";
import filter from "./filter";
import company from "./company";
import role from "./role";
import product from "./product";
import storeWare from "./storeWare";
import option from "./option";
import customer from "./customer";
import main from "./main";

const reducers = {
  store,
  storeStorage,
  user,
  filter,
  company,
  role,
  product,
  storeWare,
  option,
  customer,
  main,
};

export default combineReducers(reducers);
