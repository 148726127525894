import styled from "styled-components";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { imgList } from "@constants/img";
import { emptyString, numberWithCommas } from "@utils/convertString";
import { stateInterface } from "@redux/Interface";

const Contract = () => {
  const values = useSelector(
    (state: stateInterface) => state.main.contractView
  );

  const isImpliepack = useSelector(
    (state: stateInterface) => state.user.userInfo.isImpliepack
  );

  const contract_number = values.contract_number ?? "";
  const issue_date = values.issue_date ?? "";
  const expiry_date = values.expiry_date ?? "";
  const companyInfo = values.companyInfo;
  const customerInfo = values.customerInfo;
  const productView = values.productView ?? [];
  const company_sign_date = values.company_sign_date ?? "";
  const partner_sign_date = values.partner_sign_date ?? "";
  const effective_date = values.effective_date ?? "";
  const contact_person = values.contact_person ?? "";
  const contact_phone_number = values.contact_phone_number ?? "";
  const contact_email = values.contact_email ?? "";
  const credit_term = values.credit_term ?? "";

  const totalProduct = productView.reduce(function (sum, val, index) {
    return sum + parseInt(val.value_quantity);
  }, 0);

  return (
    <Content className="doc">
      <div className="doc__inner">
        <div className="doc__line"></div>
        <div className="doc__line-float doc__line-float--top"></div>
        <div className="doc__content">
          <div className="doc__top">
            <div className="doc__top-head">Contract</div>
            <div className="doc__top-left">
              <img src={imgList.logo_impliepack} alt="" />
            </div>
            <div className="doc__top-right">
              <div className="doc__top-date">
                <div className="doc__top-date-row">
                  <div className="doc__top-date-head doc__text-1">
                    Contract Number
                  </div>
                  <div className="doc__top-date-title doc__text-2">
                    {emptyString(contract_number)}
                  </div>
                </div>
                <div className="doc__top-date-row">
                  <div className="doc__top-date-head doc__text-1">
                    Issue Date
                  </div>
                  <div className="doc__top-date-title doc__text-2">
                    {issue_date ? dayjs(issue_date).format("DD/MM/YYYY") : "-"}
                  </div>
                </div>
                <div className="doc__top-date-row last">
                  <div className="doc__top-date-head doc__text-1">
                    Expiry Date
                  </div>
                  <div className="doc__top-date-title doc__text-2">
                    {expiry_date
                      ? dayjs(expiry_date).format("DD/MM/YYYY")
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="doc__info">
            <div className="doc__info-left">
              <div className="doc__info-head doc__text-3">
                Company Information
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Company</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(companyInfo?.company_name ?? "")}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Tax ID</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(companyInfo?.tax ?? "")}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Address</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(companyInfo?.address ?? "")},{" "}
                    {emptyString(companyInfo?.subDistrict ?? "")},{" "}
                    {emptyString(companyInfo?.district ?? "")},{" "}
                    {emptyString(companyInfo?.province ?? "")},{" "}
                    {emptyString(companyInfo?.country ?? "")},{" "}
                    {emptyString(companyInfo?.postCode ?? "")}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Telephone</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(companyInfo?.phone ?? "")}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Email</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(companyInfo?.email ?? "")}
                  </div>
                </div>
              </div>
            </div>
            <div className="doc__info-right">
              <div className="doc__info-head doc__text-3">
                Customer Information
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Company</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(customerInfo?.company_name ?? "")}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Branch</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(customerInfo?.branch_name ?? "")}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Tax ID</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(customerInfo?.tax ?? "")}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Address</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(customerInfo?.address ?? "")},{" "}
                    {emptyString(customerInfo?.subDistrict ?? "-")},{" "}
                    {emptyString(customerInfo?.district ?? "")},{" "}
                    {emptyString(customerInfo?.province ?? "")},{" "}
                    {emptyString(customerInfo?.country ?? "")},{" "}
                    {emptyString(customerInfo?.postCode ?? "")}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Contact Person</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(customerInfo?.contact_name ?? "")}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Telephone</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(customerInfo?.phone ?? "")}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Email</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(customerInfo?.email ?? "")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="doc__table">
            <div className="doc__table-detail">
              <div className="doc__table-row doc__table-row--head">
                <div className="doc__table-width doc__table-width--product doc__text-4">
                  Product
                </div>
                <div className="doc__table-width doc__table-width--quantity doc__text-4">
                  Quantity
                </div>
                <div className="doc__table-width doc__table-width--remark doc__text-4 last">
                  Remark
                </div>
              </div>
              {productView.map((val, index) => {
                return (
                  <div
                    key={index}
                    className={
                      "doc__table-row doc__table-row--content" +
                      (index + 1 === productView.length ? " last" : "")
                    }
                  >
                    <div className="doc__table-width doc__table-width--product doc__text-5">
                      {emptyString(val.value_product)}
                    </div>
                    <div className="doc__table-width doc__table-width--quantity doc__text-5 quantity">
                      {numberWithCommas(emptyString(val.value_quantity))}
                    </div>
                    <div className="doc__table-width doc__table-width--remark doc__text-5 last">
                      {emptyString(val.value_remark)}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="doc__table-row doc__table-row--footer">
              <div className="doc__table-width doc__table-width--product product doc__text-5">
                Total Product
              </div>
              <div className="doc__table-width doc__table-width--quantity quantity doc__text-5">
                {numberWithCommas(totalProduct)}
              </div>
            </div>
          </div>
          <div className="doc__info doc__info--bottom">
            <div className="doc__info-head doc__text-3">
              Contact Information
            </div>
            <div className="doc__info-left doc__info-left--border-hide">
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Contact Person</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(contact_person)}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Phone Number</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(contact_phone_number)}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Contact Email</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(contact_email)}
                  </div>
                </div>
              </div>
            </div>
            <div className="doc__info-right">
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Company Sign Date</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {company_sign_date
                      ? dayjs(company_sign_date).format("DD/MM/YYYY")
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Partner Sign Date</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {partner_sign_date
                      ? dayjs(partner_sign_date).format("DD/MM/YYYY")
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Effective Date</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {effective_date
                      ? dayjs(effective_date).format("DD/MM/YYYY")
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="doc__info-inner">
                <div className="doc__info-inner-left">
                  <div className="doc__text-1">Credit Term</div>
                </div>
                <div className="doc__info-inner-right">
                  <div className="doc__text-2">
                    {emptyString(credit_term)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isImpliepack && (
            <div className="doc__sig">
              <div className="doc__sig-box">
                <div className="doc__text-4">Customer Signature</div>
                <div className="doc__sig-line"></div>
                <div className="doc__sig-date doc__text-4">
                  Date __ __ / __ __ / __ __
                </div>
              </div>
              <div className="doc__sig-box">
                <div className="doc__text-4">Prepared By</div>
                <div className="doc__sig-line"></div>
                <div className="doc__sig-date doc__text-4">
                  Date __ __ / __ __ / __ __
                </div>
              </div>
              <div className="doc__sig-box last">
                <div className="doc__text-4">Authorised Signature</div>
                <div className="doc__sig-line"></div>
                <div className="doc__sig-date doc__text-4">
                  Date __ __ / __ __ / __ __
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="doc__line"></div>
        {/* <div className="doc__line-float doc__line-float--bottom"></div> */}
      </div>
    </Content>
  );
};

export default Contract;

const Content = styled.div`
  position: relative;
  width: 100%;
  @media only screen and (max-width: 450px) {
    overflow: scroll;
  }
  .doc {
    &__inner {
      width: 100%;
      @media only screen and (max-width: 450px) {
        width: 800px;
      }
      @media print {
        padding: 2.083vw 0;
        @media only screen and (max-width: 1024px) {
          padding: 2.93vw 0;
        }
        @media only screen and (max-width: 450px) {
          padding: 6.667vw 0;
        }
      }
    }
    &__line {
      width: 100%;
      //   height: 30px;
      height: 2.083vw;
      background-color: #4774c2;
      @media only screen and (max-width: 1024px) {
        height: 2.93vw;
      }
      @media only screen and (max-width: 450px) {
        height: 6.667vw;
      }
      @media print {
        display: none;
      }
    }
    &__line-float {
      display: none;
      position: fixed;
      width: 100%;
      height: 2.083vw;
      left: 0;
      background-color: #4774c2;
      &--top {
        top: 0;
      }
      &--bottom {
        bottom: 0;
      }
      @media only screen and (max-width: 1024px) {
        height: 2.93vw;
      }
      @media only screen and (max-width: 450px) {
        height: 6.667vw;
      }
      @media print {
        display: block;
      }
    }
    &__content {
      width: 100%;
      //   padding: 30px;
      padding: 2.083vw;
      background-color: #fff;
      @media only screen and (max-width: 1024px) {
        padding: 2.93vw;
      }
      @media only screen and (max-width: 450px) {
        padding: 6.667vw;
      }
    }
    &__top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    &__top-left {
      width: 25%;
    }
    &__top-right {
      width: 75%;
      text-align: right;
    }
    &__top-head {
      width: 100%;
      font-family: "SFProDisplay-Semibold";
      //   font-size: 60px;
      color: #4774c2;
      line-height: 1;
      text-align: right;
      //   margin-bottom: 25px;
      font-size: 4.167vw;
      margin-bottom: 1.736vw;
      @media only screen and (max-width: 1024px) {
        font-size: 5.859vw;
        margin-bottom: 2.441vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 13.333vw;
        margin-bottom: 5.556vw;
      }
    }
    &__top-date {
      width: fit-content;
      max-width: 100%;
      margin-left: auto;
      //   border: solid 2px #d3d3d3;
      border: solid 0.139vw #d3d3d3;
      @media only screen and (max-width: 1024px) {
        border: solid 0.195vw #d3d3d3;
      }
      @media only screen and (max-width: 450px) {
        border: solid 0.444vw #d3d3d3;
      }
    }
    &__top-date-row {
      max-width: 100%;
      display: flex;
      align-items: center;
      padding: 6px 14px;
      //   border-bottom: solid 2px #d3d3d3;
      border-bottom: solid 0.139vw #d3d3d3;
      &.last {
        border-bottom: none;
      }
      @media only screen and (max-width: 1024px) {
        border-bottom: solid 0.195vw #d3d3d3;
      }
      @media only screen and (max-width: 450px) {
        border-bottom: solid 0.444vw #d3d3d3;
      }
    }
    &__top-date-head {
      //   width: 200px;
      width: 13.889vw;
      text-align: left;
      line-height: 1;
      @media only screen and (max-width: 1024px) {
        width: 19.531vw;
      }
      @media only screen and (max-width: 450px) {
        width: 44.444vw;
      }
    }
    &__top-date-title {
      line-height: 1;
    }
    &__info {
      position: relative;
      width: 100%;
      display: flex;
      //   align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      border-top: solid 0.069vw #434343;
      border-bottom: solid 0.069vw #434343;
      margin: 2.083vw 0;
      padding: 2.083vw 0;
      &--bottom {
        border-top: none;
        border-bottom: none;
        margin: 0;
        padding: 0;
      }
      @media only screen and (max-width: 1024px) {
        margin: 2.93vw 0;
        padding: 2.93vw 0;
        border-top: solid 0.098vw #434343;
        border-bottom: solid 0.098vw #434343;
        &--bottom {
          border-top: none;
          border-bottom: none;
          margin: 0;
          padding: 0;
        }
      }
      @media only screen and (max-width: 450px) s {
        margin: 6.667vw 0;
        padding: 6.667vw 0;
        border-top: solid 0.222vw #434343;
        border-bottom: solid 0.222vw #434343;
        &--bottom {
          border-top: none;
          border-bottom: none;
          margin: 0;
          padding: 0;
        }
      }
    }
    &__info-left {
      width: 50%;
      padding: 0 4% 0 0;
      border-right: solid 0.139vw #707070;
      &--border-hide {
        border-right: none;
      }
      @media only screen and (max-width: 1024px) {
        border-right: solid 0.195vw #707070;
        &--border-hide {
          border-right: none;
        }
      }
      @media only screen and (max-width: 450px) {
        border-right: solid 0.444vw #707070;
        &--border-hide {
          border-right: none;
        }
      }
    }
    &__info-right {
      width: 46%;
    }
    &__info-head {
      width: 100%;
      margin: 0 0 0.5vw;
    }
    &__info-inner {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      line-height: 2;
    }
    &__info-inner-left {
      width: 34%;
    }
    &__info-inner-right {
      width: 65%;
    }
    &__sig {
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin: 2.083vw 0 0;
      border: solid 0.069vw #d3d3d3;
      @media only screen and (max-width: 1024px) {
        margin: 2.93vw 0 0;
        border: solid 0.098vw #d3d3d3;
      }
      @media only screen and (max-width: 450px) {
        margin: 6.667vw 0 0;
        border: solid 0.222vw #d3d3d3;
      }
    }
    &__sig-box {
      width: calc(100% / 3);
      padding: 2.083vw;
      border-right: solid 0.069vw #d3d3d3;
      &.last {
        border-right: none;
      }
      @media only screen and (max-width: 1024px) {
        padding: 2.93vw;
        border-right: solid 0.098vw #d3d3d3;
      }
      @media only screen and (max-width: 450px) {
        padding: 6.667vw;
        border-right: solid 0.222vw #d3d3d3;
      }
    }
    &__sig-line {
      width: 100%;
      margin: 3.472vw 0;
      border-top: solid 0.069vw #434343;
      @media only screen and (max-width: 1024px) {
        border-top: solid 0.098vw #434343;
      }
      @media only screen and (max-width: 450px) {
        border-top: solid 0.222vw #434343;
      }
    }
    &__sig-date {
      text-align: center;
    }
    &__table {
      width: 100%;
      margin: 0 0 2.083vw;
      @media only screen and (max-width: 1024px) {
        margin: 0 0 2.93vw;
      }
      @media only screen and (max-width: 450px) s {
        margin: 0 0 6.667vw;
      }
    }
    &__table-row {
      width: 100%;
      display: flex;
      &--head {
        background-color: #f8fbff;
        text-align: center;
        border-bottom: solid 0.069vw #d3d3d3;
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          //   padding: 20px 18px;
          padding: 1.389vw 1.25vw;
          border-right: solid 0.069vw #d3d3d3;
          &.last {
            border-right: none;
          }
        }
      }
      &--content {
        background-color: #f7f7f7;
        border-bottom: solid 0.069vw #d3d3d3;
        &.last {
          border-bottom: none;
        }
        > div {
          //   padding: 36px 18px;
          padding: 2.5vw 1.25vw;
          border-right: solid 0.069vw #d3d3d3;
          &.quantity {
            text-align: right;
          }
          &.last {
            border-right: none;
          }
        }
      }
      &--footer {
        > div {
          //   padding: 20px 18px;
          padding: 1.389vw 1.25vw;
          background-color: #d0e2fa;
          border-bottom: solid 0.069vw #d3d3d3;
          &.product {
            border-left: solid 0.069vw #d3d3d3;
          }
          &.quantity {
            text-align: right;
            border-right: solid 0.069vw #d3d3d3;
          }
        }
      }
      @media only screen and (max-width: 1024px) {
        &--head {
          border-bottom: solid 0.098vw #d3d3d3;
          > div {
            padding: 1.953vw 1.758vw;
            border-right: solid 0.098vw #d3d3d3;
          }
        }
        &--content {
          border-bottom: solid 0.098vw #d3d3d3;
          > div {
            padding: 3.516vw 1.758vw;
            border-right: solid 0.098vw #d3d3d3;
          }
        }
        &--footer {
          > div {
            padding: 1.953vw 1.758vw;
            border-bottom: solid 0.098vw #d3d3d3;
            &.product {
              border-left: solid 0.098vw #d3d3d3;
            }
            &.quantity {
              border-right: solid 0.098vw #d3d3d3;
            }
          }
        }
      }
      @media only screen and (max-width: 450px) {
        &--head {
          border-bottom: solid 0.222vw #d3d3d3;
          > div {
            padding: 4.444vw 4vw;
            border-right: solid 0.222vw #d3d3d3;
          }
        }
        &--content {
          border-bottom: solid 0.222vw #d3d3d3;
          > div {
            padding: 8vw 4vw;
            border-right: solid 0.222vw #d3d3d3;
          }
        }
        &--footer {
          > div {
            padding: 4.444vw 4vw;
            border-bottom: solid 0.222vw #d3d3d3;
            &.product {
              border-left: solid 0.222vw #d3d3d3;
            }
            &.quantity {
              border-right: solid 0.222vw #d3d3d3;
            }
          }
        }
      }
    }
    &__table-width {
      &--product {
        width: 55%;
      }
      &--quantity {
        width: 10%;
      }
      &--remark {
        width: 35%;
      }
      &--total {
        width: 65%;
      }
    }
    &__table-detail {
      border: solid 0.069vw #d3d3d3;
      @media only screen and (max-width: 1024px) {
        border: solid 0.098vw #d3d3d3;
      }
      @media only screen and (max-width: 450px) {
        border: solid 0.222vw #d3d3d3;
      }
    }
    &__text-1 {
      font-family: "SFProDisplay-Bold";
      //   font-size: 16px;
      font-size: 1.111vw;
      color: #434343;
      @media only screen and (max-width: 1024px) {
        font-size: 1.563vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 3.556vw;
      }
    }
    &__text-2 {
      font-family: "SFProDisplay-Regular";
      //   font-size: 16px;
      font-size: 1.111vw;
      color: #434343;
      @media only screen and (max-width: 1024px) {
        font-size: 1.563vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 3.556vw;
      }
    }
    &__text-3 {
      font-family: "SFProDisplay-Medium";
      //   font-size: 18px;
      font-size: 1.25vw;
      color: #434343;
      text-decoration: underline;
      @media only screen and (max-width: 1024px) {
        font-size: 1.758vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 4vw;
      }
    }
    &__text-4 {
      font-family: "SFProDisplay-Medium";
      //   font-size: 16px;
      font-size: 1.111vw;
      color: #434343;
      @media only screen and (max-width: 1024px) {
        font-size: 1.563vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 3.556vw;
      }
    }
    &__text-5 {
      font-family: "SFProDisplay-Regular";
      //   font-size: 14px;
      font-size: 0.972vw;
      color: #434343;
      @media only screen and (max-width: 1024px) {
        font-size: 1.367vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 3.111vw;
      }
    }
  }
`;
