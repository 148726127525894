import { productDataInterface } from "../Interface";

const initState: productDataInterface = {
  productTypeData: {
    productTypeCode: {
      value: "",
      error: "",
    },
    productTypeName: {
      value: "",
      error: "",
    },
    productDescription: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  productProductData: {
    productType: {
      value: "",
      error: "",
    },
    productTypeCode: {
      value: "",
      error: "",
    },
    productTypeName: {
      value: "",
      error: "",
    },
    productName: {
      value: "",
      error: "",
    },
    productDescription: {
      value: "",
      error: "",
    },
    productAge: {
      value: "",
      error: "",
    },
    costPrice: {
      value: "",
      error: "",
    },
    sellingPrice: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  productRateData: {
    productType: {
      value: "",
      error: "",
    },
    product: {
      value: "",
      error: "",
    },
    productRateCode: {
      value: "",
      error: "",
    },
    productRateName: {
      value: "",
      error: "",
    },
    productRateMin: {
      value: "",
      error: "",
    },
    productRateMax: {
      value: "",
      error: "",
    },
    productRatePrice: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  productProductList: [],
  productProductListTotal: 0,
  productProductTypeList: [],
  productProductTypeListTotal: 0,
  productProductRateList: [],
  productProductRateListTotal: 0,
};

const product = (
  state = initState,
  action: { type: string; data: productDataInterface }
) => {
  switch (action.type) {
    case "product_type_data":
      return {
        ...state,
        productTypeData: { ...state.productTypeData, ...action.data },
      };
    case "product_product_data":
      return {
        ...state,
        productProductData: { ...state.productProductData, ...action.data },
      };
    case "product_rate_data":
      return {
        ...state,
        productRateData: { ...state.productRateData, ...action.data },
      };
    case "product_product_list":
      return {
        ...state,
        productProductList: action.data,
      };
    case "product_product_list_total":
      return {
        ...state,
        productProductListTotal: action.data,
      };
    case "product_product_type_list":
      return {
        ...state,
        productProductTypeList: action.data,
      };
    case "product_product_type_list_total":
      return {
        ...state,
        productProductTypeListTotal: action.data,
      };
    case "product_product_rate_list":
      return {
        ...state,
        productProductRateList: action.data,
      };
    case "product_product_rate_list_total":
      return {
        ...state,
        productProductRateListTotal: action.data,
      };
    case "clear_product":
      return initState;
    default:
      return state;
  }
};

export default product;
