import logo_impliepack from "../static/images/img/logo_impliepack.png";
import bg_auth from "../static/images/img/bg_auth.png";
import bg_auth_mobile from "../static/images/img/bg_auth_mobile.png";
import avatar from "../static/images/img/avatar.png";
import arrow from "../static/images/img/arrow.png";
import close from "../static/images/img/close.png";
import arrow_gray from "../static/images/img/arrow_gray.png";
import arrow_blue from "../static/images/img/arrow_blue.png";
import arrow_white from "../static/images/img/arrow_white.png";
import arrow_search from "../static/images/img/arrow_search.png";
import glass_search from "../static/images/img/glass_search.png";
import arrow_limit from "../static/images/img/arrow_limit.png";
import arrow_back from "../static/images/img/arrow_back.png";
import ex_img from "../static/images/img/ex_img.png";
import remove_img from "../static/images/img/remove_img.png";
import filter from "../static/images/img/filter.png";
import date from "../static/images/img/date.png";
import chain from "../static/images/img/chain.png";
import pdf from "../static/images/img/pdf.png";
import marker from "../static/images/img/marker.png";
import dot from "../static/images/img/dot.png";
import pdf_file from "../static/images/img/pdf_file.png";
import edit from "../static/images/img/edit.png";

import { imgInterface } from "./imgInterface";

export const imgList: imgInterface = {
  logo_impliepack,
  bg_auth,
  bg_auth_mobile,
  avatar,
  arrow,
  close,
  arrow_gray,
  arrow_blue,
  arrow_white,
  arrow_search,
  glass_search,
  arrow_limit,
  arrow_back,
  ex_img,
  remove_img,
  filter,
  date,
  chain,
  pdf,
  marker,
  dot,
  pdf_file,
  edit,
};
