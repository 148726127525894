export const emptyString = (e: string) => {
  if (e === "") {
    return "-";
  } else {
    return e;
  }
};

export const numberWithCommas = (e: number | string) => {
  return e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const numberWithCommasWithTwoDigit = (e: number | string) => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  return Number(e).toLocaleString("en", options);
};

export const capitalizeFirstLetter = (e: string) => {
  return e.charAt(0).toUpperCase() + e.slice(1);
};
