import { iconList } from "@constants/icon";
import { menuDataMainInterface } from "@components/Aside/interface";

export const menuMainData: menuDataMainInterface[] = [
  {
    key: "contract",
    name: "Contract",
    icon: iconList.contract,
    icon_active: iconList.contract_active,
    permissions: [{ module_name: "contract", scope: "read" }],
    submenu: [],
  },
  {
    key: "order",
    name: "Order",
    icon: iconList.order,
    icon_active: iconList.order_active,
    permissions: [{ module_name: "order", scope: "read" }],
    submenu: [],
  },
  {
    key: "quotation",
    name: "Pricing",
    icon: iconList.quotation,
    icon_active: iconList.quotation_active,
    permissions: [{ module_name: "quotation", scope: "read" }],
    submenu: [],
  },
  {
    key: "invoice",
    name: "Invoice",
    icon: iconList.invoice,
    icon_active: iconList.invoice_active,
    permissions: [{ module_name: "invoice", scope: "read" }],
    submenu: [],
  },
  {
    key: "receipt",
    name: "Receipt",
    icon: iconList.receipt,
    icon_active: iconList.receipt_active,
    permissions: [{ module_name: "receipt", scope: "read" }],
    submenu: [],
  },
  {
    key: "deliveryorder",
    name: "Delivery Order",
    icon: iconList.delivery,
    icon_active: iconList.delivery_active,
    permissions: [{ module_name: "delivery-order", scope: "read" }],
    submenu: [],
  },
  {
    key: "receivedorder",
    name: "Received Order",
    icon: iconList.delivery,
    icon_active: iconList.delivery_active,
    permissions: [{ module_name: "receive-order", scope: "read" }],
    submenu: [],
  },
  {
    key: "stockinventory",
    name: "Stock Inventory",
    icon: iconList.stock,
    icon_active: iconList.stock_active,
    permissions: [{ module_name: "stock-inventory", scope: "read" }],
    submenu: [],
  },
  {
    key: "claim",
    name: "Claim",
    icon: iconList.claim,
    icon_active: iconList.claim_active,
    permissions: [{ module_name: "claim", scope: "read" }],
    submenu: [],
  },
];
export const menuMasterData: menuDataMainInterface[] = [
  {
    key: "warehouse",
    name: "Warehouse",
    icon: iconList.warehouse,
    icon_active: iconList.warehouse_active,
    permissions: [{ module_name: "warehouse", scope: "read" }],
    submenu: [],
  },
  {
    key: "product",
    name: "Product",
    icon: iconList.product,
    icon_active: iconList.product_active,
    permissions: [
      { module_name: "product-type", scope: "read" },
      { module_name: "product", scope: "read" },
      { module_name: "product-rate", scope: "read" },
    ],
    submenu: [
      {
        key: "producttype",
        name: "Product Type",
        permissions: { module_name: "product-type", scope: "read" },
      },
      {
        key: "product",
        name: "Product",
        permissions: { module_name: "product", scope: "read" },
      },
      {
        key: "productrate",
        name: "Product Rate",
        permissions: { module_name: "product-rate", scope: "read" },
      },
    ],
  },
  {
    key: "customer",
    name: "Customer",
    icon: iconList.customer,
    icon_active: iconList.customer_active,
    permissions: [
      { module_name: "customer-company", scope: "read" },
      { module_name: "customer-branch", scope: "read" },
      { module_name: "customer-shipping-address", scope: "read" },
    ],
    submenu: [
      {
        key: "company",
        name: "Company",
        permissions: { module_name: "customer-company", scope: "read" },
      },
      {
        key: "branch",
        name: "Branch",
        permissions: { module_name: "customer-branch", scope: "read" },
      },
      {
        key: "shippingaddress",
        name: "Shipping Address",
        permissions: {
          module_name: "customer-shipping-address",
          scope: "read",
        },
      },
    ],
  },
];

export const menuSettingsData: menuDataMainInterface[] = [
  {
    key: "mycompany",
    name: "My Company",
    icon: iconList.my_company,
    icon_active: iconList.my_company_active,
    permissions: [{ module_name: "company-profile", scope: "read" }],
    submenu: [],
  },
  {
    key: "branch",
    name: "Branch",
    icon: iconList.branch,
    icon_active: iconList.branch_active,
    permissions: [{ module_name: "branch", scope: "read" }],
    submenu: [],
  },
  {
    key: "division",
    name: "Division",
    icon: iconList.division,
    icon_active: iconList.division_active,
    permissions: [{ module_name: "division", scope: "read" }],
    submenu: [],
  },
  {
    key: "department",
    name: "Department",
    icon: iconList.department,
    icon_active: iconList.department_active,
    permissions: [{ module_name: "department", scope: "read" }],
    submenu: [],
  },
  {
    key: "position",
    name: "Position",
    icon: iconList.position,
    icon_active: iconList.position_active,
    permissions: [{ module_name: "position", scope: "read" }],
    submenu: [],
  },
  {
    key: "role",
    name: "Role & Permission",
    icon: iconList.role,
    icon_active: iconList.role_active,
    permissions: [{ module_name: "role-permission", scope: "read" }],
    submenu: [],
  },
  {
    key: "usermanagement",
    name: "User Management",
    icon: iconList.user_management,
    icon_active: iconList.user_management_active,
    permissions: [{ module_name: "user-management", scope: "read" }],
    submenu: [],
  },
  {
    key: "userprofile",
    name: "User Profile",
    icon: iconList.user_profile,
    icon_active: iconList.user_profile_active,
    permissions: [{ module_name: "user-profile", scope: "read" }],
    submenu: [],
  },
  {
    key: "actionlogs",
    name: "Action Logs",
    icon: iconList.action_logs,
    icon_active: iconList.action_logs_active,
    permissions: [{ module_name: "action-logs", scope: "read" }],
    submenu: [],
  },
];
