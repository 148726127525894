import { Input, InputProps } from "antd";
import styled from "styled-components";
import { omit } from "lodash";

import { iconList } from "@constants/icon";
import { inputAuthInterface } from "./interface";

const InputTextHelper = ({
  icon = iconList.icon_email,
  error = "",
  ...props
}: inputAuthInterface & InputProps) => {
  return (
    <Content className="input">
      <Input
        {...omit(props, "css")}
        className={"input__input" + (error ? " input__input--error" : "")}
      />
      <a
        href="https://notify-bot.line.me"
        target="_blank"
        className="input__text-last"
      >
        Helper
      </a>
      {error && <div className="input__error">{error}</div>}
    </Content>
  );
};

export default InputTextHelper;

const Content = styled.div`
  position: relative;
  width: 100%;
  .input {
    &__input {
      color: #000;
      font-size: 1vw;
      font-family: "SFProDisplay-Regular";
      font-weight: normal;
      line-height: 2.95;
      padding: 0 3.5vw 0 1vw;
      border: solid 1px #d3d3d3;
      border-radius: 0.521vw;
      -webkit-appearance: none;
      outline: none !important;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      &--error {
        border: solid 1px #ff0000;
        transition: none !important;
      }
      &.ant-input[disabled] {
        background-color: #e8e8e8 !important;
        border-color: #e8e8e8 !important;
      }
      &:hover,
      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        color: #d1d1d1;
        opacity: 1; /* Firefox */
      }
      &:-ms-input-placeholder {
        color: #d1d1d1;
      }
      &::-ms-input-placeholder {
        color: #d1d1d1;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 2.5vw;
        border-radius: 1vw;
        line-height: 2.5;
        padding: 0 8.5vw 0 1.5vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 3.35vw;
        line-height: 3;
        padding: 0 12.8vw 0 2.5vw;
      }
    }
    &__text-last {
      position: absolute;
      top: 50%;
      right: 1vw;
      transform: translate(0, -50%);
      font-size: 0.625vw;
      color: #9b9b9b;
      font-family: "SFProDisplay-Regular";
      font-weight: normal;
      display: block;
      @media only screen and (max-width: 1024px) {
        font-size: 2vw;
        right: 1.5vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 2.9vw;
        right: 2.5vw;
      }
    }
    &__icon {
      position: absolute;
      width: 4%;
      top: 50%;
      left: 3%;
      transform: translate(0, -50%);
    }
    &__error {
      position: absolute;
      width: 100%;
      left: 0;
      font-family: "SFProDisplay-Regular";
      font-weight: normal;
      font-size: 0.9vw;
      color: #ff0000;
      line-height: 1.3;
      margin: 0.2vw 0 0;
      @media only screen and (max-width: 1024px) {
        position: relative;
        font-size: 2.2vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 3vw;
      }
    }
  }
`;
