import { Input, InputProps } from "antd";
import styled from "styled-components";
import { omit } from "lodash";

import { iconList } from "@constants/icon";
import { inputPasswordInterface } from "./interface";

const InputPassword = ({
  icon = iconList.icon_email,
  error = "",
  text = "Show Password",
  onShowPassowrd = () => {},
  ...props
}: inputPasswordInterface & InputProps) => {
  return (
    <Content className="input">
      <div className="input__inner">
        <Input
          {...omit(props, "css")}
          className={"input__input" + (error ? " input__input--error" : "")}
        />
        <img src={icon} alt="" className="input__icon" />
        <div className="input__text-last" onClick={onShowPassowrd}>
          {text}
        </div>
      </div>
      {error && <div className="input__error">{error}</div>}
    </Content>
  );
};

export default InputPassword;

const Content = styled.div`
  position: relative;
  width: 100%;
  .input {
    &__inner {
      position: relative;
      width: 100%;
    }
    &__input {
      color: #000;
      font-size: 1.2vw;
      font-family: "SFProDisplay-Regular";
      font-weight: normal;
      line-height: 3;
      border: solid 1px #d3d3d3;
      border-radius: 0.2vw;
      padding: 0 19% 0 10%;
      -webkit-appearance: none;
      outline: none !important;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      &--error {
        border: solid 1px red;
      }
      &:hover,
      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        color: #d3d3d3;
        opacity: 1; /* Firefox */
      }
      &:-ms-input-placeholder {
        color: #d3d3d3;
      }
      &::-ms-input-placeholder {
        color: #d3d3d3;
      }
      @media only screen and (max-width: 1024px) {
        font-size: 2.8vw;
        border-radius: 1vw;
        line-height: 2.5;
        padding: 0 24% 0 10%;
      }
      @media only screen and (max-width: 450px) {
        font-size: 4vw;
        line-height: 2.7;
        padding: 0 26% 0 10%;
      }
    }
    &__icon {
      position: absolute;
      width: 4%;
      top: 50%;
      left: 3%;
      transform: translate(0, -50%);
    }
    &__text-last {
      position: absolute;
      top: 50%;
      right: 1vw;
      transform: translate(0, -50%);
      font-size: 1vw;
      color: #b7d6ff;
      font-family: "SFProDisplay-Regular";
      font-weight: normal;
      cursor: pointer;
      @media only screen and (max-width: 1024px) {
        font-size: 1.8vw;
        right: 1.5vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 2.9vw;
        right: 2.5vw;
      }
    }
    &__error {
      font-size: 1.2vw;
      font-family: "SFProDisplay-Regular";
      font-weight: normal;
      font-size: 0.9vw;
      color: #ff0000;
      @media only screen and (max-width: 1024px) {
        font-size: 2.2vw;
      }
      @media only screen and (max-width: 450px) {
        font-size: 3vw;
      }
    }
  }
`;
