import {
  permissionsScopeInterface,
  permissionsInterface,
} from "@redux/Interface";

interface userPermissionInterface {
  userPermission: permissionsInterface[];
}

export const permissionWithScope = ({
  permissions,
  userPermission,
}: permissionsScopeInterface & userPermissionInterface) => {
  let canAccess: boolean = false;
  const filter = userPermission.filter(
    (e) => e.module_name === permissions.module_name
  );
  if (filter.length > 0) {
    if (permissions.scope === "create") {
      if (filter[0].scopes.create === true) {
        canAccess = true;
      } else {
        canAccess = false;
      }
    } else if (permissions.scope === "update") {
      if (filter[0].scopes.update === true) {
        canAccess = true;
      } else {
        canAccess = false;
      }
    } else if (permissions.scope === "delete") {
      if (filter[0].scopes.delete === true) {
        canAccess = true;
      } else {
        canAccess = false;
      }
    } else if (permissions.scope === "export") {
      if (filter[0].scopes.export === true) {
        canAccess = true;
      } else {
        canAccess = false;
      }
    } else {
      if (filter[0].scopes.read === true) {
        canAccess = true;
      } else {
        canAccess = false;
      }
    }
  } else {
    canAccess = false;
  }
  return canAccess;
};
