import { modalDataInterface, actionLogsListInterface } from "../Interface";

export function loadingAction(data: boolean) {
  return {
    type: "loading",
    data: data,
  };
}

export function openMenuAction(data: boolean) {
  return {
    type: "openmenu",
    data: data,
  };
}

export function openProfileAction(data: string) {
  return {
    type: "openprofile",
    data: data,
  };
}

export function menuToggleAction(data: boolean) {
  return {
    type: "menutoggle",
    data: data,
  };
}

export function openLogoutAction(data: boolean) {
  return {
    type: "openlogout",
    data: data,
  };
}

export function modalAction(data: modalDataInterface) {
  return {
    type: "modal",
    data: data,
  };
}

export function actionLogsListAction(data: actionLogsListInterface[]) {
  return {
    type: "action_logs_list",
    data: data,
  };
}

export function actionLogsListTotalAction(data: number) {
  return {
    type: "action_logs_list_total",
    data: data,
  };
}

export function actionLogsListAllAction(data: actionLogsListInterface[]) {
  return {
    type: "action_logs_list_all",
    data: data,
  };
}
