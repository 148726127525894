import { mainDataInterface } from "../Interface";

const initState = {
  contractData: {
    credit_term: {
      value: "",
      error: "",
    },
    company: {
      value: "",
      error: "",
    },
    branch: {
      value: "",
      error: "",
    },
    contract_number: {
      value: "",
      error: "",
    },
    description: {
      value: "",
      error: "",
    },
    issue_date: {
      value: null,
      error: "",
    },
    company_sign_date: {
      value: null,
      error: "",
    },
    partner_sign_date: {
      value: null,
      error: "",
    },
    effective_date: {
      value: null,
      error: "",
    },
    expiry_date: {
      value: null,
      error: "",
    },
    contact_person: {
      value: "",
      error: "",
    },
    contact_phone_number: {
      value: "",
      error: "",
    },
    contact_email: {
      value: "",
      error: "",
    },
    notification_setup: {
      value: "",
      error: "",
    },
    notification_date: {
      value: null,
      error: "",
    },
    notification_email: {
      value: "",
      error: "",
    },
    line_key: {
      value: "",
      error: "",
    },
    notification_message: {
      value: "",
      error: "",
    },
    pdf: {
      value: "",
      error: "",
    },
    product: [
      {
        value_product: "",
        error_product: "",
        value_quantity: "0",
        error_quantity: "",
        value_remark: "",
        error_remark: "",
      },
    ],
    status: {
      value: "",
      error: "",
    },
    canDelete: false,
    canEdit: false,
    canPrint: false,
  },
  contractView: {
    company: "",
    branch: "",
    contract_number: "",
    description: "",
    issue_date: "",
    company_sign_date: "",
    partner_sign_date: "",
    effective_date: "",
    expiry_date: "",
    contact_person: "",
    contact_phone_number: "",
    contact_email: "",
    notification_setup: "",
    notification_date: "",
    notification_email: "",
    line_key: "",
    notification_message: "",
    pdf: "",
    product: [],
    productView: [],
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    status: "",
    canDelete: false,
    canEdit: false,
    canPrint: false,
    companyInfo: {
      company_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
    },
    customerInfo: {
      company_name: "",
      branch_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
      contact_name: "",
    },
  },
  orderData: {
    order_number: {
      value: "",
      error: "",
    },
    contract_number: {
      value: "",
      error: "",
    },
    company: {
      value: "",
      error: "",
    },
    branch: {
      value: "",
      error: "",
    },
    tax: {
      value: "",
      error: "",
    },
    address: {
      value: "",
      error: "",
    },
    subDistrict: {
      value: "",
      error: "",
    },
    district: {
      value: "",
      error: "",
    },
    province: {
      value: "",
      error: "",
    },
    postCode: {
      value: "",
      error: "",
    },
    country: {
      value: "217",
      error: "",
    },
    contact_person: {
      value: "",
      error: "",
    },
    contact_phone_number: {
      value: "",
      error: "",
    },
    contact_email: {
      value: "",
      error: "",
    },
    billing_type: {
      value: "1",
      error: "",
    },
    billing_address_company: {
      value: "",
      error: "",
    },
    billing_address_branch: {
      value: "",
      error: "",
    },
    billing_address_tax: {
      value: "",
      error: "",
    },
    billing_address: {
      value: "",
      error: "",
    },
    billing_subDistrict: {
      value: "",
      error: "",
    },
    billing_district: {
      value: "",
      error: "",
    },
    billing_province: {
      value: "",
      error: "",
    },
    billing_postCode: {
      value: "",
      error: "",
    },
    billing_country: {
      value: "217",
      error: "",
    },
    issue_date: {
      value: null,
      error: "",
    },
    expiry_date: {
      value: null,
      error: "",
    },
    lot: [
      {
        lot_code: "",
        flag: true,
        shipmentDate: { value: null, error: "" },
        shippingAddress: {
          value: "",
          error: "",
        },
        product: [
          {
            value_product: "",
            error_product: "",
            value_quantity: "0",
            error_quantity: "",
            value_remark: "",
            error_remark: "",
          },
        ],
        totalProduct: 0,
      },
    ],
    lot_use: [],
    remark: {
      value: "",
      error: "",
    },
    status: {
      value: "",
      error: "",
    },
    canDelete: false,
    canEdit: false,
    canPrint: false,
  },
  orderView: {
    order_number: "",
    contract_number: "",
    contract_id: "",
    company: "",
    branch: "",
    tax: "",
    address: "",
    subDistrict: "",
    district: "",
    province: "",
    postCode: "",
    country: "",
    contact_person: "",
    contact_phone_number: "",
    contact_email: "",
    billing_type: "",
    billing_address_company: "",
    billing_address_branch: "",
    billing_address_tax: "",
    billing_address: "",
    billing_subDistrict: "",
    billing_district: "",
    billing_province: "",
    billing_postCode: "",
    billing_country: "",
    issue_date: "",
    expiry_date: "",
    lot: [
      {
        _id: "",
        lot_code: "",
        flag: true,
        shipmentDate: { value: null, error: "" },
        shippingAddress: {
          value: "",
          error: "",
        },
        product: [
          {
            value_product: "",
            error_product: "",
            value_quantity: "0",
            error_quantity: "",
            value_remark: "",
            error_remark: "",
          },
        ],
        totalProduct: 0,
      },
    ],
    remark: "",
    status: "",
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    canDelete: false,
    canEdit: false,
    canPrint: false,
    companyInfo: {
      company_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
    },
    customerInfo: {
      company_name: "",
      branch_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
      contact_name: "",
    },
    billingAddressInfo: {
      company_name: "",
      branch_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
    },
    total_product: 0,
    subtotal_price: 0,
    special_discount: 0,
    net_total: 0,
    vat: 0,
    grand_total: 0,
  },
  quotationData: {
    order_number: {
      value: "",
      error: "",
    },
    contract_number: {
      value: "",
      error: "",
    },
    quotation_number: {
      value: "",
      error: "",
    },
    issue_date: {
      value: null,
      error: "",
    },
    credit_term: {
      value: "",
      error: "",
    },
    expiry_date: {
      value: null,
      error: "",
    },
    company: {
      value: "",
      error: "",
    },
    branch: {
      value: "",
      error: "",
    },
    tax: {
      value: "",
      error: "",
    },
    contact_person: {
      value: "",
      error: "",
    },
    contact_phone_number: {
      value: "",
      error: "",
    },
    contact_email: {
      value: "",
      error: "",
    },
    special_discount: {
      value: "",
      error: "",
    },
    tax_three: {
      value: false,
      error: "",
    },
    remark: {
      value: "",
      error: "",
    },
    total_product: {
      value: 0,
      error: "",
    },
    subtotal_price: {
      value: 0,
      error: "",
    },
    net_total: {
      value: 0,
      error: "",
    },
    vat: {
      value: 0,
      error: "",
    },
    tax_cal: {
      value: 0,
      error: "",
    },
    grand_total: {
      value: 0,
      error: "",
    },
    lotView: [],
    canDelete: false,
    canEdit: false,
    canPrint: false,
  },
  quotationView: {
    quotation_number: "",
    order_number: "",
    contract_number: "",
    company: "",
    branch: "",
    tax: "",
    address: "",
    subDistrict: "",
    district: "",
    province: "",
    postCode: "",
    country: "",
    contact_person: "",
    contact_phone_number: "",
    contact_email: "",
    issue_date: "",
    expiry_date: "",
    remark: "",
    status: "",
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    canDelete: false,
    canEdit: false,
    canPrint: false,
    companyInfo: {
      company_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
    },
    customerInfo: {
      company_name: "",
      branch_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
      contact_name: "",
    },
    total_product: 0,
    subtotal_price: 0,
    special_discount: 0,
    net_total: 0,
    vat: 0,
    grand_total: 0,
    price_tax_three: 0,
    tax_three: false,
    credit_term: "",
    lotView: [],
  },
  invoiceData: {
    quotation_number: {
      value: "",
      error: "",
    },
    invoice_number: {
      value: "",
      error: "",
    },
    lot_id: {
      value: "",
      error: "",
    },
    issue_date: {
      value: null,
      error: "",
    },
    credit_term: {
      value: "",
      error: "",
    },
    expiry_date: {
      value: null,
      error: "",
    },
    company: {
      value: "",
      error: "",
    },
    branch: {
      value: "",
      error: "",
    },
    tax: {
      value: "",
      error: "",
    },
    contact_person: {
      value: "",
      error: "",
    },
    contact_phone_number: {
      value: "",
      error: "",
    },
    contact_email: {
      value: "",
      error: "",
    },
    special_discount: {
      value: "",
      error: "",
    },
    tax_three: {
      value: false,
      error: "",
    },
    remark: {
      value: "",
      error: "",
    },
    total_product: {
      value: 0,
      error: "",
    },
    subtotal_price: {
      value: 0,
      error: "",
    },
    net_total: {
      value: 0,
      error: "",
    },
    vat: {
      value: 0,
      error: "",
    },
    tax_cal: {
      value: 0,
      error: "",
    },
    grand_total: {
      value: 0,
      error: "",
    },
    canDelete: false,
    canEdit: false,
    canPrint: false,
  },
  invoiceView: {
    quotation_id: "",
    lot_id: "",
    invoice_number: "",
    quotation_number: "",
    order_number: "",
    contract_number: "",
    company: "",
    branch: "",
    tax: "",
    address: "",
    subDistrict: "",
    district: "",
    province: "",
    postCode: "",
    country: "",
    contact_person: "",
    contact_phone_number: "",
    contact_email: "",
    issue_date: "",
    expiry_date: "",
    remark: "",
    status: "",
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    canDelete: false,
    canEdit: false,
    canPrint: false,
    companyInfo: {
      company_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
    },
    customerInfo: {
      company_name: "",
      branch_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
      contact_name: "",
    },
    total_product: 0,
    subtotal_price: 0,
    special_discount: 0,
    net_total: 0,
    vat: 0,
    grand_total: 0,
    price_tax_three: 0,
    tax_three: false,
    credit_term: "",
    lotView: [],
  },
  receiptData: {
    receipt_number: {
      value: "",
      error: "",
    },
    invoice_number: {
      value: "",
      error: "",
    },
    issue_date: {
      value: null,
      error: "",
    },
    company: {
      value: "",
      error: "",
    },
    branch: {
      value: "",
      error: "",
    },
    tax: {
      value: "",
      error: "",
    },
    address: {
      value: "",
      error: "",
    },
    special_discount: {
      value: "",
      error: "",
    },
    tax_three: {
      value: false,
      error: "",
    },
    remark: {
      value: "",
      error: "",
    },
    total_product: {
      value: 0,
      error: "",
    },
    subtotal_price: {
      value: 0,
      error: "",
    },
    net_total: {
      value: 0,
      error: "",
    },
    vat: {
      value: 0,
      error: "",
    },
    tax_cal: {
      value: 0,
      error: "",
    },
    grand_total: {
      value: 0,
      error: "",
    },
    canDelete: false,
    canEdit: false,
    canPrint: false,
    payment_medthod: "cash",
    payment_amount: {
      value: "",
      error: "",
    },
    payment_date: {
      value: null,
      error: "",
    },
    payment_remark: {
      value: "",
      error: "",
    },
    payment_slip: {
      value: "",
      error: "",
    },
    bank_name: {
      value: "",
      error: "",
    },
    bank_branch: {
      value: "",
      error: "",
    },
    cheque: {
      value: "",
      error: "",
    },
  },
  receiptView: {
    invoice_id: "",
    lot_id: "",
    receipt_number: "",
    invoice_number: "",
    quotation_number: "",
    order_number: "",
    contract_number: "",
    issue_date: "",
    payment_date: "",
    bank_branch: "",
    bank_name: "",
    cheque_number: "",
    payment_amount: "",
    payment_medthod: "",
    payment_remark: "",
    payment_slip: "",
    remark: "",
    status: "",
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    canDelete: false,
    canEdit: false,
    canPrint: false,
    companyInfo: {
      company_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
    },
    customerInfo: {
      company_name: "",
      branch_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
      contact_name: "",
    },
    billingAddressInfo: {
      company_name: "",
      branch_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
    },
    total_product: 0,
    subtotal_price: 0,
    special_discount: 0,
    net_total: 0,
    vat: 0,
    grand_total: 0,
    price_tax_three: 0,
    tax_three: false,
    credit_term: "",
    lotView: [],
  },
  stockData: {
    stock_number: {
      value: "",
      error: "",
    },
    transaction_date: {
      value: null,
      error: "",
    },
    manufacturing_date: {
      value: null,
      error: "",
    },
    warehouse_id: {
      value: "",
      error: "",
    },
    product_type_id: {
      value: "",
      error: "",
    },
    product_id: {
      value: "",
      error: "",
    },
    lot_number: {
      value: "",
      error: "",
    },
    stock_type: {
      value: "in",
      error: "",
    },
    remark: {
      value: "",
      error: "",
    },
    status: {
      value: "",
      error: "",
    },
    canDelete: false,
    canEdit: false,
    canPrint: false,
  },
  stockCustomerData: {
    stock_number: {
      value: "",
      error: "",
    },
    transaction_date: {
      value: null,
      error: "",
    },
    warehouse_id: {
      value: "",
      error: "",
    },
    company_id: {
      value: "",
      error: "",
    },
    branch_id: {
      value: "",
      error: "",
    },
    customer_warehouse_id: {
      value: "",
      error: "",
    },
    customer_company_id: {
      value: "",
      error: "",
    },
    customer_branch_id: {
      value: "",
      error: "",
    },
    stock_remaining: {
      value: "",
      error: "",
    },
    type: {
      value: "withdraw",
      error: "",
    },
    product: {
      value: "",
      error: "",
    },
    product_type: {
      value: "",
      error: "",
    },
    status: {
      value: "",
      error: "",
    },
    canDelete: false,
    canEdit: false,
    canPrint: false,
  },
  stockView: {
    stock_number: "",
    transaction_date: "",
    manufacturing_date: "",
    lot_number: "",
    warehouse_name: "",
    product: "",
    product_type: "",
    stock_status: "",
    quantity: "",
    remark: "",
    warehouse_id: "",
    product_type_id: "",
    product_id: "",
    transaction_date_real: null,
    manufacturing_date_real: null,
    status: "",
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    canDelete: false,
    canEdit: false,
    canPrint: false,
  },
  stockCustomerManage: [
    {
      box_id: "",
      remark: "",
      error_box_id: "",
      error_remark: "",
    },
  ],
  stockCustomerView: {
    stock_number: "",
    transaction_date: "",
    company: "",
    branch: "",
    warehouse: "",
    customer_company: "",
    customer_branch: "",
    customer_warehouse: "",
    typeStock: "",
    product: "",
    quantity: "",
    status: "",
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    canDelete: false,
    canEdit: false,
    canPrint: false,
  },
  deliveryData: {
    delivery_number: {
      value: "",
      error: "",
    },
    issue_date: {
      value: null,
      error: "",
    },
    company: {
      value: "",
      error: "",
    },
    branch: {
      value: "",
      error: "",
    },
    tax: {
      value: "",
      error: "",
    },
    address: {
      value: "",
      error: "",
    },
    phone_number: {
      value: "",
      error: "",
    },
    email: {
      value: "",
      error: "",
    },
    driver_name: {
      value: "",
      error: "",
    },
    license_plate: {
      value: "",
      error: "",
    },
    driver_phone_number: {
      value: "",
      error: "",
    },
    shipment_date: {
      value: null,
      error: "",
    },
    shipment_info: [],
    delivery_remark: {
      value: "",
      error: "",
    },
    invoice_id: "",
    shipment_info_error: "",
    status: {
      value: "",
      error: "",
    },
  },
  deliveryView: {
    deliver_number: "",
    issue_date: "",
    delivery_remark: "",
    driver_name: "",
    driver_phone_number: "",
    license_plate: "",
    shipment_info_product: [],
    shipment_info: [],
    company: "",
    branch: "",
    status: "",
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    canDelete: false,
    canEdit: false,
    canPrint: false,
    companyInfo: {
      company_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
    },
    customerInfo: {
      company_name: "",
      branch_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
      contact_name: "",
    },
  },
  deliveryNote: {
    deliver_number: "",
    issue_date: "",
    driver_name: "",
    driver_phone_number: "",
    license_plate: "",
    shipment_date: "",
    shipping_address: "",
    product: "",
    box_id: "",
    quantity: "",
    status: "",
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    canDelete: false,
    canEdit: false,
    canPrint: false,
    companyInfo: {
      company_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
    },
    customerInfo: {
      company_name: "",
      branch_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
      contact_name: "",
    },
  },
  receivedOrderData: {
    received_order_number: {
      value: "",
      error: "",
    },
    issue_date: {
      value: null,
      error: "",
    },
    delivery_number: {
      value: "",
      error: "",
    },
    remark: {
      value: "",
      error: "",
    },
    deliveryProduct: [],
    status: {
      value: "",
      error: "",
    },
    canDelete: false,
    canEdit: false,
    canPrint: false,
  },
  receivedOrderView: {
    received_order_number: "",
    issue_date: null,
    delivery_number: "",
    delivery_code: "",
    remark: "",
    deliveryProduct: [],
    driver_name: "",
    driver_phone_number: "",
    license_plate: "",
    shipment_date: null,
    shipment_address: "",
    receivedInfo: [],
    status: "",
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    canDelete: false,
    canEdit: false,
    canPrint: false,
    companyInfo: {
      company_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
    },
    customerInfo: {
      company_name: "",
      branch_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
      contact_name: "",
    },
  },
  claimData: {
    claim_number: {
      value: "",
      error: "",
    },
    company_id: {
      value: "",
      error: "",
    },
    issue_date: {
      value: null,
      error: "",
    },
    shipment_date: {
      value: null,
      error: "",
    },
    shipping_address_id: {
      value: "",
      error: "",
    },
    box_ids: [
      {
        value_box_id: "",
        error_box_id: "",
        value_claim_detail: "",
        error_claim_detail: "",
        value_remark: "",
        error_remark: "",
      },
    ],
    status: {
      value: "",
      error: "",
    },
  },
  claimView: {
    claim_number: "",
    issue_date: "",
    box_id_view: [],
    warehouse_code: "",
    warehouse_name_th: "",
    status: "",
    createdBy: "",
    createdDate: "",
    updatedBy: "",
    updatedDate: "",
    canDelete: false,
    canEdit: false,
    canPrint: false,
    companyInfo: {
      company_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
    },
    customerInfo: {
      company_name: "",
      branch_name: "",
      tax: "",
      address: "",
      subDistrict: "",
      district: "",
      province: "",
      postCode: "",
      country: "",
      phone: "",
      email: "",
      contact_name: "",
    },
  },
  contractList: [],
  contractListAll: [],
  contractListTotal: 0,
  orderList: [],
  orderListAll: [],
  orderListTotal: 0,
  quotationList: [],
  quotationListAll: [],
  quotationListTotal: 0,
  invoiceList: [],
  invoiceListAll: [],
  invoiceListTotal: 0,
  receiptList: [],
  receiptListAll: [],
  receiptListTotal: 0,
  stockList: [],
  stockListAll: [],
  stockListTotal: 0,
  stockListCustomer: [],
  stockListAllCustomer: [],
  stockListTotalCustomer: 0,
  deliveryList: [],
  deliveryListAll: [],
  deliveryListTotal: 0,
  receivedOrderList: [],
  receivedOrderListAll: [],
  receivedOrderListTotal: 0,
  claimList: [],
  claimListAll: [],
  claimListTotal: 0,
};

const main = (
  state = initState,
  action: { type: string; data: mainDataInterface }
) => {
  switch (action.type) {
    case "contract_data_data":
      return {
        ...state,
        contractData: { ...state.contractData, ...action.data },
      };
    case "contract_view":
      return {
        ...state,
        contractView: { ...state.contractView, ...action.data },
      };
    case "order_data":
      return {
        ...state,
        orderData: { ...state.orderData, ...action.data },
      };
    case "order_view":
      return {
        ...state,
        orderView: { ...state.orderView, ...action.data },
      };
    case "quotation_data":
      return {
        ...state,
        quotationData: { ...state.quotationData, ...action.data },
      };
    case "quotation_view":
      return {
        ...state,
        quotationView: { ...state.quotationView, ...action.data },
      };
    case "invoice_data":
      return {
        ...state,
        invoiceData: { ...state.invoiceData, ...action.data },
      };
    case "invoice_view":
      return {
        ...state,
        invoiceView: { ...state.invoiceView, ...action.data },
      };
    case "receipt_data":
      return {
        ...state,
        receiptData: { ...state.receiptData, ...action.data },
      };
    case "receipt_view":
      return {
        ...state,
        receiptView: { ...state.receiptView, ...action.data },
      };
    case "stock_data":
      return {
        ...state,
        stockData: { ...state.stockData, ...action.data },
      };
    case "stock_customer_data":
      return {
        ...state,
        stockCustomerData: { ...state.stockCustomerData, ...action.data },
      };
    case "stock_view":
      return {
        ...state,
        stockView: { ...state.stockView, ...action.data },
      };
    case "stock_customer_manage":
      return {
        ...state,
        stockCustomerManage: action.data,
      };
    case "stock_customer_view":
      return {
        ...state,
        stockCustomerView: action.data,
      };
    case "delivery_data":
      return {
        ...state,
        deliveryData: { ...state.deliveryData, ...action.data },
      };
    case "delivery_view":
      return {
        ...state,
        deliveryView: { ...state.deliveryView, ...action.data },
      };
    case "delivery_note":
      return {
        ...state,
        deliveryNote: { ...state.deliveryNote, ...action.data },
      };
    case "received_order_data":
      return {
        ...state,
        receivedOrderData: { ...state.receivedOrderData, ...action.data },
      };
    case "received_order_view":
      return {
        ...state,
        receivedOrderView: { ...state.receivedOrderView, ...action.data },
      };
    case "claim_data":
      return {
        ...state,
        claimData: { ...state.claimData, ...action.data },
      };
    case "claim_view":
      return {
        ...state,
        claimView: { ...state.claimView, ...action.data },
      };
    case "contract_list":
      return {
        ...state,
        contractList: action.data,
      };
    case "contract_list_all":
      return {
        ...state,
        contractListAll: action.data,
      };
    case "contract_list_total":
      return {
        ...state,
        contractListTotal: action.data,
      };
    case "order_list":
      return {
        ...state,
        orderList: action.data,
      };
    case "order_list_all":
      return {
        ...state,
        orderListAll: action.data,
      };
    case "order_list_total":
      return {
        ...state,
        orderListTotal: action.data,
      };
    case "quotation_list":
      return {
        ...state,
        quotationList: action.data,
      };
    case "quotation_list_all":
      return {
        ...state,
        quotationListAll: action.data,
      };
    case "quotation_list_total":
      return {
        ...state,
        quotationListTotal: action.data,
      };
    case "invoice_list":
      return {
        ...state,
        invoiceList: action.data,
      };
    case "invoice_list_all":
      return {
        ...state,
        invoiceListAll: action.data,
      };
    case "invoice_list_total":
      return {
        ...state,
        invoiceListTotal: action.data,
      };
    case "receipt_list":
      return {
        ...state,
        receiptList: action.data,
      };
    case "receipt_list_all":
      return {
        ...state,
        receiptListAll: action.data,
      };
    case "receipt_list_total":
      return {
        ...state,
        receiptListTotal: action.data,
      };
    case "stock_list":
      return {
        ...state,
        stockList: action.data,
      };
    case "stock_list_all":
      return {
        ...state,
        stockListAll: action.data,
      };
    case "stock_list_total":
      return {
        ...state,
        stockListTotal: action.data,
      };
    case "stock_list_customer":
      return {
        ...state,
        stockListCustomer: action.data,
      };
    case "stock_list_all_customer":
      return {
        ...state,
        stockListAllCustomer: action.data,
      };
    case "stock_list_total_customer":
      return {
        ...state,
        stockListTotalCustomer: action.data,
      };
    case "delivery_list":
      return {
        ...state,
        deliveryList: action.data,
      };
    case "delivery_list_all":
      return {
        ...state,
        deliveryListAll: action.data,
      };
    case "delivery_list_total":
      return {
        ...state,
        deliveryListTotal: action.data,
      };
    case "received_order_list":
      return {
        ...state,
        receivedOrderList: action.data,
      };
    case "received_order_list_all":
      return {
        ...state,
        receivedOrderListAll: action.data,
      };
    case "received_order_list_total":
      return {
        ...state,
        receivedOrderListTotal: action.data,
      };
    case "claim_list":
      return {
        ...state,
        claimList: action.data,
      };
    case "claim_list_all":
      return {
        ...state,
        claimListAll: action.data,
      };
    case "claim_list_total":
      return {
        ...state,
        claimListTotal: action.data,
      };
    case "clear_main":
      return initState;
    default:
      return state;
  }
};

export default main;
