import styled from "styled-components";
import Div100vh from "react-div-100vh";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { imgList } from "@constants/img";
import { layoutAuthInterface } from "./interface";
import { stateInterface } from "@redux/Interface";

const LayoutAuth = ({ children }: layoutAuthInterface) => {
  const store = useSelector((state: stateInterface) => state.store);
  return (
    <Spin tip="Loading..." spinning={store.loading ? true : false}>
      <Div100vh>
        <Content
          className="layout"
          bg={imgList.bg_auth}
          bgMobile={imgList.bg_auth_mobile}
        >
          <div className="layout__left"></div>
          <div className="layout__right">
            <Link to="/" className="layout__logo">
              <img src={imgList.logo_impliepack} alt="" />
            </Link>
            {children}
          </div>
        </Content>
      </Div100vh>
    </Spin>
  );
};

export default LayoutAuth;

interface styleInterface {
  bg: string;
  bgMobile: string;
}

const Content = styled.div<styleInterface>`
  position: relative;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  @media only screen and (max-width: 1024px) {
    background: url(${(props: styleInterface) => props.bgMobile}) #fff no-repeat
      bottom left;
    background-size: 60% auto;
  }
  @media only screen and (max-width: 450px) {
    background-size: 80% auto;
  }
  .layout {
    &__left {
      width: 39%;
      height: 100%;
      background: url(${(props: styleInterface) => props.bg}) no-repeat center
        right;
      background-size: cover;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
    }
    &__right {
      width: 61%;
      padding: 0 8%;
      max-height: 100%;
      overflow: auto;
      @media only screen and (max-width: 1024px) {
        width: 100%;
        padding: 10% 20%;
      }
      @media only screen and (max-width: 450px) {
        padding: 0 15px;
        margin: -30% 0 0;
      }
    }
    &__logo {
      width: 46%;
      margin: 0 auto 8%;
      display: block;
      > img {
        width: 100%;
      }
      @media only screen and (max-width: 1024px) {
        width: 60%;
        margin: 0 auto 10%;
      }
      @media only screen and (max-width: 450px) {
        width: 70%;
      }
    }
  }
`;
