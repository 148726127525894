import { customerDataInterface } from "../Interface";

const initState: customerDataInterface = {
  customerCompanyData: {
    logo: {
      value: "",
      error: "",
    },
    companyCode: {
      value: "",
      error: "",
    },
    companyNameEng: {
      value: "",
      error: "",
    },
    companyNameThai: {
      value: "",
      error: "",
    },
    taxID: {
      value: "",
      error: "",
    },
    phoneNumber: {
      value: "",
      error: "",
    },
    faxNumber: {
      value: "",
      error: "",
    },
    email: {
      value: "",
      error: "",
    },
    companyWebsite: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  customerBranchData: {
    companyCode: {
      value: "",
      error: "",
    },
    companyNameEng: {
      value: "",
      error: "",
    },
    companyNameThai: {
      value: "",
      error: "",
    },
    company: {
      value: "",
      error: "",
    },
    branchCode: {
      value: "",
      error: "",
    },
    branchNameEng: {
      value: "",
      error: "",
    },
    branchNameThai: {
      value: "",
      error: "",
    },
    phoneNumber: {
      value: "",
      error: "",
    },
    faxNumber: {
      value: "",
      error: "",
    },
    contactPerson: {
      value: "",
      error: "",
    },
    contactNumber: {
      value: "",
      error: "",
    },
    contactEmail: {
      value: "",
      error: "",
    },
    address: {
      value: "",
      error: "",
    },
    subDistrict: {
      value: "",
      error: "",
    },
    district: {
      value: "",
      error: "",
    },
    province: {
      value: "",
      error: "",
    },
    postCode: {
      value: "",
      error: "",
    },
    country: {
      value: "217",
      error: "",
    },
    location: {
      value: "",
      error: "",
    },
    latitude: {
      value: 0,
      error: "",
    },
    longitude: {
      value: 0,
      error: "",
    },
    mainBranch: {
      value: false,
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  customerShippingData: {
    company: {
      value: "",
      error: "",
    },
    branch: {
      value: "",
      error: "",
    },
    companyCode: {
      value: "",
      error: "",
    },
    companyNameEng: {
      value: "",
      error: "",
    },
    companyNameThai: {
      value: "",
      error: "",
    },
    branchCode: {
      value: "",
      error: "",
    },
    branchNameEng: {
      value: "",
      error: "",
    },
    branchNameThai: {
      value: "",
      error: "",
    },
    warehouseCode: {
      value: "",
      error: "",
    },
    warehouseName: {
      value: "",
      error: "",
    },
    description: {
      value: "",
      error: "",
    },
    address: {
      value: "",
      error: "",
    },
    subDistrict: {
      value: "",
      error: "",
    },
    district: {
      value: "",
      error: "",
    },
    province: {
      value: "",
      error: "",
    },
    postCode: {
      value: "",
      error: "",
    },
    country: {
      value: "217",
      error: "",
    },
    location: {
      value: "",
      error: "",
    },
    latitude: {
      value: 0,
      error: "",
    },
    longitude: {
      value: 0,
      error: "",
    },
    phoneNumber: {
      value: "",
      error: "",
    },
    faxNumber: {
      value: "",
      error: "",
    },
    contactPerson: {
      value: "",
      error: "",
    },
    contactNumber: {
      value: "",
      error: "",
    },
    contactEmail: {
      value: "",
      error: "",
    },
    status: {
      value: "active",
      error: "",
    },
    createdBy: {
      value: "",
      error: "",
    },
    createdDate: {
      value: "",
      error: "",
    },
    updatedBy: {
      value: "",
      error: "",
    },
    updatedDate: {
      value: "",
      error: "",
    },
  },
  customerCompanyList: [],
  customerCompanyListTotal: 0,
  customerBranchList: [],
  customerBranchListTotal: 0,
  customerShippingList: [],
  customerShippingListTotal: 0,
};

const storeWare = (
  state = initState,
  action: { type: string; data: customerDataInterface }
) => {
  switch (action.type) {
    case "customer_company_data":
      return {
        ...state,
        customerCompanyData: { ...state.customerCompanyData, ...action.data },
      };
    case "customer_company_list":
      return {
        ...state,
        customerCompanyList: action.data,
      };
    case "customer_company_list_total":
      return {
        ...state,
        customerCompanyListTotal: action.data,
      };
    case "customer_branch_data":
      return {
        ...state,
        customerBranchData: { ...state.customerBranchData, ...action.data },
      };
    case "customer_branch_list":
      return {
        ...state,
        customerBranchList: action.data,
      };
    case "customer_branch_list_total":
      return {
        ...state,
        customerBranchListTotal: action.data,
      };
    case "customer_shipping_data":
      return {
        ...state,
        customerShippingData: { ...state.customerShippingData, ...action.data },
      };
    case "customer_shipping_list":
      return {
        ...state,
        customerShippingList: action.data,
      };
    case "customer_shipping_list_total":
      return {
        ...state,
        customerShippingListTotal: action.data,
      };
    case "clear_customer":
      return initState;
    default:
      return state;
  }
};

export default storeWare;
