import { optionDataInterface } from "../Interface";

const initState = {
  productTypeOption: [],
  productOption: [],
  companyOption: [],
  roleOption: [],
  userTitleOption: [],
  provinceOption: [],
  districtOption: [],
  subDistrictOption: [],
  companyDataOption: [],
  branchDataOption: [],
  divisionDataOption: [],
  departmentDataOption: [],
  positionDataOption: [],
  productDataOption: [],
  contractDataOption: [],
  contractProductOption: [],
  shippingAddressOption: [],
  companyOrderDataOption: [],
  branchOrderDataOption: [],
  branchContractDataOption: [],
  actionOption: [],
  menuOption: [],
  orderOption: [],
  quotationOption: [],
  invoiceOption: [],
  stockWarehouseOption: [],
  stockProductTypeOption: [],
  stockProductOption: [],
  stockCompanyOption: [],
  stockBranchOption: [],
  customerCompanyOption: [],
  customerBranchOption: [],
  customerWarehouseOption: [],
  deliveryBranchOption: [],
  deliveryInvoiceOption: [],
  receiveDeliveryOption: [],
};

const option = (
  state = initState,
  action: { type: string; data: optionDataInterface }
) => {
  switch (action.type) {
    case "product_type_option":
      return { ...state, productTypeOption: action.data };
    case "product_option":
      return { ...state, productOption: action.data };
    case "company_option":
      return { ...state, companyOption: action.data };
    case "role_option":
      return { ...state, roleOption: action.data };
    case "user_title_option":
      return { ...state, userTitleOption: action.data };
    case "province_option":
      return { ...state, provinceOption: action.data };
    case "district_option":
      return { ...state, districtOption: action.data };
    case "subdistrict_option":
      return { ...state, subDistrictOption: action.data };
    case "company_data_option":
      return { ...state, companyDataOption: action.data };
    case "branch_data_option":
      return { ...state, branchDataOption: action.data };
    case "division_data_option":
      return { ...state, divisionDataOption: action.data };
    case "department_data_option":
      return { ...state, departmentDataOption: action.data };
    case "position_data_option":
      return { ...state, positionDataOption: action.data };
    case "product_data_option":
      return { ...state, productDataOption: action.data };
    case "contract_data_option":
      return { ...state, contractDataOption: action.data };
    case "contract_product_option":
      return { ...state, contractProductOption: action.data };
    case "shipping_address_option":
      return { ...state, shippingAddressOption: action.data };
    case "company_order_option":
      return { ...state, companyOrderDataOption: action.data };
    case "branch_order_option":
      return { ...state, branchOrderDataOption: action.data };
    case "branch_contract_option":
      return { ...state, branchContractDataOption: action.data };
    case "action_option":
      return { ...state, actionOption: action.data };
    case "menu_option":
      return { ...state, menuOption: action.data };
    case "order_option":
      return { ...state, orderOption: action.data };
    case "quotation_option":
      return { ...state, quotationOption: action.data };
    case "invoice_option":
      return { ...state, invoiceOption: action.data };
    case "stock_warehouse_option":
      return { ...state, stockWarehouseOption: action.data };
    case "stock_product_type_option":
      return { ...state, stockProductTypeOption: action.data };
    case "stock_product_option":
      return { ...state, stockProductOption: action.data };
    case "stock_company_option":
      return { ...state, stockCompanyOption: action.data };
    case "stock_branch_option":
      return { ...state, stockBranchOption: action.data };
    case "customer_company_option":
      return { ...state, customerCompanyOption: action.data };
    case "customer_branch_option":
      return { ...state, customerBranchOption: action.data };
    case "customer_warehouse_option":
      return { ...state, customerWarehouseOption: action.data };
    case "delivery_branch_option":
      return { ...state, deliveryBranchOption: action.data };
    case "delivery_invoice_option":
      return { ...state, deliveryInvoiceOption: action.data };
    case "receive_delivery_option":
      return { ...state, receiveDeliveryOption: action.data };
    default:
      return state;
  }
};

export default option;
