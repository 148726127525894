import icon_email from "../static/images/icons/icon_email.png";
import icon_password from "../static/images/icons/icon_password.png";
import icon_password_lock from "../static/images/icons/icon_password_lock.png";
import icon_password_unlock from "../static/images/icons/icon_password_unlock.png";

import company_active from "../static/images/icons/menu/company_active.png";
import company from "../static/images/icons/menu/company.png";
import role_active from "../static/images/icons/menu/role_active.png";
import role from "../static/images/icons/menu/role.png";
import product_active from "../static/images/icons/menu/product_active.png";
import product from "../static/images/icons/menu/product.png";
import store_active from "../static/images/icons/menu/store_active.png";
import store from "../static/images/icons/menu/store.png";
import impliepack_active from "../static/images/icons/menu/impliepack_active.png";
import impliepack from "../static/images/icons/menu/impliepack.png";
import customer_active from "../static/images/icons/menu/customer_active.png";
import customer from "../static/images/icons/menu/customer.png";
import user_active from "../static/images/icons/menu/user_active.png";
import user from "../static/images/icons/menu/user.png";
import report_active from "../static/images/icons/menu/report_active.png";
import report from "../static/images/icons/menu/report.png";
import lang_active from "../static/images/icons/menu/lang_active.png";
import lang from "../static/images/icons/menu/lang.png";
import my_company_active from "../static/images/icons/menu/my_company_active.png";
import my_company from "../static/images/icons/menu/my_company.png";
import branch from "../static/images/icons/menu/branch.png";
import branch_active from "../static/images/icons/menu/branch_active.png";
import position_active from "../static/images/icons/menu/position_active.png";
import division_active from "../static/images/icons/menu/division_active.png";
import department_active from "../static/images/icons/menu/department_active.png";
import division from "../static/images/icons/menu/division.png";
import department from "../static/images/icons/menu/department.png";
import position from "../static/images/icons/menu/position.png";
import user_management_active from "../static/images/icons/menu/user_management_active.png";
import user_profile_active from "../static/images/icons/menu/user_profile_active.png";
import action_logs_active from "../static/images/icons/menu/action_logs_active.png";
import user_management from "../static/images/icons/menu/user_management.png";
import user_profile from "../static/images/icons/menu/user_profile.png";
import action_logs from "../static/images/icons/menu/action_logs.png";
import warehouse_active from "../static/images/icons/menu/warehouse_active.png";
import warehouse from "../static/images/icons/menu/warehouse.png";

import contract_active from "../static/images/icons/menu/contract_active.png";
import contract from "../static/images/icons/menu/contract.png";
import claim_active from "../static/images/icons/menu/claim_active.png";
import stock_active from "../static/images/icons/menu/stock_active.png";
import delivery_active from "../static/images/icons/menu/delivery_active.png";
import delivery from "../static/images/icons/menu/delivery.png";
import stock from "../static/images/icons/menu/stock.png";
import claim from "../static/images/icons/menu/claim.png";
import invoice_active from "../static/images/icons/menu/invoice_active.png";
import receipt_active from "../static/images/icons/menu/receipt_active.png";
import invoice from "../static/images/icons/menu/invoice.png";
import receipt from "../static/images/icons/menu/receipt.png";
import quotation from "../static/images/icons/menu/quotation.png";
import order from "../static/images/icons/menu/order.png";
import quotation_active from "../static/images/icons/menu/quotation_active.png";
import order_active from "../static/images/icons/menu/order_active.png";

import icon_success from "../static/images/icons/icon_success.svg";
import icon_error from "../static/images/icons/icon_error.svg";
import icon_mail from "../static/images/icons/icon_mail.png";
import icon_plug from "../static/images/icons/icon_plug.png";

import icon_minus_active from "../static/images/icons/icon_minus_active.png";
import icon_minus from "../static/images/icons/icon_minus.png";
import icon_plus_active from "../static/images/icons/icon_plus_active.png";
import icon_plus from "../static/images/icons/icon_plus.png";

import { imgInterface } from "./imgInterface";

export const iconList: imgInterface = {
  icon_email,
  icon_password,
  icon_password_lock,
  icon_password_unlock,
  company_active,
  company,
  role_active,
  role,
  product_active,
  product,
  store_active,
  store,
  impliepack_active,
  impliepack,
  customer_active,
  customer,
  user_active,
  user,
  report_active,
  report,
  lang_active,
  lang,
  my_company_active,
  my_company,
  branch,
  branch_active,
  position_active,
  division_active,
  department_active,
  division,
  department,
  position,
  user_management_active,
  user_profile_active,
  action_logs_active,
  user_management,
  user_profile,
  action_logs,
  warehouse_active,
  warehouse,
  contract_active,
  contract,
  claim_active,
  stock_active,
  delivery_active,
  delivery,
  stock,
  claim,
  invoice_active,
  receipt_active,
  invoice,
  receipt,
  quotation,
  order,
  quotation_active,
  order_active,
  icon_success,
  icon_error,
  icon_mail,
  icon_plug,
  icon_minus_active,
  icon_minus,
  icon_plus_active,
  icon_plus,
};
